import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { ResizedEvent } from 'angular-resize-event';

import { ChangePasswordComponent } from 'src/app/components/dashboard/change-password/change-password.component';
import { DeployChangesComponent } from 'src/app/components/dashboard/deploy-changes/deploy-changes.component';

import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { PageValidationService } from 'src/app/services/page-validation.service';
import { JsonDataService } from 'src/app/services/json-data.service';

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {

    private modules: any[] = [];

    private moduleLink: any = [
        // BMA
        { moduleId: "BMA001", name: "generate-rpa" },
        { moduleId: "BMA002", name: "generate-it-reports" },
        { moduleId: "BMA003", name: "generate-reports" },
        { moduleId: "BMA004", name: "upload/process-renewal" },
        { moduleId: "BMA005", name: "update-renewal-details" },
        { moduleId: "BMA006", name: "claim-document-review" },
        // { moduleId: "BMA007", name: "document-type-maintenance" },
        // { moduleId: "BMA008", name: "nature-of-loss" },
        { moduleId: "BMA009", name: "claim-record-upload"},

        // BMM
        { moduleId: "BMM011", name: "system-parameter-maintenance" },
        { moduleId: "BMM012", name: "reference-codes-maintenance" },
        { moduleId: "BMM015", name: "customer-maintenance"},
        { moduleId: "BMM017", name: "agent-maintenance" },
        { moduleId: "BMM018", name: "marital-status-maintenance" },
        { moduleId: "BMM020", name: "job-category-maintenance" },
        { moduleId: "BMM021", name: "risk-profile-maintenance" },
        { moduleId: "BMM022", name: "issue-source-maintenance" },
        { moduleId: "BMM023", name: "coverage-maintenance" },
        { moduleId: "BMM024", name: "tax-charges-maintenance" },
        { moduleId: "BMM025", name: "vehicle-maintenance" },
        { moduleId: "BMM028", name: "fmv-maintenance" },
        { moduleId: "BMM029", name: "line-maintenance" },
        { moduleId: "BMM030", name: "subline-maintenance" },
        { moduleId: "BMM031", name: "tax-maintenance" },
        { moduleId: "BMM032", name: "policy-sequence-number-mtn" },
        { moduleId: "BMM033", name: "report-maintenance" },
        { moduleId: "BMM034", name: "report-signatory-maintenance" },
        { moduleId: "BMM037", name: "standard-amount-covered-maintenance" },
        { moduleId: "BMM036", name: "purpose-maintenance" },
        { moduleId: "BMM038", name: "plan-maintenance" },
        { moduleId: "BMM041", name: "rpa-gen-page-mapping-maintenance" },
        { moduleId: "BMM042", name: "quick-links-maintenance" },
        { moduleId: "BMM043", name: "customer-user-account-maintenance" },
        { moduleId: "BMM044", name: "bpims-maintenance" },
        { moduleId: "BMM045", name: "mortgagee-maintenance" },
        { moduleId: "BMM048", name: "citizenship-nationality-maintenance"},
        { moduleId: "BMM049", name: "occupation-maintenance" },
        { moduleId: "BMM050", name: "assured-id-type-maintenance" },
        { moduleId: "BMM051", name: "contract-type-maintenance" },
        { moduleId: "BMM052", name: "coverage-type-maintenance" },
        { moduleId: "BMM053", name: "introduction-maintenance" },
        { moduleId: "BMM055", name: "source-code-maintenance" },
        { moduleId: "BMM056", name: "source-extension-maintenance" },
        { moduleId: "BMM057", name: "bma-maintenance" },
        { moduleId: "BMM058", name: "referror-maintenance" },
        { moduleId: "BMM059", name: "project-maintenance" },
        { moduleId: "BMM060", name: "motor-high-risk-maintenance" },
        { moduleId: "BMM061", name: "client-group-maintenance" },
        { moduleId: "BMM062", name: "rpa-peril-mapping-maintenance" },
        { moduleId: "BMM064", name: "payment-options-maintenance" },
        { moduleId: "BMM065", name: "email-content-maintenance" },
        { moduleId: "BMM066", name: "warranties-clauses-maintenance" },
        { moduleId: "BMM067", name: "warr-clause-per-plan-maintenance" },
        { moduleId: "BMM068", name: "occupation-class-maintenance" },
        { moduleId: "BMM069", name: "report-parameter-maintenance" },
        { moduleId: "BMM070", name: "agency-standard-rates-maintenance" },
        { moduleId: "BMM071", name: "agency-standard-rates-per-vehicle-maintenance" },
        { moduleId: "BMM072", name: "agent-group-maintenance" },
        { moduleId: "BMM073", name: "dealership-standard-rates-maintenance" },
        { moduleId: "BMM074", name: "faq-main-maintenance" },
        { moduleId: "BMM075", name: "faq-subline-maintenance" },
        { moduleId: "BMM076", name: "agency-maintenance" },
        { moduleId: "BMM077", name: "bfb-maintenance" },
        { moduleId: "BMM078", name: "system-users-maintenance" },
        { moduleId: "BMM082", name: "user-level-mtn" },
        { moduleId: "BMM087", name: "reason-cancellation-maintenance" },
        { moduleId: "BMM088", name: "bfb-src-ext-maintenance" },
        { moduleId: "BMM089", name: "rpa-clause-mapping-maintenance" },
        { moduleId: "BMM090", name: "mv-type-maintenance" },
        { moduleId: "BMM091", name: "mv-prem-type-maintenance" },
        { moduleId: "BMM093", name: "account-type-maintenance" },
        { moduleId: "BMM095", name: "bfb-standard-rates-maintenance" },
        { moduleId: "BMM096", name: "bfb-standard-rates-per-vehicle-maintenance" },
        { moduleId: "BMM097", name: "bpi-ms-standard-rates-maintenance" },
        { moduleId: "BMM098", name: "bpi-ms-standard-rates-per-vehicle-maintenance" },
        { moduleId: "BMM099", name: "public-user-standard-rates-maintenance" },
        { moduleId: "BMM100", name: "pub-user-std-rts-per-vehicle-maintenance" },
        { moduleId: "BMM102", name: "risk-rating-questions-maintenance" },
        { moduleId: "BMM103", name: "payment-options-access-maintenance" },
        { moduleId: "BMM105", name: "hazard-grade-maintenance" },
        { moduleId: "BMM106", name: "region-maintenance" },
        { moduleId: "BMM111", name: "tariff-risk-code-maintenance" },
        { moduleId: "BMM112", name: "relationship-maintenance" },
        { moduleId: "BMM113", name: "client-group-standard-rates-maintenance" },
        { moduleId: "BMM114", name: "benefits-coverage-maintenance" },
        { moduleId: "BMM115", name: "plan-access-maintenance" },
        { moduleId: "BMM116", name: "currency-maintenance" },
        { moduleId: "BMM118", name: "standard-rates-per-plan-maintenance" },
        { moduleId: "BMM119", name: "standard-rates-per-plan-based-issuing-office-maintenance" },
        { moduleId: "BMM120", name: "source-of-funds-maintenance" },
        { moduleId: "BMM124", name: "vehicle-filter-maintenance" },
        { moduleId: "BMM125", name: "construction-type-maintenance" },
        { moduleId: "BMM126", name: "occupancy-maintenance" },
        { moduleId: "BMM128", name: "group-plan-setup-maintenance" },
        { moduleId: "BMM132", name: "client-group-per-user-type-maintenance" },
        { moduleId: "BMM133", name: "blocked-vehicle-maintenance" },
        { moduleId: "BMM134", name: "client-group-rates-for-fixed-plan-maintenance" },
        { moduleId: "BMM135", name: "client-group-rates-for-fixed-plan-based-io-maintenance" },
        { moduleId: "BMM136", name: "pa-hcip-parameter-validation-maintenance" },
        { moduleId: "BMM137", name: "deductible-maintenance" },
        { moduleId: "BMM138", name: "deductible-maintenance-per-client-group" },
        { moduleId: "BMM139", name: "deductible-maintenance-per-client-group-per-issue-source"},
        { moduleId: "BMM140", name: "applicable-deductible-per-plan-maintenance" },
        { moduleId: "BMM141", name: "earthquake-zone-maintenance" },
        { moduleId: "BMM142", name: "typhoon-flood-zoning-maintenance" },
        { moduleId: "BMM143", name: "deductible-maintenance-per-client-group-chev-mazda-ford" },
        { moduleId: "BMM144", name: "deductible-maintenance-per-client-group-per-issue-source-chev-mazda-ford"},
        { moduleId: "BMM145", name: "state-maintenance" },
        { moduleId: "BMM146", name: "fire-district-maintenance" },
        { moduleId: "BMM147", name: "fire-accumulation-register"},
        { moduleId: "BMM148", name: "segment-code-maintenance" },
        { moduleId: "BMM149", name: "src-ext-for-rela-code-mtn" },
        { moduleId: "BMM150", name: "client-group-rates-for-flexible-plan-per-iss-maintenance" },
        { moduleId: "BMM151", name: "benefits-coverage-per-plan-peril-maintenance" },
        { moduleId: "BMM153", name: "applicable-client-group-per-user-id-maintenance" },
        { moduleId: "BMM154", name: "standard-amount-per-client-group-per-car-maintenance" },
        { moduleId: "BMM155", name: "towing-amt-per-client-grp-maintenance" },
        { moduleId: "BMM156", name: "towing-amt-per-client-grp-vehicle-maintenance" },
        { moduleId: "BMM157", name: "product-code-maintenance" },
        { moduleId: "BMM158", name: "voucher-code-maintenance" },
        { moduleId: "BMM159", name: "plan-notification-maintenance" },
        { moduleId: "BMM160", name: "property-details-maintenance" },
        { moduleId: "BMM164", name: "property-assessment-wordings-maintenance" },
        { moduleId: "BMM165", name: "pre-approved-subdivision-maintenance" },
        { moduleId: "BMM166", name: "determine-hca-plan-maintenance" },
        { moduleId: "BMM167", name: "geographic-zoning-maintenance" },
        { moduleId: "BMM170", name: "fire-prem-rt-for-agent-maintenance" },
        { moduleId: "BMM171", name: "cat-zone-fire-rates-agent-maintenance" },
        { moduleId: "BMM172", name: "fire-prem-rt-for-bpi-maintenance" },
        { moduleId: "BMM173", name: "cat-zone-fire-rates-bpi-maintenance" },
        { moduleId: "BMM174", name: "fire-prem-rt-for-bpims-maintenance" },
        { moduleId: "BMM175", name: "cat-zone-fire-rates-bpims-maintenance" },
        { moduleId: "BMM176", name: "fire-prem-rt-for-public-maintenance" },
        { moduleId: "BMM177", name: "cat-zone-fire-rates-pu-maintenance" },
        { moduleId: "BMM178", name: "class-maintenance" },
        { moduleId: "BMM180", name: "property-si-limit-maintenance" },
        { moduleId: "BMM181", name: "plan-amt-limits-maintenance" },
        { moduleId: "BMM182", name: "effective-date-basis-maintenance" },
        { moduleId: "BMM183", name: "construction-year-maintenance" },
        { moduleId: "BMM185", name: "src-cd-per-dealership-maintenance" },
        { moduleId: "BMM186", name: "src-ext-per-dealership-maintenance" },
        { moduleId: "BMM188", name: "fire-pol-wordings-maintenance" },
        { moduleId: "BMM167", name: "geographic-zoning-maintenance" },
        { moduleId: "BMM184", name: "client-group-per-agent-group-maintenance" },
        { moduleId: "BMM108", name: "applicable-item-code-per-plan-maintenance" },
        { moduleId: "BMM187", name: "business-code-maintenance" },
        { moduleId: "BMM168", name: "fire-premium-rates-per-client-group-maintenance" },
        { moduleId: "BMM110", name: "occupancy-business-code-per-plan-maintenance" },
        { moduleId: "BMM130", name: "open-policy-sequence-no-maintenance" },
        { moduleId: "BMM179", name: "quotation-wordings-maintenance" },
        { moduleId: "BMM189", name: "fire-typhoon-zone-maintenance" },
        { moduleId: "BMM190", name: "fire-flood-zone-maintenance" },
        { moduleId: "BMM169", name: "fire-cat-perils-premium-rates-per-client-group-maintenance" },
        { moduleId: "BMM107", name: "fire-item-code-maintenance" },
        { moduleId: "BMM194", name: "cyber-premiums-maintenance" },
        { moduleId: "BMM193", name: "payment-frequency-maintenance" },
        
        // BMU
        { moduleId: "BMU001", name: "policy-cancellation" },
        { moduleId: "BMU002", name: "update-policy-accumulation-register" }
        
    ];

    public userId: string = "";
    public heightAdjuster: any;
    public generalMenus: any[] = [];
    public maintenanceMenuRoutes: any[] = [];
    public renewalMenuRoutes: any[] = [];
    public claimMenuRoutes: any[] = [];

    public menuButtons: any = {
        maintenanceShow: false,
        generalShow: false,
        motorShow: false
    }

    constructor (
        private router: Router,
        private dialog: MatDialog,
        private userDetailService: UserDetailsService,
        private apiCallService: APICallService,
        private appMessageService: AppMessageService,
        private pageValidationService: PageValidationService,
        private jsonDataService: JsonDataService
    ) {
        this.pageValidationService.validatePageAccessibility();

        const urlModule = this.router.url.split("/").pop();

        this.modules = JSON.parse(this.jsonDataService.decrypt(sessionStorage.getItem("mdls") || "")).sort((a: any, b: any) => a.moduleDesc > b.moduleDesc ? 1 : -1);
        this.userId = this.userDetailService.userId || "";

        for (const mdls of this.modules.filter((a: any) => a.moduleGrp === "A" && a.hasAccess === "Y")) {
            this.generalMenus.push({
                moduleId: mdls.moduleId,
                name: this.getName(mdls.moduleId),
                title: mdls.moduleDesc,
                hidden: false
            });
        }

        for (const menu of this.jsonDataService.data.refCds.filter((a: any) => a.rvDomain === "MENU_GRP").sort((a: any, b: any) => a.rvMeaning > b.rvMeaning ? 1 : -1)) {
            const opt = [];
            for (const maintenance of this.modules.filter((a: any) => a.menuGrp === menu.rvLowValue && a.hasAccess === "Y" && a.moduleGrp === 'M')) {
                opt.push({
                    moduleId: maintenance.moduleId,
                    name: this.getName(maintenance.moduleId),
                    title: maintenance.moduleDesc,
                    hidden: false
                });

            }

            this.maintenanceMenuRoutes.push({
                name: menu.rvMeaning.replace(/ /g, "-").toLowerCase(),
                title: menu.rvMeaning,
                hidden: opt.length === 0 ? true : false,
                showSubOptions: false,
                options: opt
            });

            const optR = [];
            for (const renewal of this.modules.filter((a: any) => a.menuGrp === menu.rvLowValue && a.hasAccess === "Y" && a.moduleGrp === 'R')) {
                optR.push({
                    moduleId: renewal.moduleId,
                    name: this.getName(renewal.moduleId),
                    title: renewal.moduleDesc,
                    hidden: false
                });

            }

            this.renewalMenuRoutes.push({
                name: menu.rvMeaning.replace(/ /g, "-").toLowerCase(),
                title: menu.rvMeaning,
                hidden: optR.length === 0 ? true : false,
                showSubOptions: false,
                options: optR
            });

            // const optC = [];
            // for (const claim of this.modules.filter((a: any) => a.menuGrp === menu.rvLowValue && a.hasAccess === "Y" && a.moduleGrp === 'C')) {
            //     optC.push({
            //         moduleId: claim.moduleId,
            //         name: this.getName(claim.moduleId),
            //         title: claim.moduleDesc,
            //         hidden: false
            //     });
            // }

            // this.claimMenuRoutes.push({
            //     name: menu.rvMeaning.replace(/ /g, "-").toLowerCase(),
            //     title: menu.rvMeaning,
            //     hidden: optC.length === 0 ? true : false,
            //     showSubOptions: false,
            //     options: optC
            // });
        }

        for (let mtn of this.maintenanceMenuRoutes) {
            if (mtn.options?.some((sub: any) => sub.name === urlModule)) {
                this.menuButtons.maintenanceShow = true;
                mtn.showSubOptions = true;
            }
        }
    }

    private getName(moduleId: string) {
        let name = "";
        for (const mod of this.moduleLink.filter((a: any) => a.moduleId === moduleId)) {
            name = mod.name;
        }
        return name;
    }

    logout() {
        this.apiCallService.logout().subscribe((response: any) => {
            if (response.status == "SUCCESS") {
                this.appMessageService.showAppMessage("Logged out successfully", "success");
                sessionStorage.removeItem("bssid");
                sessionStorage.removeItem("rkc");
                this.userDetailService.resetUserDetails();
                this.router.navigate([""]);
            } else {
                this.appMessageService.showAppMessage("An error has occured", "error");
            }
        })
    }

    public onResized(event: ResizedEvent): void {
        this.heightAdjuster = event.newHeight;
    }

    toggleMtn() {
        this.menuButtons.maintenanceShow = !this.menuButtons.maintenanceShow;
        if (!this.menuButtons.maintenanceShow) {
            for (let subs of this.maintenanceMenuRoutes) {
                subs.showSubOptions = false;
            }
        }
    }

    showChangePassword() {
        try {
            this.dialog.open(ChangePasswordComponent, {
                width: '525px',
                disableClose: true,
            });
        } catch (e) {
    console.error(e);
            
        }
    }

    deployChanges(): void {
        try {
            this.dialog.open(DeployChangesComponent, {
                disableClose: true,
                width: '512px',
                data: {
                    title: 'Deploy Changes',
                    content:
                        `Deploy Changes Test Dialog Test!`,
                },
            });
        } catch (e) {
    console.error(e);
            
        }
    }

}
