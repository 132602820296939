import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

// MODULES
import { ThirdPartyModule } from './third-party.module';

import { AppRoutingModule, RoutingComponents } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { MaintenanceComponent } from './components/maintenance/maintenance';
import { EmployeeMaintenanceComponent } from './components/dashboard/employee-maintenance/employee-maintenance.component';
import { BfbMaintenanceComponent } from './components/dashboard/bfb-maintenance/bfb-maintenance.component';
import { VehicleMaintentanceSelectModelMakeComponent } from './components/dashboard/vehicle-maintenance/vehicle-maintentance-select-model-make/vehicle-maintentance-select-model-make.component';
import { VehicleMaintenanceMakeComponent } from './components/dashboard/vehicle-maintenance/vehicle-maintenance-make/vehicle-maintenance-make.component';
import { VehicleMaintenanceModelComponent } from './components/dashboard/vehicle-maintenance/vehicle-maintenance-model/vehicle-maintenance-model.component';
import { DialogBoxComponent } from './components/common/dialog-box/dialog-box';
import { DialogMsgComponent } from './components/common/dialog-msg/dialog-msg.component';
import { AppMessageComponent } from './components/common/app-message/app-message';
import { UploadFmvComponent } from './components/dashboard/fmv-maintenance/upload-fmv/upload-fmv.component';
import { SystemUsersMaintentanceComponent } from './components/dashboard/system-users-maintentance/system-users-maintentance.component';
import { LovDialogComponent } from './components/dashboard/agent-maintenance/lov-dialog/lov-dialog.component';
import { AgencyMaintenanceComponent } from './components/dashboard/agency-maintenance/agency-maintenance.component';
import { SysUsrsMtnUserAccessComponent } from './components/dashboard/system-users-maintentance/sys-usrs-mtn-user-access/sys-usrs-mtn-user-access.component';
import { OspTableComponent } from './components/common/osp-table/osp-table.component';
import { IssSrcComponent } from './components/common/iss-src/iss-src.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SublineMaintenanceComponent } from './components/dashboard/subline-maintenance/subline-maintenance.component';
import { StandardAmountCoveredMaintenanceComponent } from './components/dashboard/standard-amount-covered-maintenance/standard-amount-covered-maintenance.component';
import { MortgageeMaintenanceComponent } from './components/dashboard/mortgagee-maintenance/mortgagee-maintenance.component';
import { ProjectMaintenanceComponent } from './components/dashboard/project-maintenance/project-maintenance.component';
import { JsonDataService } from './services/json-data.service';
import { TaxMaintenanceComponent } from './components/dashboard/tax-maintenance/tax-maintenance.component';
import { AssureIdTypeMaintenanceComponent } from './components/dashboard/assure-id-type-maintenance/assure-id-type-maintenance.component';
import { AgentGroupMaintenanceComponent } from './components/dashboard/agent-group-maintenance/agent-group-maintenance.component';
import { IntroductionMaintenanceComponent } from './components/dashboard/introduction-maintenance/introduction-maintenance.component';
import { ChangePasswordComponent } from './components/dashboard/change-password/change-password.component';
import { OccupationMaintenanceComponent } from './components/dashboard/occupation-maintenance/occupation-maintenance.component';
import { ContractTypeMaintenanceComponent } from './components/dashboard/contract-type-maintenance/contract-type-maintenance.component';
import { ReportMaintenanceComponent } from './components/dashboard/report-maintenance/report-maintenance.component';
import { LoadingComponent } from './components/common/loading/loading.component';
import { DeployChangesComponent } from './components/dashboard/deploy-changes/deploy-changes.component';
import { SrcExtMaintenanceComponent } from './components/dashboard/src-ext-maintenance/src-ext-maintenance.component';
import { ReferrorMaintenanceComponent } from './components/dashboard/referror-maintenance/referror-maintenance.component';
import { SrcCdMaintenanceComponent } from './components/dashboard/src-cd-maintenance/src-cd-maintenance.component';
import { BmaMaintenanceComponent } from './components/dashboard/bma-maintenance/bma-maintenance.component';
import { ClientGroupMaintenanceComponent } from './components/dashboard/client-group-maintenance/client-group-maintenance.component';
import { MessageBoxComponent } from './components/common/message-box/message-box';
import { PublicUserStandardRatesMaintenanceComponent } from './components/dashboard/public-user-standard-rates-maintenance/public-user-standard-rates-maintenance.component';
import { PaymentOptionsMaintenanceComponent } from './components/dashboard/payment-options-maintenance/payment-options-maintenance';
import { PublicUserStandardRatesPerVehicleMaintenanceComponent } from './components/dashboard/pub-user-std-rts-per-vehicle-maintenance/pub-user-std-rts-per-vehicle-maintenance.component';
import { PaymentOptionsAccessMaintenanceComponent } from './components/dashboard/payment-options-access-maintenance/payment-options-access-maintenance';
import { BfbStandardRatesMaintenanceComponent } from './components/dashboard/bfb-standard-rates-maintenance/bfb-standard-rates-maintenance.component';
import { BpiMsStandardRatesMaintenanceComponent } from './components/dashboard/bpi-ms-standard-rates-maintenance/bpi-ms-standard-rates-maintenance.component';
import { BfbStandardRatesPerVehicleMaintenanceComponent } from './components/dashboard/bfb-standard-rates-per-vehicle-maintenance/bfb-standard-rates-per-vehicle-maintenance.component';
import { BpiMsStandardRatesPerVehicleMaintenanceComponent } from './components/dashboard/bpi-ms-standard-rates-maintenance-per-vehicle/bpi-ms-standard-rates-per-vehicle-maintenance.component';
import { CurrencyMaintenanceComponent } from './components/dashboard/currency-maintenance/currency-maintenance.component';
import { BfbSrcExtMaintenanceComponent } from './components/dashboard/bfb-src-ext-maintenance/bfb-src-ext-maintenance.component';
import { TariffRiskCodeMaintenanceComponent } from './components/dashboard/tariff-risk-code-maintenance/tariff-risk-code-maintenance.component';
import { MvPremTypeMaintenanceComponent } from './components/dashboard/mv-prem-type-maintenance/mv-prem-type-maintenance.component';
import { RpaPerilMappingMaintenanceComponent } from './components/dashboard/rpa-peril-mapping-maintenance/rpa-peril-mapping-maintenance.component';
import { RpaGenPageMappingMaintenanceComponent } from './components/dashboard/rpa-gen-page-mapping-maintenance/rpa-gen-page-mapping-maintenance.component';
import { RpaClauseMappingMaintenanceComponent } from './components/dashboard/rpa-clause-mapping-maintenance/rpa-clause-mapping-maintenance.component';
import { MvTypeMaintenanceComponent } from './components/dashboard/mv-type-maintenance/mv-type-maintenance.component';
import { ReportSignatoryMaintenanceComponent } from './components/dashboard/report-signatory-maintenance/report-signatory-maintenance.component';
import { RelationshipMaintenanceComponent } from './components/dashboard/relationship-maintenance/relationship-maintenance.component';
import { OccupationClassMaintenanceComponent } from './components/dashboard/occupation-class-maintenance/occupation-class-maintenance.component';
import { CoverageMaintenanceComponent } from './components/dashboard/coverage-maintenance/coverage-maintenance.component';
import { EmailContentMaintenanceComponent } from './components/dashboard/email-content-maintenance/email-content-maintenance.component';
import { GenerateVoucherDialogComponent } from './components/dashboard/voucher-code-maintenance/generate-voucher-dialog/generate-voucher-dialog.component';
import { DeactivateVoucherDialogComponent } from './components/dashboard/voucher-code-maintenance/deactivate-voucher-dialog/deactivate-voucher-dialog.component';
import { DownloadVoucherDialogComponent } from './components/dashboard/voucher-code-maintenance/download-voucher-dialog/download-voucher-dialog.component';
import { UploadVoucherDialogComponent } from './components/dashboard/voucher-code-maintenance/upload-voucher-dialog/upload-voucher-dialog.component';
import { RiskProfileMaintenanceComponent } from './components/dashboard/risk-profile-maintenance/risk-profile-maintenance.component';
import { ClientGroupPerUserTypeMaintenanceComponent } from './components/dashboard/client-group-per-user-type-maintenance/client-group-per-user-type-maintenance.component';
import { FireAccumulationRegisterComponent } from './components/dashboard/fire-accumulation-register/fire-accumulation-register.component';

import { CheckLovComponent } from './components/common/guard/check-lov/check-lov.component';

// common LOV Modules
import { SrcCdLovComponent } from './components/common/src-cd-lov/src-cd-lov.component';
import { SrcExtLovComponent } from './components/common/src-ext-lov/src-ext-lov.component';
import { BmaLovComponent } from './components/common/bma-lov/bma-lov.component';
import { LineLovDialogComponent } from './components/common/line-lov-dialog/line-lov-dialog.component';
import { PerilLovComponent } from './components/common/peril-lov/peril-lov.component';
import { PlanLovComponent } from './components/common/plan-lov/plan-lov.component';
import { AccountTypeLovComponent } from './components/common/acc-type-lov/acc-type-lov.component';
import { PaymentOptionsLovComponent } from './components/common/payt-opt-lov/payt-opt-lov.component';
import { CarTypeLovComponent } from './components/common/car-type-lov/car-type-lov.component';
import { TaxLovComponent } from './components/common/tax-lov/tax-lov.component';
import { RefLovComponent } from './components/common/ref-lov/ref-lov.component';
import { ContractTypeLovDialogComponent } from './components/common/contract-type-lov/contract-type-lov.component';
import { OccClassLovComponent } from './components/common/occ-class-lov/occ-class-lov.component';
import { CategoryLovComponent } from './components/common/category-lov/category-lov.component';
import { LineWarrClauseLovComponent } from './components/common/line-warr-clause-lov/line-warr-clause-lov.component';
import { AgentGroupLovDialogComponent } from './components/common/agent-group-lov/agent-group-lov.component';
import { ReportLovComponent } from './components/common/report-lov/report-lov.component';
import { AgentLovComponent } from './components/common/agent-lov/agent-lov.component';
import { ProjectLovComponent } from './components/common/project-lov/project-lov.component';
import { ClientGroupLovDialog } from './components/common/client-group-lov-dialog/client-group-lov-dialog.component';
import { DeductibleLovDialog } from './components/common/deductible-lov-dialog/deductible-lov-dialog.component';
import { OverlayComponent } from './components/common/overlay/overlay';
import { UserLovDialog } from './components/common/user-lov-dialog/user-lov-dialog.component';
import { CurrencyLovComponent } from './components/common/currency-lov/currency-lov.component';
import { UserTypeLovDialogComponent } from './components/common/user-type-lov/user-type-lov.component';
import { CoverageTypeLovComponent } from './components/common/coverage-type-lov/coverage-type-lov.component';
import { PlanSubgrpLovComponent } from './components/common/plan-subgrp-lov/plan-subgrp-lov.component';
import { TariffLovComponent } from './components/common/tariff-lov/tariff-lov.component';
import { StateLovComponent } from './components/common/state-lov/state-lov.component';
import { RiskProfileLovComponent } from './components/common/risk-profile-lov/risk-profile-lov.component';
import { DistrictLovComponent } from './components/common/district-lov/district-lov.component';
import { OccupancyLovComponent } from './components/common/occupancy-lov/occupancy-lov.component';
import { ConstructionLovComponent } from './components/common/construction-lov/construction-lov.component';
import { RegionLovComponent } from './components/common/region-lov/region-lov.component';
import { FireRegisterLovComponent } from './components/common/fire-register-lov/fire-register-lov.component';
import { EQZoneLovComponent } from './components/common/eq-zone-lov/eq-zone-lov.component';
import { TFZoneLovComponent } from './components/common/tf-zone-lov/tf-zone-lov.component';
import { HazardGrdLovComponent } from './components/common/hazard-grd-lov/hazard-grd-lov.component';
import { UserLevelLovDialogComponent } from './components/common/user-level-lov/user-level-lov.component';
import { PropAssessmentLovComponent } from './components/common/prop-assessment-lov/prop-assessment-lov.component';
import { ClassLovComponent } from './components/common/class-lov/class-lov.component';
import { CountryLovDialogComponent } from './components/common/country-lov/country-lov.component';
import { ProvinceLovDialogComponent } from './components/common/province-lov/province-lov.component';
import { CityLovDialogComponent } from './components/common/city-lov/city-lov.component';
import { ZoneLovDialogComponent } from './components/common/zone-lov/zone-lov.component';
import { FireItemCodeLovComponent } from './components/common/fire-item-code-lov/fire-item-code-lov.component';
import { TyphoonZoneLovComponent } from './components/common/typhoon-zone-lov/typhoon-zone-lov.component';
import { FloodZoneLovComponent } from './components/common/flood-zone-lov/flood-zone-lov.component';
import { CyberPremiumFrequencyLov } from './components/common/cyber-prem-freq-lov/cyber-prem-freq-lov.component';

// Material Modules
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';

// Directive Module
import { PosNumInputDirective } from './directives/pos-num-input.directive';
import { UppercaseDirective } from './directives/uppercase.directive';
import { AlphanumericInputDirective } from './directives/alphanumeric-input.directive';
import { AmountDirective } from './directives/amount.directive';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { DecimalDirective } from './directives/decimal.directive';
import { DateInputDirective } from './directives/date-input.directive';
import { NumbersAndFSlashOnlyDirective } from './directives/numbers-fslash-only.directive';
import { NumbersOnlyWithoutDecimalDirective } from './directives/numbers-only-without-decimal.directive';

import { UpdateRenewalDetailsComponent } from './components/dashboard/update-renewal-details/update-renewal-details.component';
import { RiskRatingQuestionsMaintenanceComponent } from './components/dashboard/risk-rating-questions-maintenance/risk-rating-questions-maintenance.component';
import { UpdatePolicyAccumulationRegisterComponent } from './components/dashboard/update-policy-accumulation-register/update-policy-accumulation-register.component';
import { QuickLinksMaintenanceComponent } from './components/dashboard/quick-links-maintenance/quick-links-maintenance';
import { VehicleFilterMaintenanceComponent } from './components/dashboard/vehicle-filter-maintenance/vehicle-filter-maintenance.component';
import { PAHCIPParameterValidationComponent } from './components/dashboard/pa-hcip-parameter-validation-maintenance/pa-hcip-parameter-validation-maintenance.component';
import { StandardAmountPerClientGroupPerCarMaintenanceComponent } from './components/dashboard/standard-amount-per-client-group-per-car-maintenance/standard-amount-per-client-group-per-car-maintenance.component';
import { TyphoonFloodZoningMaintenanceComponent } from './components/dashboard/typhoon-flood-zoning-maintenance/typhoon-flood-zoning-maintenance.component';
import { AccountTypeMaintenanceComponent } from './components/dashboard/account-type-maintenance/account-type-maintenance.component';
import { OtpComponent } from './components/home/otp/otp.component';
import { BusinessCodeLovDialogComponent } from './components/common/business-cd-lov/business-cd-lov.component';
import { ViewPoliciesLovComponent } from './components/common/view-policies-lov/view-policies-lov.component';
import { FireFloodZoneMaintenanceComponent } from './components/dashboard/fire-flood-zone-maintenance/fire-flood-zone-maintenance.component';
import { FireTyphoonZoneMaintenanceComponent } from './components/dashboard/fire-typhoon-zone-maintenance/fire-typhoon-zone-maintenance.component';
import { QuotationWordingsMaintenanceComponent } from './components/dashboard/quotation-wordings-maintenance/quotation-wordings-maintenance.component';
import { RevisionHistoryComponent } from './components/common/revision-history/revision-history.component';
import { DecimalOtherDirective } from './directives/decimal-other.directive';
import { SrcExtForRelaCodeMtnComponent } from './components/dashboard/src-ext-for-rela-code-mtn/src-ext-for-rela-code-mtn.component';
import { LovComponent } from './components/common/lov/lov.component';
import { UploadComponent } from './components/common/upload/upload.component';
import { DecimalAccDirective } from './directives/decimal-acc.directive';
import { PolicySequenceNumberMtnComponent } from './components/dashboard/policy-sequence-number-mtn/policy-sequence-number-mtn.component';
import { UserLevelMtnComponent } from './components/dashboard/user-level-mtn/user-level-mtn.component';
// import { GroupPlanSetupMaintenanceComponent } from './components/dashboard/group-plan-setup-maintenance/group-plan-setup-maintenance.component';
import { CyberPremiumsMaintenanceComponent } from './components/dashboard/cyber-premiums-maintenance/cyber-premiums-maintenance.component'
import { PaymentFrequencyMaintenanceComponent } from './components/dashboard/payment-frequency-maintenance/payment-frequency-maintenance.component'
import { ClaimDocumentReview } from './components/dashboard/claim-document-review/claim-document-review.component';
import { ClaimRecordUpload } from './components/dashboard/claim-record-upload/claim-record-upload.component';

@NgModule({
  declarations: [
    AppComponent,
    MaintenanceComponent,
    AppMessageComponent,
    RoutingComponents,
    EmployeeMaintenanceComponent,
    OspTableComponent,
    VehicleMaintentanceSelectModelMakeComponent,
    IssSrcComponent,
    VehicleMaintenanceMakeComponent,
    VehicleMaintenanceModelComponent,
    UploadFmvComponent,
    DialogBoxComponent,
    DialogMsgComponent,
    SystemUsersMaintentanceComponent,
    LovDialogComponent,
    SrcCdLovComponent,
    SrcExtLovComponent,
    AgencyMaintenanceComponent,
    SysUsrsMtnUserAccessComponent,
    BfbMaintenanceComponent,
    BmaLovComponent,
    SublineMaintenanceComponent,
    LineLovDialogComponent,
    PerilLovComponent,
    PlanLovComponent,
    AccountTypeLovComponent,
    PaymentOptionsLovComponent,
    TaxMaintenanceComponent,
    AssureIdTypeMaintenanceComponent,
    StandardAmountCoveredMaintenanceComponent,
    CarTypeLovComponent,
    AgentGroupMaintenanceComponent,
    MortgageeMaintenanceComponent,
    ProjectMaintenanceComponent,
    TaxLovComponent,
    IntroductionMaintenanceComponent,
    ChangePasswordComponent,
    CheckLovComponent,
    OccupationMaintenanceComponent,
    ContractTypeMaintenanceComponent,
    ReportMaintenanceComponent,
    LoadingComponent,
    DeployChangesComponent,
    SrcExtMaintenanceComponent,
    ReferrorMaintenanceComponent,
    ClientGroupMaintenanceComponent,
    SrcCdMaintenanceComponent,
    BmaMaintenanceComponent,
    RefLovComponent,
    MessageBoxComponent,
    OccClassLovComponent,
    PosNumInputDirective,
    CategoryLovComponent,
    PublicUserStandardRatesMaintenanceComponent,
    ContractTypeLovDialogComponent,
    PaymentOptionsMaintenanceComponent,
    CurrencyMaintenanceComponent,
    PublicUserStandardRatesPerVehicleMaintenanceComponent,
    BfbStandardRatesMaintenanceComponent,
    BfbSrcExtMaintenanceComponent,
    PaymentOptionsAccessMaintenanceComponent,
    TariffRiskCodeMaintenanceComponent,
    MvPremTypeMaintenanceComponent,
    BpiMsStandardRatesMaintenanceComponent,
    RpaPerilMappingMaintenanceComponent,
    RpaGenPageMappingMaintenanceComponent,
    RpaClauseMappingMaintenanceComponent,
    MvTypeMaintenanceComponent,
    BfbStandardRatesPerVehicleMaintenanceComponent,
    LineWarrClauseLovComponent,
    BpiMsStandardRatesPerVehicleMaintenanceComponent,
    AgentGroupLovDialogComponent,
    ReportSignatoryMaintenanceComponent,
    ReportLovComponent,
    UppercaseDirective,
    RelationshipMaintenanceComponent,
    OccupationClassMaintenanceComponent,
    CoverageMaintenanceComponent,
    EmailContentMaintenanceComponent,
    GenerateVoucherDialogComponent,
    RiskProfileMaintenanceComponent,
    AgentLovComponent,
    ProjectLovComponent,
    ClientGroupLovDialog,
    DeductibleLovDialog,
    DeactivateVoucherDialogComponent,
    UpdateRenewalDetailsComponent,
    OverlayComponent,
    DownloadVoucherDialogComponent,
    UploadVoucherDialogComponent,
    ClientGroupPerUserTypeMaintenanceComponent,
    UserLovDialog,
    CurrencyLovComponent,
    UserTypeLovDialogComponent,
    CoverageTypeLovComponent,
    PlanSubgrpLovComponent,
    RiskRatingQuestionsMaintenanceComponent,
    FireAccumulationRegisterComponent,
    UpdatePolicyAccumulationRegisterComponent,
    QuickLinksMaintenanceComponent,
    QuickLinksMaintenanceComponent,
    VehicleFilterMaintenanceComponent,
    PAHCIPParameterValidationComponent,
    StandardAmountPerClientGroupPerCarMaintenanceComponent,
    TariffLovComponent,
    TyphoonFloodZoningMaintenanceComponent,
    AccountTypeMaintenanceComponent,
    RiskProfileLovComponent,
    StateLovComponent,
    DistrictLovComponent,
    OccupancyLovComponent,
    ConstructionLovComponent,
    RegionLovComponent,
    FireRegisterLovComponent,
    AlphanumericInputDirective,
    AmountDirective,
    NumbersOnlyDirective,
    EQZoneLovComponent,
    TFZoneLovComponent,
    HazardGrdLovComponent,
    OtpComponent,
    DecimalDirective,
    UserLevelLovDialogComponent,
    PropAssessmentLovComponent,
    ClassLovComponent,
    CountryLovDialogComponent,
    ProvinceLovDialogComponent,
    CityLovDialogComponent,
    ZoneLovDialogComponent,
    FireItemCodeLovComponent,
    TyphoonZoneLovComponent,
    FloodZoneLovComponent,
    DateInputDirective,
    BusinessCodeLovDialogComponent,
    ViewPoliciesLovComponent,
    FireFloodZoneMaintenanceComponent,
    FireTyphoonZoneMaintenanceComponent,
    QuotationWordingsMaintenanceComponent,
    NumbersAndFSlashOnlyDirective,
    RevisionHistoryComponent,
    DecimalOtherDirective,
    SrcExtForRelaCodeMtnComponent,
    LovComponent,
    UploadComponent,
    DecimalAccDirective,
    PolicySequenceNumberMtnComponent,
    NumbersOnlyWithoutDecimalDirective,
    UserLevelMtnComponent,
    // GroupPlanSetupMaintenanceComponent,
    CyberPremiumsMaintenanceComponent,
    PaymentFrequencyMaintenanceComponent,
    CyberPremiumFrequencyLov,
    ClaimDocumentReview,
    ClaimRecordUpload
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ThirdPartyModule,
    MatCardModule,
    MatDividerModule,
    MatExpansionModule,
    HttpClientModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatInputModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [JsonDataService],
      useFactory: (jsonDataService:JsonDataService) => () => jsonDataService.init()
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
