import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as cryptoJs from 'crypto-js';
import { Subject } from 'rxjs';

// COMMAND TO GET JSON FROM S3 FOR DEVELOPMENT
// aws s3 cp s3://admin.osp-devt.com/assets/data src/assets/data --recursive
const JSON_LIST:any[] = [
    { key: "BMM011", name: "params"             },
    { key: "BMM012", name: "refCds"             },
    { key: "BMM016", name: "gender"             },
    { key: "BMM017", name: "agent"              },
    { key: "BMM018", name: "maritalStat"        },
    { key: "BMM019", name: "postalCds"          },
    { key: "BMM020", name: "category"           },
    { key: "BMM021", name: "riskProfCd"         },
    { key: "BMM023", name: "peril"              },
    { key: "BMM025", name: "carCompanies"       },
    { key: "BMM026", name: "makes"              },
    { key: "BMM027", name: "engineSeries"       },
    { key: "BMM028", name: "veDetails"          },
    { key: "BMM029", name: "lines"              },
    { key: "BMM030", name: "sublines"           },
    { key: "BMM031", name: "tax"                },
    { key: "BMM033", name: "reports"            },
    { key: "BMM036", name: "sublinePurposes"    },
    { key: "BMM037", name: "perilAllowedTsi"    },
    { key: "BMM038", name: "plans"              },
    { key: "BMM039", name: "planPerils"         },
    { key: "BMM040", name: "moduleList"         },
    { key: "BMM045", name: "banks"              },
    { key: "BMM046", name: "country"            },
    { key: "BMM047", name: "province"           },
    { key: "BMM048", name: "nationality"        },
    { key: "BMM049", name: "occupation"         },
    { key: "BMM050", name: "idList"             },
    { key: "BMM051", name: "contracts"          },
    { key: "BMM052", name: "coverages"          },
    { key: "BMM053", name: "introDetail"        },
    { key: "BMM054", name: "veUsages"           },
    { key: "BMM055", name: "src"                },
    { key: "BMM056", name: "srcExt"             },
    { key: "BMM057", name: "bma"                },
    { key: "BMM058", name: "referror"           },
    { key: "BMM059", name: "project"            },
    { key: "BMM060", name: "highRisk"           },
    { key: "BMM061", name: "client"             },
    { key: "BMM065", name: "policyEmail"        },
    // { key: "BMM068", name: "slidCom"            },
    { key: "BMM070", name: "BMM070"             },
    { key: "BMM071", name: "BMM071"             },
    { key: "BMM072", name: "agentGrp"           },
    { key: "BMM073", name: "BMM073"             },
    { key: "BMM074", name: "faq"                },
    { key: "BMM075", name: "faqProduct"         },
    { key: "BMM076", name: "agencyCds"          },
    { key: "BMM086", name: "paymentOptions"     },
    { key: "BMM087", name: "cancelReason"       },
    { key: "BMM090", name: "mvType"             },
    { key: "BMM091", name: "mvPremType"         },
    { key: "BMM092", name: "city"               },
    { key: "BMM093", name: "userType"           },
    { key: "BMM191", name: "fireProCd"          },
    { key: "BMM192", name: "rpaStaff"           },
    { key: "HELP",   name: "help"               }
];


const addtlDataKeys:any[] = ['referror','agent', 'bma'];

@Injectable({
    providedIn: 'root'
})
export class JsonDataService {

    private encryptKey: string = "";

    data:any = {};

    apiUrl:string = "";

    loadingSource = new Subject<Boolean>();
    loading$ = this.loadingSource.asObservable();

    msgBoxSource = new Subject<any>();
    msgBox$ = this.msgBoxSource.asObservable();

    public onMaintenance: boolean = false;

    constructor (
        private httpClient: HttpClient
    ) { }

    init():Promise<any> {
        this.setApiUrlForLocalhostTesting();
        this.getEncryptionKey();
        return this.extractData();
    }

    async setApiUrlForLocalhostTesting():Promise<void> {
        let apiUrl = "";

        try {
            const response =  await this.httpClient.get<any>("assets/data/config.json").toPromise();
            window.location.hostname.includes("localhost") || window.location.port.includes("4200")
                ? apiUrl = response.apiUrl 
                : apiUrl = "https://" + window.location.hostname + "/api";
        } catch (e) {
    console.error(e);
            // do action here
        }

        this.apiUrl = apiUrl;
    }

    async getEncryptionKey():Promise<void> {
        try {
            const key = await this.httpClient.get<any>("assets/data/O4NTDGHewlaeaBW5NzqjNGLb.json").toPromise();
            for (let a = 1; a <= (key.length / 5); a++) {
                this.encryptKey += key.substr((5 * a) - 1, 1);
            }
        } catch (e) {
    console.error(e);
            this.onMaintenance = true;
        }
    }

    async extractData():Promise<any> {
        try {
            const res = JSON.parse(this.decrypt(await this.httpClient.get<any>("assets/data/MUNmMsb3GuhcuByoA84c5X01.json").toPromise()));
            JSON_LIST.forEach((file) => {
                this.data[file.name] = res[file.key];
            });
        } catch (e) {
    console.error(e);
            // do action here
        }
    }

    decrypt(data:string):string {
        return cryptoJs.AES.decrypt(data, this.encryptKey).toString(cryptoJs.enc.Utf8);
    }

    encrypt(data:any):string {
        if(data == undefined){
            data = null;
        }
        return cryptoJs.AES.encrypt(JSON.stringify(data), this.encryptKey).toString();
    }

    contorlLoading(action:boolean):void {
        this.loadingSource.next(action);
    }

    contorlMsgBox(type:string, msg?:any, title?:any):void {
        this.msgBoxSource.next({
            msg:msg,
            title:title,
            type: type
        });
    }

    saveToStorage(key:string, obj:any):void {
        //sessionStorage.setItem(key, this.encrypt(obj));
        sessionStorage.setItem(key, obj);
    }

    retrieveFromStorage(key:string):any {
        let obj = null;
        let objStr = sessionStorage.getItem(key);
        //if (objStr) obj = this.decrypt(objStr);
        if (objStr) obj = objStr;
        return obj;
    }

    getHelp(tableName: String, columnName:String):String{
        return this.data.help?.filter((a:any)=> a.tableName == tableName && a.columnName == columnName)[0]?.comment || '';
    }

    parseBase64(str: string):String{
        let words  = cryptoJs.enc.Base64.parse(str);
        return cryptoJs.enc.Utf8.stringify(words);
    }

    updateAddtlData(data:any){
        for(let key of Object.keys(data)){
            this.data[key] = data[key];
        }
    }
}