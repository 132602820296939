<section id="main">
    <div class="mtn-title">
        <p>Home Credit</p>
    </div>
    <div class="form-btns">
        <a (click)="onButtonClick()" class="btn btn2">Claims Review</a>
    </div>
    <div class="form-btns">
        <a (click)="onButtonClick()" class="btn btn2">Claims Review</a>
    </div>
</section>