import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { SecurityService } from 'src/app/services/security.service';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';
import { JsonDataService } from 'src/app/services/json-data.service';
import { OspTableComponent } from '../../common/osp-table/osp-table.component';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { LazyLoadEvent } from 'primeng/api';

@Component({
  selector: 'policy-renewal',
  templateUrl: './policy-renewal.component.html',
  styleUrls: ['./policy-renewal.component.css']
})
export class PolicyRenewalComponent implements OnInit {

  @ViewChild('tbl') tbl!: OspTableComponent;
  @ViewChild('purgeDialog') purgeDialog!: TemplateRef<any>;
  @ViewChild('tagDialog') tagDialog!: TemplateRef<any>;
  @ViewChild('tagInfoDialog') tagInfoDialog!: TemplateRef<any>;
  @ViewChild('logsDialog') logsDialog!: TemplateRef<any>;
  @ViewChild('fileInput') fileInput!: ElementRef<any>;

  moduleId: string = 'BMA004';

  tblConfig: any = {
    cols: [
      {
        key: "",
        header: "",
        dataType: "button",
        width: "100px",
        btnTitle: "View",
        excFilter: true
      },
      {
        key: "PROCESS_TAG",
        header: "P",
        dataType: "checkboxWithVal",
        selectAll: true,
        width: "50px",
        disabled: false,
        vals: ['Y', 'N'],
        confirmation: true,
        checked: false,
        excFilter: true
      },
      {
        key: "BALANCE_TAG",
        header: "B",
        dataType: "checkboxWithVal",
        width: "50px",
        disabled: false,
        vals: ['Y', 'N'],
        excFilter: true
      },
      {
        key: "TOTAL_LOSS_TAG",
        header: "TL",
        dataType: "checkboxWithVal",
        width: "50px",
        disabled: false,
        vals: ['Y', 'N'],
        excFilter: true
      },
      {
        key: "BLOCKED_TAG",
        header: "BK",
        dataType: "checkboxWithVal",
        width: "50px",
        disabled: false,
        vals: ['Y', 'N'],
        excFilter: true
      },
      {
        key: "CONTRACT_TYPE_CD",
        header: "Contract^Type",
        dataType: "string",
        width: "70px",
        excFilter: false,
        filterValue: "UPPER(CONTRACT_TYPE_CD)"
      },
      {
        key: "POLICY_NO",
        header: "Policy No",
        dataType: "string",
        width: "100px",
        excFilter: false,
        filterValue: "UPPER(r1.POLICY_NO)"
      },
      {
        key: "REN_STAT_DESC",
        header: "Status",
        dataType: "string",
        width: "120px",
        align: 'text-l',
        excFilter: false,
        filterValue: "UPPER(m12.RV_MEANING)"
      },
      {
        key: "DISPLAY_NAME",
        header: "Insured",
        dataType: "string",
        width: "130px",
        align: 'text-l',
        excFilter: false,
        filterValue: "UPPER(r1_r2.DISPLAY_NAME)"
      },
      {
        key: "OLD_EXPIRY_DATE",
        header: "Expiry",
        dataType: "date",
        width: "100px",
        excFilter: false,
        filterValue: "UPPER(DATE_FORMAT(r1.OLD_EXPIRY_DATE, '%m/%d/%Y'))"
      },
      {
        key: "TOTAL_TSI_AMT",
        header: "Sum Insured",
        dataType: "currWithoutDecimal",
        width: "120px",
        align: 'text-r',
        excFilter: false,
        filterValue: "UPPER(r1.TOTAL_TSI_AMT)"
      },
      {
        key: "TOTAL_GROSS_PREM",
        header: "Gross Premium",
        dataType: "currency",
        width: "120px",
        align: 'text-r',
        excFilter: false,
        filterValue: "UPPER(r1.TOTAL_GROSS_PREM)"
      },
      {
        key: "BALANCE_AMT",
        header: "Balance",
        dataType: "currency",
        width: "100px",
        align: 'text-r',
        excFilter: false,
        filterValue: "UPPER(r1.BALANCE_AMT)"
      },
      {
        key: "MOBILE_NO",
        header: "Mobile No",
        dataType: "string",
        width: "100px",
        align: 'text-l',
        excFilter: false,
        filterValue: "r1_r2.MOBILE_NO"
      },
      {
        key: "EMAIL",
        header: "Email Address",
        dataType: "string",
        width: "120px",
        align: 'text-l',
        excFilter: false,
        filterValue: "UPPER(r1_r2.EMAIL)"
      },
      {
        key: "DEPT_CD",
        header: "Dept Code",
        dataType: "string",
        width: "100px",
        excFilter: false,
        filterValue: "UPPER(r1.DEPT_CD)"
      },
      {
        key: "AGENT_CD",
        header: "Agent Code",
        dataType: "string",
        width: "100px",
        excFilter: false,
        filterValue: "UPPER(r1.AGENT_CD)"
      },
      {
        key: "AGENT_NAME",
        header: "Agent Name",
        dataType: "string",
        width: "120px",
        align: 'text-l',
        excFilter: false,
        filterValue: "UPPER(r1_r2.AGENT_NAME)"
      },
      {
        key: "ISS_CD",
        header: "Issuing Office",
        dataType: "string",
        width: "120px",
        excFilter: false,
        filterValue: "UPPER(r1.ISS_CD)"
      },
      {
        key: "SRC_CD_DESC",
        header: "Source Code",
        dataType: "string",
        width: "150px",
        align: 'text-l',
        excFilter: false,
        filterValue: "UPPER(m55.SRC_CD_DESC)"
      },
      {
        key: "SRC_EXT_DESC",
        header: "Source Extension",
        dataType: "string",
        width: "150px",
        align: 'text-l',
        excFilter: false,
        filterValue: "UPPER(m56.SRC_EXT_DESC)"
      },
      {
        key: "BMA_DESC",
        header: "BMA",
        dataType: "string",
        width: "130px",
        align: 'text-l',
        excFilter: false,
        filterValue: "UPPER(m57.BMA_DESC)"
      },
      {
        key: "BLOCKED_REASON",
        header: "Blocking Reason",
        dataType: "string",
        width: "140px",
        align: 'text-l',
        excFilter: false,
        filterValue: "UPPER(r1.BLOCKED_REASON)"
      },
      {
        key: "REMARKS",
        header: "Remarks",
        dataType: "string",
        width: "100px",
        align: 'text-l',
        excFilter: false,
        filterValue: "UPPER(r1.REMARKS)"
      },
      {
        key: "UPLOAD_USER",
        header: "Upload User",
        dataType: "string",
        width: "110px",
        align: 'text-l',
        excFilter: false,
        filterValue: "UPPER(r1.UPLOAD_USER)"
      },
      {
        key: "UPLOAD_DATE",
        header: "Upload Date",
        dataType: "date",
        width: "110px",
        excFilter: false,
        filterValue: "UPPER(DATE_FORMAT(r1.UPLOAD_DATE, '%m/%d/%Y'))"
      },
      {
        key: "LAST_USER",
        header: "Update User",
        dataType: "string",
        width: "110px",
        align: 'text-l',
        excFilter: false,
        filterValue: "UPPER(r1.LAST_USER)"
      },
      {
        key: "LAST_USER_UPDATE",
        header: "Update Date",
        dataType: "date",
        width: "110px",
        excFilter: false,
        filterValue: "UPPER(DATE_FORMAT(r1.LAST_USER_UPDATE, '%m/%d/%Y'))"
      },
      {
        key: "PURGE_TAG",
        header: "CA",
        dataType: "checkboxWithVal",
        selectAll: true,
        width: "50px",
        disabled: false,
        vals: ['Y', 'N'],
        confirmation: true,
        checked: false,
        excFilter: true
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: true,
    totalRecords: 15,
    scrollable: true,
    scrollableWidth: "1300px",
    pageLinks: 1,
    withDropDown: true
    // frozen: {}
  }

  fileUpload: FormGroup = this.formBuilder.group({
		file: [File, Validators.required]
	});

  updateRenewal: FormGroup = this.formBuilder.group({
		balanceAmt: [""],
    blockedReason: [""],
    remarks: [""],
    email: [""],
    mobileNo: [""],
    telNo: [""],
    maritalStatus: [""],
    address01: [""],
    address02: [""],
    address03: [""],
    address04: [""],
    provCity: [""],
    birthCountry: [""],
    postalCd: [""],
    balanceAmt2: [""],
    blockedReason2: [""],
    agent: [""],
    bma: [""],
    src: [""],
    srcExt: [null],
    referror: [""],
    project: [""],
    client: [""]
	});

  retrievalParams: any = {
		type: "",
		userId: ""
	};

  excelRows: any = [];
  expiredList: any = [];

  renewalData: any = {};
  view: boolean = false;
  coverages:any[] = [];
  lastSortedColumn: string = "";
  sorting: string = "ASC";
  selectedRow: any = null;
  saveRenewalData: any[] = [];
  rowEventData: any = null;
  fileName: string = '';

  emailParams: any = {
    recipient: "",
    subject: "",
    emailContent: "",
    mobileNo: "",
    countryCd: "",
    smsContent: "",
    header: ""
    // attach: []
  };

  options: any = {
    maritalStat: [],
    country: [],
    provCity: [],
    agent: [],
    bma: [],
    src: [],
    srcExt: [],
    referror: [],
    project: [],
    client: []
  }

  smsCountryCd: any[] = [
    {
      countryCd: 23,
      BM_CODE: 'PHP',
      smsCountryCd: '63',
      prefix: '0'
    }
  ]

  datePipe = new DatePipe('en-us');

  reportParams: any = {
    uploadId: '',
    renewalId: '',
    reportType: '',
    reportName: ''
  }

  seeMorePropertyInfo: boolean = false;
  seeMorePaInfo: boolean = false;

  seeMoreConstruction: boolean = false;
  seeMoreOccupancy: boolean = false;
  seeMoreOtherRiskDtl: boolean = false;
  seeMoreInterestedParty: boolean = false;
  seeMoreRiskEndtWc: boolean = false;

  selectedStatus: string = 'forProcessing';


  dialogContent: string = '';
  tooltip: any = {
    viewDisabledSendNotice: 'Unable to send notice for policies with this status'
  }

  disableViewSendNotice: boolean = false;

  uploadId: number = 0;
  newUploadLogs: any[] = [];
  uploadCtr: number = 0;
  uploadRow: any = null;
  isGetAddtlData: boolean = false;

  constructor(
    private userDetailService: UserDetailsService,
    private apiCallService: APICallService,
    public formService: FormService,
    private formBuilder: FormBuilder,
    private router: Router,
    private jsonDataService: JsonDataService,
    public dialog: MatDialog,
    private securityService: SecurityService,
    private appMessageService: AppMessageService
  ) {
    this.enableForm(false);
    this.getDataJson();
  }

  ngOnInit(): void {
    this.retrievalParams.userId = this.userDetailService.userId || null;
    this.fileUpload.patchValue({
      file: ""
    });

    // this.tblConfig.frozen = {
    //   scrollableCols: this.tblConfig.cols.slice(5, this.tblConfig.cols.length),
    //   frozenCols: this.tblConfig.cols.slice(0, 5),
    //   frozenWidth: '340px'
    // }
  }

  getDataJson():void {
    this.emailParams.subject = 'Policy Renewal Notice';
    this.options.maritalStat = this.jsonDataService.data.maritalStat;
    this.options.country = this.jsonDataService.data.country;
    this.options.src = [{bmCode: null, srcCdDesc: ''}].concat(this.jsonDataService.data.src);
    this.options.srcExt = [{bmCode: null, srcExtDesc: ''}].concat(this.jsonDataService.data.srcExt);
    this.options.project = [{bmCode: null, projectDesc: ''}].concat(this.jsonDataService.data.project);
    this.options.client = [{bmCode: null, clientDesc: ''}].concat(this.jsonDataService.data.client);

    // this.options.agent = this.jsonDataService.data.agent;
    this.options.bma = [{bmCode: null, bmaDesc: ''}].concat(this.jsonDataService.data.bma);
    // this.options.referror = [{bmCode: '', referrorDesc: ''}].concat(this.jsonDataService.data.referror);
    this.isGetAddtlData = this.jsonDataService.data.referror.length == 0;

    this.updateProvCityOpts();
  }

  fileHandlerFunction(event: any) {
		this.formService.hideFormMsg("polRenewalFormMsg")
		const excel: File = event.target.files[0];
    var regex = /^([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)$/;

		if (excel == undefined) {
      this.fileUpload.patchValue({
				file: ""
			});
    } else if(regex.test(excel.name)) {
      this.fileUpload.patchValue({
				file: excel
			});
    } else {
      this.formService.showFormMsg("polRenewalFormMsg", "Please upload a valid Excel file.", "E");
			this.fileUpload.patchValue({
				file: ""
			});
    }
	}

  dupHeaderList: any[] = [];
  processAllTag: boolean = true;

  public onClickUpload(): void {
      this.formService.hideFormMsg("polRenewalFormMsg");
      this.dialogContent = "";

    if (!this.fileUpload.valid || this.fileUpload.value.file.name === "File") {
      this.formService.showFormMsg("polRenewalFormMsg", "Please upload a valid Excel file.", "E");
    } else {
          this.processFile(this.fileUpload.value.file);

          setTimeout((e: any) => {
              if (this.dupHeaderList.length > 0) {
                  this.dialogContent = this.constructLogs([], "u", this.dupHeaderList);
                  this.openLogsDialog();
                  return;
              }

              this.jsonDataService.contorlLoading(true);
              this.apiCallService.saveUploadRenewalData(
                  this.excelRows
              ).subscribe((response: any) => {
                  this.securityService.checkRequestKeyResponse(response, () => {
                      response = JSON.parse(this.jsonDataService.decrypt(response.content));
                      this.jsonDataService.contorlLoading(false);
                      if (response.status == "SUCCESS") {
                          this.getUploadedRenewalData();

                          this.fileName = this.fileUpload.value.file.name;

                          this.fileUpload.patchValue({file: ""});
                          this.fileInput.nativeElement.value = "";

                          if(response.renewalLogs.length > 0) {
                              this.dialogContent = this.constructLogs(response.renewalLogs, 'u');

                              this.openLogsDialog();
                          } else {
                              const dialogRole = this.dialog.open(DialogMsgComponent, {
                                  disableClose: true,
                                  width: "500px",
                                  data: {
                                  title: 'SUCCESS',
                                  content: 'Upload successful'
                                  }
                              });
                          }
                      } else {
                          this.formService.hideFormMsg("polRenewalFormMsg")
                      }
                  });
              });
          }, 1000);
      }
  }

  // updateSessionLastActionTimeInterval: any = null;

  public newOnClickUpload(): void {
    this.formService.hideFormMsg("polRenewalFormMsg");
    this.dialogContent = "";

    if (!this.fileUpload.valid || this.fileUpload.value.file.name === "File") {
      this.formService.showFormMsg("polRenewalFormMsg", "Please upload a valid Excel file.", "E");
    } else {
      this.uploadCtr = 0;
      this.uploadId = 0;

      // this.updateSessionLastActionTimeInterval = setInterval(() => {
      //   this.userDetailService.lastActionTime = new Date();
      // }, 1000);

      this.jsonDataService.contorlLoading(true);
      this.newProcessFile(this.fileUpload.value.file);

      setTimeout((e: any) => {
        if (this.dupHeaderList.length > 0) {
          this.dialogContent = this.constructLogs([], "u", this.dupHeaderList);
          this.jsonDataService.contorlLoading(false);
          this.openLogsDialog();
          return;
        }

        this.goToNextRow();
      }, 1000);
    }
  }

  processFile(data: File) {
    this.excelRows = [];
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(data);
    reader.onload = (e: any) => {
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, {type: 'binary'});

      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      const excelData: any = XLSX.utils.sheet_to_json(ws);

      let headerObj: any = XLSX.utils.sheet_to_json(ws, {defval:null})[0];
      var headers = Object.keys(headerObj);

      this.dupHeaderList = [];

      if(headers.length > 0) {
        for (let x = 0; x < headers.length; x++) {
          const el = headers[x].substring(0, headers[x].length-2);

          for (let y = 0; y < headers.length; y++) {
            const el2 = headers[y];

            if(el == el2) {
              this.dupHeaderList.push(el2);
            }
          }
        }
      }

      this.dupHeaderList = this.dupHeaderList.filter((x, i, a) => a.indexOf(x) == i);

      if(this.dupHeaderList.length > 0) {
        return;
      }

      excelData.forEach((el: any) => {
        Object.keys(el).forEach(x => {
          if (!el.hasOwnProperty(String(x).toUpperCase())
           || String(x).trim().length !== String(x).length) {
            el[String(x).toUpperCase().trim()] = el[x];
            delete el[x];
          }
        });
      });

      let errorMessage = "No file selected. Please select a file first.";
      loop1:
      for (var i = 0; i < excelData.length; i++) {
        let excelRow: any = {};
        let el = excelData[i];

        excelRow['excelRowNum'] = el['__rowNum__'] + 1;
        excelRow['lineCd'] = trim(el['LINE CODE']);
        excelRow['policyNo'] = trim(el['POLICY NO']);
        excelRow['totalLossTag'] = trim(el['TOTAL LOSS TAG']);
        excelRow['uploadUser'] = this.retrievalParams.userId;
        excelRow['displayName'] = trim(el['ASSURED NAME']);
        excelRow['assdLastName'] = trim(el['ASSD LAST NAME']);
        excelRow['assdFirstName'] = trim(el['ASSD FIRST NAME']);
        excelRow['assdMiddleName'] = trim(el['ASSD MIDDLE NAME']);
        excelRow['oldEffDate'] = el['OLD EFFECTIVE DATE'] !== null && el['OLD EFFECTIVE DATE'] !== '' && el['OLD EFFECTIVE DATE'] !== undefined ? dateToDateString(serialNumberToDate(el['OLD EFFECTIVE DATE'])) : null;
        excelRow['oldExpiryDate'] = el['OLD EXPIRY DATE'] !== null && el['OLD EXPIRY DATE'] !== '' && el['OLD EXPIRY DATE'] !== undefined ? dateToDateString(serialNumberToDate(el['OLD EXPIRY DATE'])) : null;
        excelRow['effDate'] = el['NEW EFFECTIVE DATE'] !== null && el['NEW EFFECTIVE DATE'] !== '' && el['NEW EFFECTIVE DATE'] !== undefined ? dateToDateString(serialNumberToDate(el['NEW EFFECTIVE DATE'])) : null;
        excelRow['expiryDate'] = el['NEW EXPIRY DATE'] !== null && el['NEW EXPIRY DATE'] !== '' && el['NEW EXPIRY DATE'] !== undefined ? dateToDateString(serialNumberToDate(el['NEW EXPIRY DATE'])) : null;
        excelRow['totalTsiAmt'] = trim(el['TOTAL TSI']);
        excelRow['totalGrossPrem'] = trim(el['TOTAL GROSS PREMIUM']);
        excelRow['totalCommAmt'] = trim(el['TOTAL COMMISSION']);
        excelRow['balanceAmt'] = trim(el['BALANCE']);
        excelRow['issCd'] = trim(el['ISSUE SOURCE']);
        excelRow['deptCd'] = trim(el['DEPT CODE']);
        excelRow['contractTypeCd'] = trim(el['CONTRACT TYPE']);
        excelRow['coverageTypeCd'] = trim(el['COVERAGE TYPE']);
        excelRow['agentCd'] = trim(el['AGENT CODE']);
        excelRow['srcCd'] = trim(el['SOURCE CODE']);
        excelRow['srcExtCd'] = trim(el['SOURCE EXTENSION CODE']);
        excelRow['bmaCd'] = trim(el['BMA CODE']);
        excelRow['referrorCd'] = trim(el['REFERROR CODE']);
        excelRow['clientCd'] = trim(el['CLIENT NUMBER']);
        excelRow['clientGrp'] = trim(el['CLIENT GROUP']);
        excelRow['agentGrp'] = trim(el['AGENT GROUP']);
        excelRow['replacesCd'] = trim(el['REPLACES CODE']);
        excelRow['promoCode'] = trim(el['PROMO CODE']);
        excelRow['bmPolCd'] = trim(el['BM_POL_CD']);
        excelRow['bmPolSeqNo'] = trim(el['BM_POL_SEQ_NO']);
        excelRow['projectCd'] = trim(el['PROJECT CODE']);
        excelRow['prjectName'] = trim(el['PROJECT NAME']);
        excelRow['genpage01'] = trim(el['GENPAGE 1']);
        excelRow['genpage02'] = trim(el['GENPAGE 2']);
        excelRow['genpage03'] = trim(el['GENPAGE 3']);
        excelRow['genpage04'] = trim(el['GENPAGE 4']);
        excelRow['genpage05'] = trim(el['GENPAGE 5']);
        excelRow['genpage06'] = trim(el['GENPAGE 6']);
        excelRow['genpage07'] = trim(el['GENPAGE 7']);
        excelRow['genpage08'] = trim(el['GENPAGE 8']);
        excelRow['genpage09'] = trim(el['GENPAGE 9']);
        excelRow['genpage10'] = trim(el['GENPAGE 10']);
        excelRow['genpage11'] = trim(el['GENPAGE 11']);
        excelRow['genpage12'] = trim(el['GENPAGE 12']);
        excelRow['genpage13'] = trim(el['GENPAGE 13']);
        excelRow['genpage14'] = trim(el['GENPAGE 14']);
        excelRow['genpage15'] = trim(el['GENPAGE 15']);
        excelRow['genpage16'] = trim(el['GENPAGE 16']);
        excelRow['genpage17'] = trim(el['GENPAGE 17']);
        excelRow['genpage18'] = trim(el['GENPAGE 18']);
        excelRow['genpage19'] = trim(el['GENPAGE 19']);
        excelRow['genpage20'] = trim(el['GENPAGE 20']);
        excelRow['genpage21'] = trim(el['GENPAGE 21']);
        excelRow['genpage22'] = trim(el['GENPAGE 22']);
        excelRow['genpage23'] = trim(el['GENPAGE 23']);
        excelRow['genpage24'] = trim(el['GENPAGE 24']);
        excelRow['genpage25'] = trim(el['GENPAGE 25']);
        excelRow['genpage26'] = trim(el['GENPAGE 26']);
        excelRow['genpage27'] = trim(el['GENPAGE 27']);
        excelRow['genpage28'] = trim(el['GENPAGE 28']);
        excelRow['genpage29'] = trim(el['GENPAGE 29']);
        excelRow['genpage30'] = trim(el['GENPAGE 30']);
        excelRow['paytRefNo'] = trim(el['PAYT REF NO']);
        excelRow['paytTranNo'] = trim(el['TRANSACTION NO']);
        excelRow['paytMode'] = trim(el['MODE OF PAMENT']);
        excelRow['paytDate'] = el['PAYMENT DATE'] !== null && el['PAYMENT DATE'] !== '' && el['PAYMENT DATE'] !== undefined ? dateToDateString(serialNumberToDate(el['PAYMENT DATE'])) : null;
        excelRow['clientType'] = trim(el['CLIENT TYPE']);
        excelRow['address01'] = trim(el['ADDRESS LINE 1']);
        excelRow['address02'] = trim(el['ADDRESS LINE 2']);
        excelRow['address03'] = trim(el['ADDRESS LINE 3']);
        excelRow['address04'] = trim(el['ADDRESS LINE 4']);
        excelRow['postalCd'] = trim(el['POSTAL CODE']);
        excelRow['mobileNo'] = trim(el['MOBILE NO']);
        excelRow['telNo'] = trim(el['TELEPHONE NO']);
        excelRow['email'] = trim(el['EMAIL']);
        excelRow['birthDate'] = el['DATE OF BIRTH'] !== null && el['DATE OF BIRTH'] !== '' && el['DATE OF BIRTH'] !== undefined ? dateToDateString(serialNumberToDate(el['DATE OF BIRTH'])) : null;
        excelRow['provCity'] = trim(el['PROVINCE/CITY']);
        excelRow['birthCountry'] = trim(el['COUNTRY OF BIRTH']);
        excelRow['gender'] = trim(el['GENDER']);
        excelRow['maritalStatus'] = trim(el['MARITAL STATUS']);
        excelRow['sTaxIndicator'] = trim(el['S/TAX INDICATOR']);
        excelRow['nationality'] = trim(el['NATIONALITY']);
        excelRow['busRes'] = trim(el['BUS/RES']);
        excelRow['occupation'] = trim(el['OCCUPATION']);
        excelRow['category'] = trim(el['CATEGORY']);
        excelRow['riskProfile'] = trim(el['RISK PROFILE']);
        excelRow['tin'] = trim(el['TIN']);
        excelRow['idType01'] = trim(el['ID TYPE 1']);
        excelRow['idNo01'] = trim(el['ID NUMBER 1']);
        excelRow['idExpiryDate01'] = el['ID EXPIRY DATE 1'] !== null && el['ID EXPIRY DATE 1'] !== '' && el['ID EXPIRY DATE 1'] !== undefined ? dateToDateString(serialNumberToDate(el['ID EXPIRY DATE 1'])) : null;
        excelRow['idType02'] = trim(el['ID TYPE 2']);
        excelRow['idNo02'] = trim(el['ID NUMBER 2']);
        excelRow['idExpiryDate02'] = el['ID EXPIRY DATE 2'] !== null && el['ID EXPIRY DATE 2'] !== '' && el['ID EXPIRY DATE 2'] !== undefined ? dateToDateString(serialNumberToDate(el['ID EXPIRY DATE 2'])) : null;
        excelRow['marketSector'] = trim(el['MARKET SECTOR']);
        excelRow['riskGenpage01'] = trim(el['RISK GENPAGE 1']);
        excelRow['riskGenpage02'] = trim(el['RISK GENPAGE 2']);
        excelRow['riskGenpage03'] = trim(el['RISK GENPAGE 3']);
        excelRow['riskGenpage04'] = trim(el['RISK GENPAGE 4']);
        excelRow['riskGenpage05'] = trim(el['RISK GENPAGE 5']);
        excelRow['riskGenpage06'] = trim(el['RISK GENPAGE 6']);
        excelRow['riskGenpage07'] = trim(el['RISK GENPAGE 7']);
        excelRow['riskGenpage08'] = trim(el['RISK GENPAGE 8']);
        excelRow['riskGenpage09'] = trim(el['RISK GENPAGE 9']);
        excelRow['riskGenpage10'] = trim(el['RISK GENPAGE 10']);
        excelRow['riskGenpage11'] = trim(el['RISK GENPAGE 11']);
        excelRow['riskGenpage12'] = trim(el['RISK GENPAGE 12']);
        excelRow['riskGenpage13'] = trim(el['RISK GENPAGE 13']);
        excelRow['riskGenpage14'] = trim(el['RISK GENPAGE 14']);
        excelRow['riskGenpage15'] = trim(el['RISK GENPAGE 15']);
        excelRow['riskGenpage16'] = trim(el['RISK GENPAGE 16']);
        excelRow['riskGenpage17'] = trim(el['RISK GENPAGE 17']);
        excelRow['riskGenpage18'] = trim(el['RISK GENPAGE 18']);
        excelRow['riskGenpage19'] = trim(el['RISK GENPAGE 19']);
        excelRow['riskGenpage20'] = trim(el['RISK GENPAGE 20']);
        excelRow['riskGenpage21'] = trim(el['RISK GENPAGE 21']);
        excelRow['riskGenpage22'] = trim(el['RISK GENPAGE 22']);
        excelRow['riskGenpage23'] = trim(el['RISK GENPAGE 23']);
        excelRow['riskGenpage24'] = trim(el['RISK GENPAGE 24']);
        excelRow['riskGenpage25'] = trim(el['RISK GENPAGE 25']);
        excelRow['riskGenpage26'] = trim(el['RISK GENPAGE 26']);
        excelRow['riskGenpage27'] = trim(el['RISK GENPAGE 27']);
        excelRow['riskGenpage28'] = trim(el['RISK GENPAGE 28']);
        excelRow['riskGenpage29'] = trim(el['RISK GENPAGE 29']);
        excelRow['riskGenpage30'] = trim(el['RISK GENPAGE 30']);
        excelRow['selField01'] = trim(el['SEL FIELD 1']);
        excelRow['selField02'] = trim(el['SEL FIELD 2']);
        excelRow['selField03'] = trim(el['SEL FIELD 3']);
        excelRow['riMethod'] = trim(el['RI METHOD']);
        excelRow['rateFlag'] = trim(el['RATE FLAG']);
        excelRow['deductibleAmt'] = trim(el['DEDUCTIBLE']);
        excelRow['itemNo'] = trim(el['ITEM NO']);
        excelRow['makeModelCd'] = trim(el['MAKE & MODEL CODE']);
        excelRow['carCompany'] = trim(el['CAR COMPANY']);
        excelRow['makeDesc'] = trim(el['MAKE DESC']);
        excelRow['subModel'] = trim(el['SUB MODEL']);
        excelRow['veTypeTag'] = trim(el['VEHICLE TYPE']);
        excelRow['yearModel'] = trim(el['YEAR MODEL']);
        excelRow['fmv'] = trim(el['FMV']);
        excelRow['cover'] = trim(el['COVER']);
        excelRow['vehicleUsage'] = trim(el['VEHICLE USAGE']);
        excelRow['engineNo'] = trim(el['ENGINE NO']);
        excelRow['chassisNo'] = trim(el['CHASSIS NO']);
        excelRow['plateNo'] = trim(el['VEHICLE PLATE NO']);
        excelRow['makeTypeCd'] = trim(el['MAKE TYPE CODE']);
        excelRow['color01'] = trim(el['COLOR']);
        excelRow['color02'] = trim(el['COLOR 2']);
        excelRow['noOfSeats'] = trim(el['NO OF SEATS']);
        excelRow['seatsCovered'] = trim(el['SEATS COVERED']);
        excelRow['ccWt'] = trim(el['CC/WT']);
        excelRow['repairLimit'] = trim(el['REPAIR LIMIT']);
        excelRow['towingLimit'] = trim(el['TOWING LIMIT']);
        excelRow['fuelType'] = trim(el['FUEL TYPE']);
        excelRow['itemNo01'] = trim(el['ITEM NO 1']);
        excelRow['perilCode01'] = trim(el['PERIL ITEM 1']);
        excelRow['amountCovered01'] = trim(el['AMOUNT COVERED 1']);
        excelRow['calcMethod01'] = trim(el['CALC METHOD 1']);
        excelRow['perilRt01'] = trim(el['PERIL RATE 1']);
        excelRow['grossPrem01'] = trim(el['GROSS PREMIUM 1']);
        excelRow['docStamp01'] = trim(el['DOCUMENT STAMP 1']);
        excelRow['lgt01'] = trim(el['LOCAL GOVT TAX 1']);
        excelRow['vat01'] = trim(el['VAT 1']);
        excelRow['fst01'] = trim(el['FST 1']);
        excelRow['otherTax01'] = trim(el['OTHER TAX 1']);
        excelRow['premTax01'] = trim(el['PREMIUM TAX 1']);
        excelRow['netPrem01'] = trim(el['NET PREMIUM 1']);
        excelRow['commRt01'] = trim(el['COMMISSION RATE 1']);
        excelRow['commAmt01'] = trim(el['COMMISSION AMOUNT 1']);
        excelRow['itemNo02'] = trim(el['ITEM NO 2']);
        excelRow['perilCode02'] = trim(el['PERIL ITEM 2']);
        excelRow['amountCovered02'] = trim(el['AMOUNT COVERED 2']);
        excelRow['calcMethod02'] = trim(el['CALC METHOD 2']);
        excelRow['perilRt02'] = trim(el['PERIL RATE 2']);
        excelRow['grossPrem02'] = trim(el['GROSS PREMIUM 2']);
        excelRow['docStamp02'] = trim(el['DOCUMENT STAMP 2']);
        excelRow['lgt02'] = trim(el['LOCAL GOVT TAX 2']);
        excelRow['vat02'] = trim(el['VAT 2']);
        excelRow['fst02'] = trim(el['FST 2']);
        excelRow['otherTax02'] = trim(el['OTHER TAX 2']);
        excelRow['premTax02'] = trim(el['PREMIUM TAX 2']);
        excelRow['netPrem02'] = trim(el['NET PREMIUM 2']);
        excelRow['commRt02'] = trim(el['COMMISSION RATE 2']);
        excelRow['commAmt02'] = trim(el['COMMISSION AMOUNT 2']);
        excelRow['itemNo03'] = trim(el['ITEM NO 3']);
        excelRow['perilCode03'] = trim(el['PERIL ITEM 3']);
        excelRow['amountCovered03'] = trim(el['AMOUNT COVERED 3']);
        excelRow['calcMethod03'] = trim(el['CALC METHOD 3']);
        excelRow['perilRt03'] = trim(el['PERIL RATE 3']);
        excelRow['grossPrem03'] = trim(el['GROSS PREMIUM 3']);
        excelRow['docStamp03'] = trim(el['DOCUMENT STAMP 3']);
        excelRow['lgt03'] = trim(el['LOCAL GOVT TAX 3']);
        excelRow['vat03'] = trim(el['VAT 3']);
        excelRow['fst03'] = trim(el['FST 3']);
        excelRow['otherTax03'] = trim(el['OTHER TAX 3']);
        excelRow['premTax03'] = trim(el['PREMIUM TAX 3']);
        excelRow['netPrem03'] = trim(el['NET PREMIUM 3']);
        excelRow['commRt03'] = trim(el['COMMISSION RATE 3']);
        excelRow['commAmt03'] = trim(el['COMMISSION AMOUNT 3']);
        excelRow['itemNo04'] = trim(el['ITEM NO 4']);
        excelRow['perilCode04'] = trim(el['PERIL ITEM 4']);
        excelRow['amountCovered04'] = trim(el['AMOUNT COVERED 4']);
        excelRow['calcMethod04'] = trim(el['CALC METHOD 4']);
        excelRow['perilRt04'] = trim(el['PERIL RATE 4']);
        excelRow['grossPrem04'] = trim(el['GROSS PREMIUM 4']);
        excelRow['docStamp04'] = trim(el['DOCUMENT STAMP 4']);
        excelRow['lgt04'] = trim(el['LOCAL GOVT TAX 4']);
        excelRow['vat04'] = trim(el['VAT 4']);
        excelRow['fst04'] = trim(el['FST 4']);
        excelRow['otherTax04'] = trim(el['OTHER TAX 4']);
        excelRow['premTax04'] = trim(el['PREMIUM TAX 4']);
        excelRow['netPrem04'] = trim(el['NET PREMIUM 4']);
        excelRow['commRt04'] = trim(el['COMMISSION RATE 4']);
        excelRow['commAmt04'] = trim(el['COMMISSION AMOUNT 4']);
        excelRow['itemNo05'] = trim(el['ITEM NO 5']);
        excelRow['perilCode05'] = trim(el['PERIL ITEM 5']);
        excelRow['amountCovered05'] = trim(el['AMOUNT COVERED 5']);
        excelRow['calcMethod05'] = trim(el['CALC METHOD 5']);
        excelRow['perilRt05'] = trim(el['PERIL RATE 5']);
        excelRow['grossPrem05'] = trim(el['GROSS PREMIUM 5']);
        excelRow['docStamp05'] = trim(el['DOCUMENT STAMP 5']);
        excelRow['lgt05'] = trim(el['LOCAL GOVT TAX 5']);
        excelRow['vat05'] = trim(el['VAT 5']);
        excelRow['fst05'] = trim(el['FST 5']);
        excelRow['otherTax05'] = trim(el['OTHER TAX 5']);
        excelRow['premTax05'] = trim(el['PREMIUM TAX 5']);
        excelRow['netPrem05'] = trim(el['NET PREMIUM 5']);
        excelRow['commRt05'] = trim(el['COMMISSION RATE 5']);
        excelRow['commAmt05'] = trim(el['COMMISSION AMOUNT 5']);
        excelRow['itemNo06'] = trim(el['ITEM NO 6']);
        excelRow['perilCode06'] = trim(el['PERIL ITEM 6']);
        excelRow['amountCovered06'] = trim(el['AMOUNT COVERED 6']);
        excelRow['calcMethod06'] = trim(el['CALC METHOD 6']);
        excelRow['perilRt06'] = trim(el['PERIL RATE 6']);
        excelRow['grossPrem06'] = trim(el['GROSS PREMIUM 6']);
        excelRow['docStamp06'] = trim(el['DOCUMENT STAMP 6']);
        excelRow['lgt06'] = trim(el['LOCAL GOVT TAX 6']);
        excelRow['vat06'] = trim(el['VAT 6']);
        excelRow['fst06'] = trim(el['FST 6']);
        excelRow['otherTax06'] = trim(el['OTHER TAX 6']);
        excelRow['premTax06'] = trim(el['PREMIUM TAX 6']);
        excelRow['netPrem06'] = trim(el['NET PREMIUM 6']);
        excelRow['commRt06'] = trim(el['COMMISSION RATE 6']);
        excelRow['commAmt06'] = trim(el['COMMISSION AMOUNT 6']);
        excelRow['itemNo07'] = trim(el['ITEM NO 7']);
        excelRow['perilCode07'] = trim(el['PERIL ITEM 7']);
        excelRow['amountCovered07'] = trim(el['AMOUNT COVERED 7']);
        excelRow['calcMethod07'] = trim(el['CALC METHOD 7']);
        excelRow['perilRt07'] = trim(el['PERIL RATE 7']);
        excelRow['grossPrem07'] = trim(el['GROSS PREMIUM 7']);
        excelRow['docStamp07'] = trim(el['DOCUMENT STAMP 7']);
        excelRow['lgt07'] = trim(el['LOCAL GOVT TAX 7']);
        excelRow['vat07'] = trim(el['VAT 7']);
        excelRow['fst07'] = trim(el['FST 7']);
        excelRow['otherTax07'] = trim(el['OTHER TAX 7']);
        excelRow['premTax07'] = trim(el['PREMIUM TAX 7']);
        excelRow['netPrem07'] = trim(el['NET PREMIUM 7']);
        excelRow['commRt07'] = trim(el['COMMISSION RATE 7']);
        excelRow['commAmt07'] = trim(el['COMMISSION AMOUNT 7']);
        excelRow['itemNo08'] = trim(el['ITEM NO 8']);
        excelRow['perilCode08'] = trim(el['PERIL ITEM 8']);
        excelRow['amountCovered08'] = trim(el['AMOUNT COVERED 8']);
        excelRow['calcMethod08'] = trim(el['CALC METHOD 8']);
        excelRow['perilRt08'] = trim(el['PERIL RATE 8']);
        excelRow['grossPrem08'] = trim(el['GROSS PREMIUM 8']);
        excelRow['docStamp08'] = trim(el['DOCUMENT STAMP 8']);
        excelRow['lgt08'] = trim(el['LOCAL GOVT TAX 8']);
        excelRow['vat08'] = trim(el['VAT 8']);
        excelRow['fst08'] = trim(el['FST 8']);
        excelRow['otherTax08'] = trim(el['OTHER TAX 8']);
        excelRow['premTax08'] = trim(el['PREMIUM TAX 8']);
        excelRow['netPrem08'] = trim(el['NET PREMIUM 8']);
        excelRow['commRt08'] = trim(el['COMMISSION RATE 8']);
        excelRow['commAmt08'] = trim(el['COMMISSION AMOUNT 8']);
        excelRow['itemNo09'] = trim(el['ITEM NO 9']);
        excelRow['perilCode09'] = trim(el['PERIL ITEM 9']);
        excelRow['amountCovered09'] = trim(el['AMOUNT COVERED 9']);
        excelRow['calcMethod09'] = trim(el['CALC METHOD 9']);
        excelRow['perilRt09'] = trim(el['PERIL RATE 9']);
        excelRow['grossPrem09'] = trim(el['GROSS PREMIUM 9']);
        excelRow['docStamp09'] = trim(el['DOCUMENT STAMP 9']);
        excelRow['lgt09'] = trim(el['LOCAL GOVT TAX 9']);
        excelRow['vat09'] = trim(el['VAT 9']);
        excelRow['fst09'] = trim(el['FST 9']);
        excelRow['otherTax09'] = trim(el['OTHER TAX 9']);
        excelRow['premTax09'] = trim(el['PREMIUM TAX 9']);
        excelRow['netPrem09'] = trim(el['NET PREMIUM 9']);
        excelRow['commRt09'] = trim(el['COMMISSION RATE 9']);
        excelRow['commAmt09'] = trim(el['COMMISSION AMOUNT 9']);
        excelRow['itemNo10'] = trim(el['ITEM NO 10']);
        excelRow['perilCode10'] = trim(el['PERIL ITEM 10']);
        excelRow['amountCovered10'] = trim(el['AMOUNT COVERED 10']);
        excelRow['calcMethod10'] = trim(el['CALC METHOD 10']);
        excelRow['perilRt10'] = trim(el['PERIL RATE 10']);
        excelRow['grossPrem10'] = trim(el['GROSS PREMIUM 10']);
        excelRow['docStamp10'] = trim(el['DOCUMENT STAMP 10']);
        excelRow['lgt10'] = trim(el['LOCAL GOVT TAX 10']);
        excelRow['vat10'] = trim(el['VAT 10']);
        excelRow['fst10'] = trim(el['FST 10']);
        excelRow['otherTax10'] = trim(el['OTHER TAX 10']);
        excelRow['premTax10'] = trim(el['PREMIUM TAX 10']);
        excelRow['netPrem10'] = trim(el['NET PREMIUM 10']);
        excelRow['commRt10'] = trim(el['COMMISSION RATE 10']);
        excelRow['commAmt10'] = trim(el['COMMISSION AMOUNT 10']);
        excelRow['itemNo11'] = trim(el['ITEM NO 11']);
        excelRow['perilCode11'] = trim(el['PERIL ITEM 11']);
        excelRow['amountCovered11'] = trim(el['AMOUNT COVERED 11']);
        excelRow['calcMethod11'] = trim(el['CALC METHOD 11']);
        excelRow['perilRt11'] = trim(el['PERIL RATE 11']);
        excelRow['grossPrem11'] = trim(el['GROSS PREMIUM 11']);
        excelRow['docStamp11'] = trim(el['DOCUMENT STAMP 11']);
        excelRow['lgt11'] = trim(el['LOCAL GOVT TAX 11']);
        excelRow['vat11'] = trim(el['VAT 11']);
        excelRow['fst11'] = trim(el['FST 11']);
        excelRow['otherTax11'] = trim(el['OTHER TAX 11']);
        excelRow['premTax11'] = trim(el['PREMIUM TAX 11']);
        excelRow['netPrem11'] = trim(el['NET PREMIUM 11']);
        excelRow['commRt11'] = trim(el['COMMISSION RATE 11']);
        excelRow['commAmt11'] = trim(el['COMMISSION AMOUNT 11']);
        excelRow['itemNo12'] = trim(el['ITEM NO 12']);
        excelRow['perilCode12'] = trim(el['PERIL ITEM 12']);
        excelRow['amountCovered12'] = trim(el['AMOUNT COVERED 12']);
        excelRow['calcMethod12'] = trim(el['CALC METHOD 12']);
        excelRow['perilRt12'] = trim(el['PERIL RATE 12']);
        excelRow['grossPrem12'] = trim(el['GROSS PREMIUM 12']);
        excelRow['docStamp12'] = trim(el['DOCUMENT STAMP 12']);
        excelRow['lgt12'] = trim(el['LOCAL GOVT TAX 12']);
        excelRow['vat12'] = trim(el['VAT 12']);
        excelRow['fst12'] = trim(el['FST 12']);
        excelRow['otherTax12'] = trim(el['OTHER TAX 12']);
        excelRow['premTax12'] = trim(el['PREMIUM TAX 12']);
        excelRow['netPrem12'] = trim(el['NET PREMIUM 12']);
        excelRow['commRt12'] = trim(el['COMMISSION RATE 12']);
        excelRow['commAmt12'] = trim(el['COMMISSION AMOUNT 12']);
        excelRow['itemNo13'] = trim(el['ITEM NO 13']);
        excelRow['perilCode13'] = trim(el['PERIL ITEM 13']);
        excelRow['amountCovered13'] = trim(el['AMOUNT COVERED 13']);
        excelRow['calcMethod13'] = trim(el['CALC METHOD 13']);
        excelRow['perilRt13'] = trim(el['PERIL RATE 13']);
        excelRow['grossPrem13'] = trim(el['GROSS PREMIUM 13']);
        excelRow['docStamp13'] = trim(el['DOCUMENT STAMP 13']);
        excelRow['lgt13'] = trim(el['LOCAL GOVT TAX 13']);
        excelRow['vat13'] = trim(el['VAT 13']);
        excelRow['fst13'] = trim(el['FST 13']);
        excelRow['otherTax13'] = trim(el['OTHER TAX 13']);
        excelRow['premTax13'] = trim(el['PREMIUM TAX 13']);
        excelRow['netPrem13'] = trim(el['NET PREMIUM 13']);
        excelRow['commRt13'] = trim(el['COMMISSION RATE 13']);
        excelRow['commAmt13'] = trim(el['COMMISSION AMOUNT 13']);
        excelRow['itemNo14'] = trim(el['ITEM NO 14']);
        excelRow['perilCode14'] = trim(el['PERIL ITEM 14']);
        excelRow['amountCovered14'] = trim(el['AMOUNT COVERED 14']);
        excelRow['calcMethod14'] = trim(el['CALC METHOD 14']);
        excelRow['perilRt14'] = trim(el['PERIL RATE 14']);
        excelRow['grossPrem14'] = trim(el['GROSS PREMIUM 14']);
        excelRow['docStamp14'] = trim(el['DOCUMENT STAMP 14']);
        excelRow['lgt14'] = trim(el['LOCAL GOVT TAX 14']);
        excelRow['vat14'] = trim(el['VAT 14']);
        excelRow['fst14'] = trim(el['FST 14']);
        excelRow['otherTax14'] = trim(el['OTHER TAX 14']);
        excelRow['premTax14'] = trim(el['PREMIUM TAX 14']);
        excelRow['netPrem14'] = trim(el['NET PREMIUM 14']);
        excelRow['commRt14'] = trim(el['COMMISSION RATE 14']);
        excelRow['commAmt14'] = trim(el['COMMISSION AMOUNT 14']);
        excelRow['itemNo15'] = trim(el['ITEM NO 15']);
        excelRow['perilCode15'] = trim(el['PERIL ITEM 15']);
        excelRow['amountCovered15'] = trim(el['AMOUNT COVERED 15']);
        excelRow['calcMethod15'] = trim(el['CALC METHOD 15']);
        excelRow['perilRt15'] = trim(el['PERIL RATE 15']);
        excelRow['grossPrem15'] = trim(el['GROSS PREMIUM 15']);
        excelRow['docStamp15'] = trim(el['DOCUMENT STAMP 15']);
        excelRow['lgt15'] = trim(el['LOCAL GOVT TAX 15']);
        excelRow['vat15'] = trim(el['VAT 15']);
        excelRow['fst15'] = trim(el['FST 15']);
        excelRow['otherTax15'] = trim(el['OTHER TAX 15']);
        excelRow['premTax15'] = trim(el['PREMIUM TAX 15']);
        excelRow['netPrem15'] = trim(el['NET PREMIUM 15']);
        excelRow['commRt15'] = trim(el['COMMISSION RATE 15']);
        excelRow['commAmt15'] = trim(el['COMMISSION AMOUNT 15']);
        excelRow['itemNo16'] = trim(el['ITEM NO 16']);
        excelRow['perilCode16'] = trim(el['PERIL ITEM 16']);
        excelRow['amountCovered16'] = trim(el['AMOUNT COVERED 16']);
        excelRow['calcMethod16'] = trim(el['CALC METHOD 16']);
        excelRow['perilRt16'] = trim(el['PERIL RATE 16']);
        excelRow['grossPrem16'] = trim(el['GROSS PREMIUM 16']);
        excelRow['docStamp16'] = trim(el['DOCUMENT STAMP 16']);
        excelRow['lgt16'] = trim(el['LOCAL GOVT TAX 16']);
        excelRow['vat16'] = trim(el['VAT 16']);
        excelRow['fst16'] = trim(el['FST 16']);
        excelRow['otherTax16'] = trim(el['OTHER TAX 16']);
        excelRow['premTax16'] = trim(el['PREMIUM TAX 16']);
        excelRow['netPrem16'] = trim(el['NET PREMIUM 16']);
        excelRow['commRt16'] = trim(el['COMMISSION RATE 16']);
        excelRow['commAmt16'] = trim(el['COMMISSION AMOUNT 16']);
        excelRow['itemNo17'] = trim(el['ITEM NO 17']);
        excelRow['perilCode17'] = trim(el['PERIL ITEM 17']);
        excelRow['amountCovered17'] = trim(el['AMOUNT COVERED 17']);
        excelRow['calcMethod17'] = trim(el['CALC METHOD 17']);
        excelRow['perilRt17'] = trim(el['PERIL RATE 17']);
        excelRow['grossPrem17'] = trim(el['GROSS PREMIUM 17']);
        excelRow['docStamp17'] = trim(el['DOCUMENT STAMP 17']);
        excelRow['lgt17'] = trim(el['LOCAL GOVT TAX 17']);
        excelRow['vat17'] = trim(el['VAT 17']);
        excelRow['fst17'] = trim(el['FST 17']);
        excelRow['otherTax17'] = trim(el['OTHER TAX 17']);
        excelRow['premTax17'] = trim(el['PREMIUM TAX 17']);
        excelRow['netPrem17'] = trim(el['NET PREMIUM 17']);
        excelRow['commRt17'] = trim(el['COMMISSION RATE 17']);
        excelRow['commAmt17'] = trim(el['COMMISSION AMOUNT 17']);
        excelRow['itemNo18'] = trim(el['ITEM NO 18']);
        excelRow['perilCode18'] = trim(el['PERIL ITEM 18']);
        excelRow['amountCovered18'] = trim(el['AMOUNT COVERED 18']);
        excelRow['calcMethod18'] = trim(el['CALC METHOD 18']);
        excelRow['perilRt18'] = trim(el['PERIL RATE 18']);
        excelRow['grossPrem18'] = trim(el['GROSS PREMIUM 18']);
        excelRow['docStamp18'] = trim(el['DOCUMENT STAMP 18']);
        excelRow['lgt18'] = trim(el['LOCAL GOVT TAX 18']);
        excelRow['vat18'] = trim(el['VAT 18']);
        excelRow['fst18'] = trim(el['FST 18']);
        excelRow['otherTax18'] = trim(el['OTHER TAX 18']);
        excelRow['premTax18'] = trim(el['PREMIUM TAX 18']);
        excelRow['netPrem18'] = trim(el['NET PREMIUM 18']);
        excelRow['commRt18'] = trim(el['COMMISSION RATE 18']);
        excelRow['commAmt18'] = trim(el['COMMISSION AMOUNT 18']);
        excelRow['itemNo19'] = trim(el['ITEM NO 19']);
        excelRow['perilCode19'] = trim(el['PERIL ITEM 19']);
        excelRow['amountCovered19'] = trim(el['AMOUNT COVERED 19']);
        excelRow['calcMethod19'] = trim(el['CALC METHOD 19']);
        excelRow['perilRt19'] = trim(el['PERIL RATE 19']);
        excelRow['grossPrem19'] = trim(el['GROSS PREMIUM 19']);
        excelRow['docStamp19'] = trim(el['DOCUMENT STAMP 19']);
        excelRow['lgt19'] = trim(el['LOCAL GOVT TAX 19']);
        excelRow['vat19'] = trim(el['VAT 19']);
        excelRow['fst19'] = trim(el['FST 19']);
        excelRow['otherTax19'] = trim(el['OTHER TAX 19']);
        excelRow['premTax19'] = trim(el['PREMIUM TAX 19']);
        excelRow['netPrem19'] = trim(el['NET PREMIUM 19']);
        excelRow['commRt19'] = trim(el['COMMISSION RATE 19']);
        excelRow['commAmt19'] = trim(el['COMMISSION AMOUNT 19']);
        excelRow['itemNo20'] = trim(el['ITEM NO 20']);
        excelRow['perilCode20'] = trim(el['PERIL ITEM 20']);
        excelRow['amountCovered20'] = trim(el['AMOUNT COVERED 20']);
        excelRow['calcMethod20'] = trim(el['CALC METHOD 20']);
        excelRow['perilRt20'] = trim(el['PERIL RATE 20']);
        excelRow['grossPrem20'] = trim(el['GROSS PREMIUM 20']);
        excelRow['docStamp20'] = trim(el['DOCUMENT STAMP 20']);
        excelRow['lgt20'] = trim(el['LOCAL GOVT TAX 20']);
        excelRow['vat20'] = trim(el['VAT 20']);
        excelRow['fst20'] = trim(el['FST 20']);
        excelRow['otherTax20'] = trim(el['OTHER TAX 20']);
        excelRow['premTax20'] = trim(el['PREMIUM TAX 20']);
        excelRow['netPrem20'] = trim(el['NET PREMIUM 20']);
        excelRow['commRt20'] = trim(el['COMMISSION RATE 20']);
        excelRow['commAmt20'] = trim(el['COMMISSION AMOUNT 20']);
        excelRow['itemNo21'] = trim(el['ITEM NO 21']);
        excelRow['perilCode21'] = trim(el['PERIL ITEM 21']);
        excelRow['amountCovered21'] = trim(el['AMOUNT COVERED 21']);
        excelRow['calcMethod21'] = trim(el['CALC METHOD 21']);
        excelRow['perilRt21'] = trim(el['PERIL RATE 21']);
        excelRow['grossPrem21'] = trim(el['GROSS PREMIUM 21']);
        excelRow['docStamp21'] = trim(el['DOCUMENT STAMP 21']);
        excelRow['lgt21'] = trim(el['LOCAL GOVT TAX 21']);
        excelRow['vat21'] = trim(el['VAT 21']);
        excelRow['fst21'] = trim(el['FST 21']);
        excelRow['otherTax21'] = trim(el['OTHER TAX 21']);
        excelRow['premTax21'] = trim(el['PREMIUM TAX 21']);
        excelRow['netPrem21'] = trim(el['NET PREMIUM 21']);
        excelRow['commRt21'] = trim(el['COMMISSION RATE 21']);
        excelRow['commAmt21'] = trim(el['COMMISSION AMOUNT 21']);
        excelRow['itemNo22'] = trim(el['ITEM NO 22']);
        excelRow['perilCode22'] = trim(el['PERIL ITEM 22']);
        excelRow['amountCovered22'] = trim(el['AMOUNT COVERED 22']);
        excelRow['calcMethod22'] = trim(el['CALC METHOD 22']);
        excelRow['perilRt22'] = trim(el['PERIL RATE 22']);
        excelRow['grossPrem22'] = trim(el['GROSS PREMIUM 22']);
        excelRow['docStamp22'] = trim(el['DOCUMENT STAMP 22']);
        excelRow['lgt22'] = trim(el['LOCAL GOVT TAX 22']);
        excelRow['vat22'] = trim(el['VAT 22']);
        excelRow['fst22'] = trim(el['FST 22']);
        excelRow['otherTax22'] = trim(el['OTHER TAX 22']);
        excelRow['premTax22'] = trim(el['PREMIUM TAX 22']);
        excelRow['netPrem22'] = trim(el['NET PREMIUM 22']);
        excelRow['commRt22'] = trim(el['COMMISSION RATE 22']);
        excelRow['commAmt22'] = trim(el['COMMISSION AMOUNT 22']);
        excelRow['itemNo23'] = trim(el['ITEM NO 23']);
        excelRow['perilCode23'] = trim(el['PERIL ITEM 23']);
        excelRow['amountCovered23'] = trim(el['AMOUNT COVERED 23']);
        excelRow['calcMethod23'] = trim(el['CALC METHOD 23']);
        excelRow['perilRt23'] = trim(el['PERIL RATE 23']);
        excelRow['grossPrem23'] = trim(el['GROSS PREMIUM 23']);
        excelRow['docStamp23'] = trim(el['DOCUMENT STAMP 23']);
        excelRow['lgt23'] = trim(el['LOCAL GOVT TAX 23']);
        excelRow['vat23'] = trim(el['VAT 23']);
        excelRow['fst23'] = trim(el['FST 23']);
        excelRow['otherTax23'] = trim(el['OTHER TAX 23']);
        excelRow['premTax23'] = trim(el['PREMIUM TAX 23']);
        excelRow['netPrem23'] = trim(el['NET PREMIUM 23']);
        excelRow['commRt23'] = trim(el['COMMISSION RATE 23']);
        excelRow['commAmt23'] = trim(el['COMMISSION AMOUNT 23']);
        excelRow['itemNo24'] = trim(el['ITEM NO 24']);
        excelRow['perilCode24'] = trim(el['PERIL ITEM 24']);
        excelRow['amountCovered24'] = trim(el['AMOUNT COVERED 24']);
        excelRow['calcMethod24'] = trim(el['CALC METHOD 24']);
        excelRow['perilRt24'] = trim(el['PERIL RATE 24']);
        excelRow['grossPrem24'] = trim(el['GROSS PREMIUM 24']);
        excelRow['docStamp24'] = trim(el['DOCUMENT STAMP 24']);
        excelRow['lgt24'] = trim(el['LOCAL GOVT TAX 24']);
        excelRow['vat24'] = trim(el['VAT 24']);
        excelRow['fst24'] = trim(el['FST 24']);
        excelRow['otherTax24'] = trim(el['OTHER TAX 24']);
        excelRow['premTax24'] = trim(el['PREMIUM TAX 24']);
        excelRow['netPrem24'] = trim(el['NET PREMIUM 24']);
        excelRow['commRt24'] = trim(el['COMMISSION RATE 24']);
        excelRow['commAmt24'] = trim(el['COMMISSION AMOUNT 24']);
        excelRow['itemNo25'] = trim(el['ITEM NO 25']);
        excelRow['perilCode25'] = trim(el['PERIL ITEM 25']);
        excelRow['amountCovered25'] = trim(el['AMOUNT COVERED 25']);
        excelRow['calcMethod25'] = trim(el['CALC METHOD 25']);
        excelRow['perilRt25'] = trim(el['PERIL RATE 25']);
        excelRow['grossPrem25'] = trim(el['GROSS PREMIUM 25']);
        excelRow['docStamp25'] = trim(el['DOCUMENT STAMP 25']);
        excelRow['lgt25'] = trim(el['LOCAL GOVT TAX 25']);
        excelRow['vat25'] = trim(el['VAT 25']);
        excelRow['fst25'] = trim(el['FST 25']);
        excelRow['otherTax25'] = trim(el['OTHER TAX 25']);
        excelRow['premTax25'] = trim(el['PREMIUM TAX 25']);
        excelRow['netPrem25'] = trim(el['NET PREMIUM 25']);
        excelRow['commRt25'] = trim(el['COMMISSION RATE 25']);
        excelRow['commAmt25'] = trim(el['COMMISSION AMOUNT 25']);
        excelRow['itemNo26'] = trim(el['ITEM NO 26']);
        excelRow['perilCode26'] = trim(el['PERIL ITEM 26']);
        excelRow['amountCovered26'] = trim(el['AMOUNT COVERED 26']);
        excelRow['calcMethod26'] = trim(el['CALC METHOD 26']);
        excelRow['perilRt26'] = trim(el['PERIL RATE 26']);
        excelRow['grossPrem26'] = trim(el['GROSS PREMIUM 26']);
        excelRow['docStamp26'] = trim(el['DOCUMENT STAMP 26']);
        excelRow['lgt26'] = trim(el['LOCAL GOVT TAX 26']);
        excelRow['vat26'] = trim(el['VAT 26']);
        excelRow['fst26'] = trim(el['FST 26']);
        excelRow['otherTax26'] = trim(el['OTHER TAX 26']);
        excelRow['premTax26'] = trim(el['PREMIUM TAX 26']);
        excelRow['netPrem26'] = trim(el['NET PREMIUM 26']);
        excelRow['commRt26'] = trim(el['COMMISSION RATE 26']);
        excelRow['commAmt26'] = trim(el['COMMISSION AMOUNT 26']);
        excelRow['itemNo27'] = trim(el['ITEM NO 27']);
        excelRow['perilCode27'] = trim(el['PERIL ITEM 27']);
        excelRow['amountCovered27'] = trim(el['AMOUNT COVERED 27']);
        excelRow['calcMethod27'] = trim(el['CALC METHOD 27']);
        excelRow['perilRt27'] = trim(el['PERIL RATE 27']);
        excelRow['grossPrem27'] = trim(el['GROSS PREMIUM 27']);
        excelRow['docStamp27'] = trim(el['DOCUMENT STAMP 27']);
        excelRow['lgt27'] = trim(el['LOCAL GOVT TAX 27']);
        excelRow['vat27'] = trim(el['VAT 27']);
        excelRow['fst27'] = trim(el['FST 27']);
        excelRow['otherTax27'] = trim(el['OTHER TAX 27']);
        excelRow['premTax27'] = trim(el['PREMIUM TAX 27']);
        excelRow['netPrem27'] = trim(el['NET PREMIUM 27']);
        excelRow['commRt27'] = trim(el['COMMISSION RATE 27']);
        excelRow['commAmt27'] = trim(el['COMMISSION AMOUNT 27']);
        excelRow['itemNo28'] = trim(el['ITEM NO 28']);
        excelRow['perilCode28'] = trim(el['PERIL ITEM 28']);
        excelRow['amountCovered28'] = trim(el['AMOUNT COVERED 28']);
        excelRow['calcMethod28'] = trim(el['CALC METHOD 28']);
        excelRow['perilRt28'] = trim(el['PERIL RATE 28']);
        excelRow['grossPrem28'] = trim(el['GROSS PREMIUM 28']);
        excelRow['docStamp28'] = trim(el['DOCUMENT STAMP 28']);
        excelRow['lgt28'] = trim(el['LOCAL GOVT TAX 28']);
        excelRow['vat28'] = trim(el['VAT 28']);
        excelRow['fst28'] = trim(el['FST 28']);
        excelRow['otherTax28'] = trim(el['OTHER TAX 28']);
        excelRow['premTax28'] = trim(el['PREMIUM TAX 28']);
        excelRow['netPrem28'] = trim(el['NET PREMIUM 28']);
        excelRow['commRt28'] = trim(el['COMMISSION RATE 28']);
        excelRow['commAmt28'] = trim(el['COMMISSION AMOUNT 28']);
        excelRow['itemNo29'] = trim(el['ITEM NO 29']);
        excelRow['perilCode29'] = trim(el['PERIL ITEM 29']);
        excelRow['amountCovered29'] = trim(el['AMOUNT COVERED 29']);
        excelRow['calcMethod29'] = trim(el['CALC METHOD 29']);
        excelRow['perilRt29'] = trim(el['PERIL RATE 29']);
        excelRow['grossPrem29'] = trim(el['GROSS PREMIUM 29']);
        excelRow['docStamp29'] = trim(el['DOCUMENT STAMP 29']);
        excelRow['lgt29'] = trim(el['LOCAL GOVT TAX 29']);
        excelRow['vat29'] = trim(el['VAT 29']);
        excelRow['fst29'] = trim(el['FST 29']);
        excelRow['otherTax29'] = trim(el['OTHER TAX 29']);
        excelRow['premTax29'] = trim(el['PREMIUM TAX 29']);
        excelRow['netPrem29'] = trim(el['NET PREMIUM 29']);
        excelRow['commRt29'] = trim(el['COMMISSION RATE 29']);
        excelRow['commAmt29'] = trim(el['COMMISSION AMOUNT 29']);
        excelRow['itemNo30'] = trim(el['ITEM NO 30']);
        excelRow['perilCode30'] = trim(el['PERIL ITEM 30']);
        excelRow['amountCovered30'] = trim(el['AMOUNT COVERED 30']);
        excelRow['calcMethod30'] = trim(el['CALC METHOD 30']);
        excelRow['perilRt30'] = trim(el['PERIL RATE 30']);
        excelRow['grossPrem30'] = trim(el['GROSS PREMIUM 30']);
        excelRow['docStamp30'] = trim(el['DOCUMENT STAMP 30']);
        excelRow['lgt30'] = trim(el['LOCAL GOVT TAX 30']);
        excelRow['vat30'] = trim(el['VAT 30']);
        excelRow['fst30'] = trim(el['FST 30']);
        excelRow['otherTax30'] = trim(el['OTHER TAX 30']);
        excelRow['premTax30'] = trim(el['PREMIUM TAX 30']);
        excelRow['netPrem30'] = trim(el['NET PREMIUM 30']);
        excelRow['commRt30'] = trim(el['COMMISSION RATE 30']);
        excelRow['commAmt30'] = trim(el['COMMISSION AMOUNT 30']);
        excelRow['wcItemNo01'] = trim(el['WC ITEM NO 1']);
        excelRow['wcCd01'] = trim(el['WARRANTY/CLAUSE CODE 1']);
        excelRow['wcTextA01'] = trim(el['WC TEXT A 1']);
        excelRow['wcTextB01'] = trim(el['WC TEXT B 1']);
        excelRow['wcTextC01'] = trim(el['WC TEXT C 1']);
        excelRow['wcTextD01'] = trim(el['WC TEXT D 1']);
        excelRow['wcTextE01'] = trim(el['WC TEXT E 1']);
        excelRow['wcTextF01'] = trim(el['WC TEXT F 1']);
        excelRow['wcTextG01'] = trim(el['WC TEXT G 1']);
        excelRow['wcTextH01'] = trim(el['WC TEXT H 1']);
        excelRow['wcTextI01'] = trim(el['WC TEXT I 1']);
        excelRow['wcTextJ01'] = trim(el['WC TEXT J 1']);
        excelRow['wcTextK01'] = trim(el['WC TEXT K 1']);
        excelRow['wcTextL01'] = trim(el['WC TEXT L 1']);
        excelRow['wcTextM01'] = trim(el['WC TEXT M 1']);
        excelRow['wcTextN01'] = trim(el['WC TEXT N 1']);
        excelRow['wcTextO01'] = trim(el['WC TEXT O 1']);
        excelRow['wcItemNo02'] = trim(el['WC ITEM NO 2']);
        excelRow['wcCd02'] = trim(el['WARRANTY/CLAUSE CODE 2']);
        excelRow['wcTextA02'] = trim(el['WC TEXT A 2']);
        excelRow['wcTextB02'] = trim(el['WC TEXT B 2']);
        excelRow['wcTextC02'] = trim(el['WC TEXT C 2']);
        excelRow['wcTextD02'] = trim(el['WC TEXT D 2']);
        excelRow['wcTextE02'] = trim(el['WC TEXT E 2']);
        excelRow['wcTextF02'] = trim(el['WC TEXT F 2']);
        excelRow['wcTextG02'] = trim(el['WC TEXT G 2']);
        excelRow['wcTextH02'] = trim(el['WC TEXT H 2']);
        excelRow['wcTextI02'] = trim(el['WC TEXT I 2']);
        excelRow['wcTextJ02'] = trim(el['WC TEXT J 2']);
        excelRow['wcTextK02'] = trim(el['WC TEXT K 2']);
        excelRow['wcTextL02'] = trim(el['WC TEXT L 2']);
        excelRow['wcTextM02'] = trim(el['WC TEXT M 2']);
        excelRow['wcTextN02'] = trim(el['WC TEXT N 2']);
        excelRow['wcTextO02'] = trim(el['WC TEXT O 2']);
        excelRow['wcItemNo03'] = trim(el['WC ITEM NO 3']);
        excelRow['wcCd03'] = trim(el['WARRANTY/CLAUSE CODE 3']);
        excelRow['wcTextA03'] = trim(el['WC TEXT A 3']);
        excelRow['wcTextB03'] = trim(el['WC TEXT B 3']);
        excelRow['wcTextC03'] = trim(el['WC TEXT C 3']);
        excelRow['wcTextD03'] = trim(el['WC TEXT D 3']);
        excelRow['wcTextE03'] = trim(el['WC TEXT E 3']);
        excelRow['wcTextF03'] = trim(el['WC TEXT F 3']);
        excelRow['wcTextG03'] = trim(el['WC TEXT G 3']);
        excelRow['wcTextH03'] = trim(el['WC TEXT H 3']);
        excelRow['wcTextI03'] = trim(el['WC TEXT I 3']);
        excelRow['wcTextJ03'] = trim(el['WC TEXT J 3']);
        excelRow['wcTextK03'] = trim(el['WC TEXT K 3']);
        excelRow['wcTextL03'] = trim(el['WC TEXT L 3']);
        excelRow['wcTextM03'] = trim(el['WC TEXT M 3']);
        excelRow['wcTextN03'] = trim(el['WC TEXT N 3']);
        excelRow['wcTextO03'] = trim(el['WC TEXT O 3']);
        excelRow['wcItemNo04'] = trim(el['WC ITEM NO 4']);
        excelRow['wcCd04'] = trim(el['WARRANTY/CLAUSE CODE 4']);
        excelRow['wcTextA04'] = trim(el['WC TEXT A 4']);
        excelRow['wcTextB04'] = trim(el['WC TEXT B 4']);
        excelRow['wcTextC04'] = trim(el['WC TEXT C 4']);
        excelRow['wcTextD04'] = trim(el['WC TEXT D 4']);
        excelRow['wcTextE04'] = trim(el['WC TEXT E 4']);
        excelRow['wcTextF04'] = trim(el['WC TEXT F 4']);
        excelRow['wcTextG04'] = trim(el['WC TEXT G 4']);
        excelRow['wcTextH04'] = trim(el['WC TEXT H 4']);
        excelRow['wcTextI04'] = trim(el['WC TEXT I 4']);
        excelRow['wcTextJ04'] = trim(el['WC TEXT J 4']);
        excelRow['wcTextK04'] = trim(el['WC TEXT K 4']);
        excelRow['wcTextL04'] = trim(el['WC TEXT L 4']);
        excelRow['wcTextM04'] = trim(el['WC TEXT M 4']);
        excelRow['wcTextN04'] = trim(el['WC TEXT N 4']);
        excelRow['wcTextO04'] = trim(el['WC TEXT O 4']);
        excelRow['wcItemNo05'] = trim(el['WC ITEM NO 5']);
        excelRow['wcCd05'] = trim(el['WARRANTY/CLAUSE CODE 5']);
        excelRow['wcTextA05'] = trim(el['WC TEXT A 5']);
        excelRow['wcTextB05'] = trim(el['WC TEXT B 5']);
        excelRow['wcTextC05'] = trim(el['WC TEXT C 5']);
        excelRow['wcTextD05'] = trim(el['WC TEXT D 5']);
        excelRow['wcTextE05'] = trim(el['WC TEXT E 5']);
        excelRow['wcTextF05'] = trim(el['WC TEXT F 5']);
        excelRow['wcTextG05'] = trim(el['WC TEXT G 5']);
        excelRow['wcTextH05'] = trim(el['WC TEXT H 5']);
        excelRow['wcTextI05'] = trim(el['WC TEXT I 5']);
        excelRow['wcTextJ05'] = trim(el['WC TEXT J 5']);
        excelRow['wcTextK05'] = trim(el['WC TEXT K 5']);
        excelRow['wcTextL05'] = trim(el['WC TEXT L 5']);
        excelRow['wcTextM05'] = trim(el['WC TEXT M 5']);
        excelRow['wcTextN05'] = trim(el['WC TEXT N 5']);
        excelRow['wcTextO05'] = trim(el['WC TEXT O 5']);
        excelRow['wcItemNo06'] = trim(el['WC ITEM NO 6']);
        excelRow['wcCd06'] = trim(el['WARRANTY/CLAUSE CODE 6']);
        excelRow['wcTextA06'] = trim(el['WC TEXT A 6']);
        excelRow['wcTextB06'] = trim(el['WC TEXT B 6']);
        excelRow['wcTextC06'] = trim(el['WC TEXT C 6']);
        excelRow['wcTextD06'] = trim(el['WC TEXT D 6']);
        excelRow['wcTextE06'] = trim(el['WC TEXT E 6']);
        excelRow['wcTextF06'] = trim(el['WC TEXT F 6']);
        excelRow['wcTextG06'] = trim(el['WC TEXT G 6']);
        excelRow['wcTextH06'] = trim(el['WC TEXT H 6']);
        excelRow['wcTextI06'] = trim(el['WC TEXT I 6']);
        excelRow['wcTextJ06'] = trim(el['WC TEXT J 6']);
        excelRow['wcTextK06'] = trim(el['WC TEXT K 6']);
        excelRow['wcTextL06'] = trim(el['WC TEXT L 6']);
        excelRow['wcTextM06'] = trim(el['WC TEXT M 6']);
        excelRow['wcTextN06'] = trim(el['WC TEXT N 6']);
        excelRow['wcTextO06'] = trim(el['WC TEXT O 6']);
        excelRow['wcItemNo07'] = trim(el['WC ITEM NO 7']);
        excelRow['wcCd07'] = trim(el['WARRANTY/CLAUSE CODE 7']);
        excelRow['wcTextA07'] = trim(el['WC TEXT A 7']);
        excelRow['wcTextB07'] = trim(el['WC TEXT B 7']);
        excelRow['wcTextC07'] = trim(el['WC TEXT C 7']);
        excelRow['wcTextD07'] = trim(el['WC TEXT D 7']);
        excelRow['wcTextE07'] = trim(el['WC TEXT E 7']);
        excelRow['wcTextF07'] = trim(el['WC TEXT F 7']);
        excelRow['wcTextG07'] = trim(el['WC TEXT G 7']);
        excelRow['wcTextH07'] = trim(el['WC TEXT H 7']);
        excelRow['wcTextI07'] = trim(el['WC TEXT I 7']);
        excelRow['wcTextJ07'] = trim(el['WC TEXT J 7']);
        excelRow['wcTextK07'] = trim(el['WC TEXT K 7']);
        excelRow['wcTextL07'] = trim(el['WC TEXT L 7']);
        excelRow['wcTextM07'] = trim(el['WC TEXT M 7']);
        excelRow['wcTextN07'] = trim(el['WC TEXT N 7']);
        excelRow['wcTextO07'] = trim(el['WC TEXT O 7']);
        excelRow['wcItemNo08'] = trim(el['WC ITEM NO 8']);
        excelRow['wcCd08'] = trim(el['WARRANTY/CLAUSE CODE 8']);
        excelRow['wcTextA08'] = trim(el['WC TEXT A 8']);
        excelRow['wcTextB08'] = trim(el['WC TEXT B 8']);
        excelRow['wcTextC08'] = trim(el['WC TEXT C 8']);
        excelRow['wcTextD08'] = trim(el['WC TEXT D 8']);
        excelRow['wcTextE08'] = trim(el['WC TEXT E 8']);
        excelRow['wcTextF08'] = trim(el['WC TEXT F 8']);
        excelRow['wcTextG08'] = trim(el['WC TEXT G 8']);
        excelRow['wcTextH08'] = trim(el['WC TEXT H 8']);
        excelRow['wcTextI08'] = trim(el['WC TEXT I 8']);
        excelRow['wcTextJ08'] = trim(el['WC TEXT J 8']);
        excelRow['wcTextK08'] = trim(el['WC TEXT K 8']);
        excelRow['wcTextL08'] = trim(el['WC TEXT L 8']);
        excelRow['wcTextM08'] = trim(el['WC TEXT M 8']);
        excelRow['wcTextN08'] = trim(el['WC TEXT N 8']);
        excelRow['wcTextO08'] = trim(el['WC TEXT O 8']);
        excelRow['wcItemNo09'] = trim(el['WC ITEM NO 9']);
        excelRow['wcCd09'] = trim(el['WARRANTY/CLAUSE CODE 9']);
        excelRow['wcTextA09'] = trim(el['WC TEXT A 9']);
        excelRow['wcTextB09'] = trim(el['WC TEXT B 9']);
        excelRow['wcTextC09'] = trim(el['WC TEXT C 9']);
        excelRow['wcTextD09'] = trim(el['WC TEXT D 9']);
        excelRow['wcTextE09'] = trim(el['WC TEXT E 9']);
        excelRow['wcTextF09'] = trim(el['WC TEXT F 9']);
        excelRow['wcTextG09'] = trim(el['WC TEXT G 9']);
        excelRow['wcTextH09'] = trim(el['WC TEXT H 9']);
        excelRow['wcTextI09'] = trim(el['WC TEXT I 9']);
        excelRow['wcTextJ09'] = trim(el['WC TEXT J 9']);
        excelRow['wcTextK09'] = trim(el['WC TEXT K 9']);
        excelRow['wcTextL09'] = trim(el['WC TEXT L 9']);
        excelRow['wcTextM09'] = trim(el['WC TEXT M 9']);
        excelRow['wcTextN09'] = trim(el['WC TEXT N 9']);
        excelRow['wcTextO09'] = trim(el['WC TEXT O 9']);
        excelRow['wcItemNo10'] = trim(el['WC ITEM NO 10']);
        excelRow['wcCd10'] = trim(el['WARRANTY/CLAUSE CODE 10']);
        excelRow['wcTextA10'] = trim(el['WC TEXT A 10']);
        excelRow['wcTextB10'] = trim(el['WC TEXT B 10']);
        excelRow['wcTextC10'] = trim(el['WC TEXT C 10']);
        excelRow['wcTextD10'] = trim(el['WC TEXT D 10']);
        excelRow['wcTextE10'] = trim(el['WC TEXT E 10']);
        excelRow['wcTextF10'] = trim(el['WC TEXT F 10']);
        excelRow['wcTextG10'] = trim(el['WC TEXT G 10']);
        excelRow['wcTextH10'] = trim(el['WC TEXT H 10']);
        excelRow['wcTextI10'] = trim(el['WC TEXT I 10']);
        excelRow['wcTextJ10'] = trim(el['WC TEXT J 10']);
        excelRow['wcTextK10'] = trim(el['WC TEXT K 10']);
        excelRow['wcTextL10'] = trim(el['WC TEXT L 10']);
        excelRow['wcTextM10'] = trim(el['WC TEXT M 10']);
        excelRow['wcTextN10'] = trim(el['WC TEXT N 10']);
        excelRow['wcTextO10'] = trim(el['WC TEXT O 10']);
        excelRow['wcItemNo11'] = trim(el['WC ITEM NO 11']);
        excelRow['wcCd11'] = trim(el['WARRANTY/CLAUSE CODE 11']);
        excelRow['wcTextA11'] = trim(el['WC TEXT A 11']);
        excelRow['wcTextB11'] = trim(el['WC TEXT B 11']);
        excelRow['wcTextC11'] = trim(el['WC TEXT C 11']);
        excelRow['wcTextD11'] = trim(el['WC TEXT D 11']);
        excelRow['wcTextE11'] = trim(el['WC TEXT E 11']);
        excelRow['wcTextF11'] = trim(el['WC TEXT F 11']);
        excelRow['wcTextG11'] = trim(el['WC TEXT G 11']);
        excelRow['wcTextH11'] = trim(el['WC TEXT H 11']);
        excelRow['wcTextI11'] = trim(el['WC TEXT I 11']);
        excelRow['wcTextJ11'] = trim(el['WC TEXT J 11']);
        excelRow['wcTextK11'] = trim(el['WC TEXT K 11']);
        excelRow['wcTextL11'] = trim(el['WC TEXT L 11']);
        excelRow['wcTextM11'] = trim(el['WC TEXT M 11']);
        excelRow['wcTextN11'] = trim(el['WC TEXT N 11']);
        excelRow['wcTextO11'] = trim(el['WC TEXT O 11']);
        excelRow['wcItemNo12'] = trim(el['WC ITEM NO 12']);
        excelRow['wcCd12'] = trim(el['WARRANTY/CLAUSE CODE 12']);
        excelRow['wcTextA12'] = trim(el['WC TEXT A 12']);
        excelRow['wcTextB12'] = trim(el['WC TEXT B 12']);
        excelRow['wcTextC12'] = trim(el['WC TEXT C 12']);
        excelRow['wcTextD12'] = trim(el['WC TEXT D 12']);
        excelRow['wcTextE12'] = trim(el['WC TEXT E 12']);
        excelRow['wcTextF12'] = trim(el['WC TEXT F 12']);
        excelRow['wcTextG12'] = trim(el['WC TEXT G 12']);
        excelRow['wcTextH12'] = trim(el['WC TEXT H 12']);
        excelRow['wcTextI12'] = trim(el['WC TEXT I 12']);
        excelRow['wcTextJ12'] = trim(el['WC TEXT J 12']);
        excelRow['wcTextK12'] = trim(el['WC TEXT K 12']);
        excelRow['wcTextL12'] = trim(el['WC TEXT L 12']);
        excelRow['wcTextM12'] = trim(el['WC TEXT M 12']);
        excelRow['wcTextN12'] = trim(el['WC TEXT N 12']);
        excelRow['wcTextO12'] = trim(el['WC TEXT O 12']);
        excelRow['wcItemNo13'] = trim(el['WC ITEM NO 13']);
        excelRow['wcCd13'] = trim(el['WARRANTY/CLAUSE CODE 13']);
        excelRow['wcTextA13'] = trim(el['WC TEXT A 13']);
        excelRow['wcTextB13'] = trim(el['WC TEXT B 13']);
        excelRow['wcTextC13'] = trim(el['WC TEXT C 13']);
        excelRow['wcTextD13'] = trim(el['WC TEXT D 13']);
        excelRow['wcTextE13'] = trim(el['WC TEXT E 13']);
        excelRow['wcTextF13'] = trim(el['WC TEXT F 13']);
        excelRow['wcTextG13'] = trim(el['WC TEXT G 13']);
        excelRow['wcTextH13'] = trim(el['WC TEXT H 13']);
        excelRow['wcTextI13'] = trim(el['WC TEXT I 13']);
        excelRow['wcTextJ13'] = trim(el['WC TEXT J 13']);
        excelRow['wcTextK13'] = trim(el['WC TEXT K 13']);
        excelRow['wcTextL13'] = trim(el['WC TEXT L 13']);
        excelRow['wcTextM13'] = trim(el['WC TEXT M 13']);
        excelRow['wcTextN13'] = trim(el['WC TEXT N 13']);
        excelRow['wcTextO13'] = trim(el['WC TEXT O 13']);
        excelRow['wcItemNo14'] = trim(el['WC ITEM NO 14']);
        excelRow['wcCd14'] = trim(el['WARRANTY/CLAUSE CODE 14']);
        excelRow['wcTextA14'] = trim(el['WC TEXT A 14']);
        excelRow['wcTextB14'] = trim(el['WC TEXT B 14']);
        excelRow['wcTextC14'] = trim(el['WC TEXT C 14']);
        excelRow['wcTextD14'] = trim(el['WC TEXT D 14']);
        excelRow['wcTextE14'] = trim(el['WC TEXT E 14']);
        excelRow['wcTextF14'] = trim(el['WC TEXT F 14']);
        excelRow['wcTextG14'] = trim(el['WC TEXT G 14']);
        excelRow['wcTextH14'] = trim(el['WC TEXT H 14']);
        excelRow['wcTextI14'] = trim(el['WC TEXT I 14']);
        excelRow['wcTextJ14'] = trim(el['WC TEXT J 14']);
        excelRow['wcTextK14'] = trim(el['WC TEXT K 14']);
        excelRow['wcTextL14'] = trim(el['WC TEXT L 14']);
        excelRow['wcTextM14'] = trim(el['WC TEXT M 14']);
        excelRow['wcTextN14'] = trim(el['WC TEXT N 14']);
        excelRow['wcTextO14'] = trim(el['WC TEXT O 14']);
        excelRow['wcItemNo15'] = trim(el['WC ITEM NO 15']);
        excelRow['wcCd15'] = trim(el['WARRANTY/CLAUSE CODE 15']);
        excelRow['wcTextA15'] = trim(el['WC TEXT A 15']);
        excelRow['wcTextB15'] = trim(el['WC TEXT B 15']);
        excelRow['wcTextC15'] = trim(el['WC TEXT C 15']);
        excelRow['wcTextD15'] = trim(el['WC TEXT D 15']);
        excelRow['wcTextE15'] = trim(el['WC TEXT E 15']);
        excelRow['wcTextF15'] = trim(el['WC TEXT F 15']);
        excelRow['wcTextG15'] = trim(el['WC TEXT G 15']);
        excelRow['wcTextH15'] = trim(el['WC TEXT H 15']);
        excelRow['wcTextI15'] = trim(el['WC TEXT I 15']);
        excelRow['wcTextJ15'] = trim(el['WC TEXT J 15']);
        excelRow['wcTextK15'] = trim(el['WC TEXT K 15']);
        excelRow['wcTextL15'] = trim(el['WC TEXT L 15']);
        excelRow['wcTextM15'] = trim(el['WC TEXT M 15']);
        excelRow['wcTextN15'] = trim(el['WC TEXT N 15']);
        excelRow['wcTextO15'] = trim(el['WC TEXT O 15']);
        excelRow['wcItemNo16'] = trim(el['WC ITEM NO 16']);
        excelRow['wcCd16'] = trim(el['WARRANTY/CLAUSE CODE 16']);
        excelRow['wcTextA16'] = trim(el['WC TEXT A 16']);
        excelRow['wcTextB16'] = trim(el['WC TEXT B 16']);
        excelRow['wcTextC16'] = trim(el['WC TEXT C 16']);
        excelRow['wcTextD16'] = trim(el['WC TEXT D 16']);
        excelRow['wcTextE16'] = trim(el['WC TEXT E 16']);
        excelRow['wcTextF16'] = trim(el['WC TEXT F 16']);
        excelRow['wcTextG16'] = trim(el['WC TEXT G 16']);
        excelRow['wcTextH16'] = trim(el['WC TEXT H 16']);
        excelRow['wcTextI16'] = trim(el['WC TEXT I 16']);
        excelRow['wcTextJ16'] = trim(el['WC TEXT J 16']);
        excelRow['wcTextK16'] = trim(el['WC TEXT K 16']);
        excelRow['wcTextL16'] = trim(el['WC TEXT L 16']);
        excelRow['wcTextM16'] = trim(el['WC TEXT M 16']);
        excelRow['wcTextN16'] = trim(el['WC TEXT N 16']);
        excelRow['wcTextO16'] = trim(el['WC TEXT O 16']);
        excelRow['wcItemNo17'] = trim(el['WC ITEM NO 17']);
        excelRow['wcCd17'] = trim(el['WARRANTY/CLAUSE CODE 17']);
        excelRow['wcTextA17'] = trim(el['WC TEXT A 17']);
        excelRow['wcTextB17'] = trim(el['WC TEXT B 17']);
        excelRow['wcTextC17'] = trim(el['WC TEXT C 17']);
        excelRow['wcTextD17'] = trim(el['WC TEXT D 17']);
        excelRow['wcTextE17'] = trim(el['WC TEXT E 17']);
        excelRow['wcTextF17'] = trim(el['WC TEXT F 17']);
        excelRow['wcTextG17'] = trim(el['WC TEXT G 17']);
        excelRow['wcTextH17'] = trim(el['WC TEXT H 17']);
        excelRow['wcTextI17'] = trim(el['WC TEXT I 17']);
        excelRow['wcTextJ17'] = trim(el['WC TEXT J 17']);
        excelRow['wcTextK17'] = trim(el['WC TEXT K 17']);
        excelRow['wcTextL17'] = trim(el['WC TEXT L 17']);
        excelRow['wcTextM17'] = trim(el['WC TEXT M 17']);
        excelRow['wcTextN17'] = trim(el['WC TEXT N 17']);
        excelRow['wcTextO17'] = trim(el['WC TEXT O 17']);
        excelRow['wcItemNo18'] = trim(el['WC ITEM NO 18']);
        excelRow['wcCd18'] = trim(el['WARRANTY/CLAUSE CODE 18']);
        excelRow['wcTextA18'] = trim(el['WC TEXT A 18']);
        excelRow['wcTextB18'] = trim(el['WC TEXT B 18']);
        excelRow['wcTextC18'] = trim(el['WC TEXT C 18']);
        excelRow['wcTextD18'] = trim(el['WC TEXT D 18']);
        excelRow['wcTextE18'] = trim(el['WC TEXT E 18']);
        excelRow['wcTextF18'] = trim(el['WC TEXT F 18']);
        excelRow['wcTextG18'] = trim(el['WC TEXT G 18']);
        excelRow['wcTextH18'] = trim(el['WC TEXT H 18']);
        excelRow['wcTextI18'] = trim(el['WC TEXT I 18']);
        excelRow['wcTextJ18'] = trim(el['WC TEXT J 18']);
        excelRow['wcTextK18'] = trim(el['WC TEXT K 18']);
        excelRow['wcTextL18'] = trim(el['WC TEXT L 18']);
        excelRow['wcTextM18'] = trim(el['WC TEXT M 18']);
        excelRow['wcTextN18'] = trim(el['WC TEXT N 18']);
        excelRow['wcTextO18'] = trim(el['WC TEXT O 18']);
        excelRow['wcItemNo19'] = trim(el['WC ITEM NO 19']);
        excelRow['wcCd19'] = trim(el['WARRANTY/CLAUSE CODE 19']);
        excelRow['wcTextA19'] = trim(el['WC TEXT A 19']);
        excelRow['wcTextB19'] = trim(el['WC TEXT B 19']);
        excelRow['wcTextC19'] = trim(el['WC TEXT C 19']);
        excelRow['wcTextD19'] = trim(el['WC TEXT D 19']);
        excelRow['wcTextE19'] = trim(el['WC TEXT E 19']);
        excelRow['wcTextF19'] = trim(el['WC TEXT F 19']);
        excelRow['wcTextG19'] = trim(el['WC TEXT G 19']);
        excelRow['wcTextH19'] = trim(el['WC TEXT H 19']);
        excelRow['wcTextI19'] = trim(el['WC TEXT I 19']);
        excelRow['wcTextJ19'] = trim(el['WC TEXT J 19']);
        excelRow['wcTextK19'] = trim(el['WC TEXT K 19']);
        excelRow['wcTextL19'] = trim(el['WC TEXT L 19']);
        excelRow['wcTextM19'] = trim(el['WC TEXT M 19']);
        excelRow['wcTextN19'] = trim(el['WC TEXT N 19']);
        excelRow['wcTextO19'] = trim(el['WC TEXT O 19']);
        excelRow['wcItemNo20'] = trim(el['WC ITEM NO 20']);
        excelRow['wcCd20'] = trim(el['WARRANTY/CLAUSE CODE 20']);
        excelRow['wcTextA20'] = trim(el['WC TEXT A 20']);
        excelRow['wcTextB20'] = trim(el['WC TEXT B 20']);
        excelRow['wcTextC20'] = trim(el['WC TEXT C 20']);
        excelRow['wcTextD20'] = trim(el['WC TEXT D 20']);
        excelRow['wcTextE20'] = trim(el['WC TEXT E 20']);
        excelRow['wcTextF20'] = trim(el['WC TEXT F 20']);
        excelRow['wcTextG20'] = trim(el['WC TEXT G 20']);
        excelRow['wcTextH20'] = trim(el['WC TEXT H 20']);
        excelRow['wcTextI20'] = trim(el['WC TEXT I 20']);
        excelRow['wcTextJ20'] = trim(el['WC TEXT J 20']);
        excelRow['wcTextK20'] = trim(el['WC TEXT K 20']);
        excelRow['wcTextL20'] = trim(el['WC TEXT L 20']);
        excelRow['wcTextM20'] = trim(el['WC TEXT M 20']);
        excelRow['wcTextN20'] = trim(el['WC TEXT N 20']);
        excelRow['wcTextO20'] = trim(el['WC TEXT O 20']);
        excelRow['wcItemNo21'] = trim(el['WC ITEM NO 21']);
        excelRow['wcCd21'] = trim(el['WARRANTY/CLAUSE CODE 21']);
        excelRow['wcTextA21'] = trim(el['WC TEXT A 21']);
        excelRow['wcTextB21'] = trim(el['WC TEXT B 21']);
        excelRow['wcTextC21'] = trim(el['WC TEXT C 21']);
        excelRow['wcTextD21'] = trim(el['WC TEXT D 21']);
        excelRow['wcTextE21'] = trim(el['WC TEXT E 21']);
        excelRow['wcTextF21'] = trim(el['WC TEXT F 21']);
        excelRow['wcTextG21'] = trim(el['WC TEXT G 21']);
        excelRow['wcTextH21'] = trim(el['WC TEXT H 21']);
        excelRow['wcTextI21'] = trim(el['WC TEXT I 21']);
        excelRow['wcTextJ21'] = trim(el['WC TEXT J 21']);
        excelRow['wcTextK21'] = trim(el['WC TEXT K 21']);
        excelRow['wcTextL21'] = trim(el['WC TEXT L 21']);
        excelRow['wcTextM21'] = trim(el['WC TEXT M 21']);
        excelRow['wcTextN21'] = trim(el['WC TEXT N 21']);
        excelRow['wcTextO21'] = trim(el['WC TEXT O 21']);
        excelRow['wcItemNo22'] = trim(el['WC ITEM NO 22']);
        excelRow['wcCd22'] = trim(el['WARRANTY/CLAUSE CODE 22']);
        excelRow['wcTextA22'] = trim(el['WC TEXT A 22']);
        excelRow['wcTextB22'] = trim(el['WC TEXT B 22']);
        excelRow['wcTextC22'] = trim(el['WC TEXT C 22']);
        excelRow['wcTextD22'] = trim(el['WC TEXT D 22']);
        excelRow['wcTextE22'] = trim(el['WC TEXT E 22']);
        excelRow['wcTextF22'] = trim(el['WC TEXT F 22']);
        excelRow['wcTextG22'] = trim(el['WC TEXT G 22']);
        excelRow['wcTextH22'] = trim(el['WC TEXT H 22']);
        excelRow['wcTextI22'] = trim(el['WC TEXT I 22']);
        excelRow['wcTextJ22'] = trim(el['WC TEXT J 22']);
        excelRow['wcTextK22'] = trim(el['WC TEXT K 22']);
        excelRow['wcTextL22'] = trim(el['WC TEXT L 22']);
        excelRow['wcTextM22'] = trim(el['WC TEXT M 22']);
        excelRow['wcTextN22'] = trim(el['WC TEXT N 22']);
        excelRow['wcTextO22'] = trim(el['WC TEXT O 22']);
        excelRow['wcItemNo23'] = trim(el['WC ITEM NO 23']);
        excelRow['wcCd23'] = trim(el['WARRANTY/CLAUSE CODE 23']);
        excelRow['wcTextA23'] = trim(el['WC TEXT A 23']);
        excelRow['wcTextB23'] = trim(el['WC TEXT B 23']);
        excelRow['wcTextC23'] = trim(el['WC TEXT C 23']);
        excelRow['wcTextD23'] = trim(el['WC TEXT D 23']);
        excelRow['wcTextE23'] = trim(el['WC TEXT E 23']);
        excelRow['wcTextF23'] = trim(el['WC TEXT F 23']);
        excelRow['wcTextG23'] = trim(el['WC TEXT G 23']);
        excelRow['wcTextH23'] = trim(el['WC TEXT H 23']);
        excelRow['wcTextI23'] = trim(el['WC TEXT I 23']);
        excelRow['wcTextJ23'] = trim(el['WC TEXT J 23']);
        excelRow['wcTextK23'] = trim(el['WC TEXT K 23']);
        excelRow['wcTextL23'] = trim(el['WC TEXT L 23']);
        excelRow['wcTextM23'] = trim(el['WC TEXT M 23']);
        excelRow['wcTextN23'] = trim(el['WC TEXT N 23']);
        excelRow['wcTextO23'] = trim(el['WC TEXT O 23']);
        excelRow['wcItemNo24'] = trim(el['WC ITEM NO 24']);
        excelRow['wcCd24'] = trim(el['WARRANTY/CLAUSE CODE 24']);
        excelRow['wcTextA24'] = trim(el['WC TEXT A 24']);
        excelRow['wcTextB24'] = trim(el['WC TEXT B 24']);
        excelRow['wcTextC24'] = trim(el['WC TEXT C 24']);
        excelRow['wcTextD24'] = trim(el['WC TEXT D 24']);
        excelRow['wcTextE24'] = trim(el['WC TEXT E 24']);
        excelRow['wcTextF24'] = trim(el['WC TEXT F 24']);
        excelRow['wcTextG24'] = trim(el['WC TEXT G 24']);
        excelRow['wcTextH24'] = trim(el['WC TEXT H 24']);
        excelRow['wcTextI24'] = trim(el['WC TEXT I 24']);
        excelRow['wcTextJ24'] = trim(el['WC TEXT J 24']);
        excelRow['wcTextK24'] = trim(el['WC TEXT K 24']);
        excelRow['wcTextL24'] = trim(el['WC TEXT L 24']);
        excelRow['wcTextM24'] = trim(el['WC TEXT M 24']);
        excelRow['wcTextN24'] = trim(el['WC TEXT N 24']);
        excelRow['wcTextO24'] = trim(el['WC TEXT O 24']);
        excelRow['wcItemNo25'] = trim(el['WC ITEM NO 25']);
        excelRow['wcCd25'] = trim(el['WARRANTY/CLAUSE CODE 25']);
        excelRow['wcTextA25'] = trim(el['WC TEXT A 25']);
        excelRow['wcTextB25'] = trim(el['WC TEXT B 25']);
        excelRow['wcTextC25'] = trim(el['WC TEXT C 25']);
        excelRow['wcTextD25'] = trim(el['WC TEXT D 25']);
        excelRow['wcTextE25'] = trim(el['WC TEXT E 25']);
        excelRow['wcTextF25'] = trim(el['WC TEXT F 25']);
        excelRow['wcTextG25'] = trim(el['WC TEXT G 25']);
        excelRow['wcTextH25'] = trim(el['WC TEXT H 25']);
        excelRow['wcTextI25'] = trim(el['WC TEXT I 25']);
        excelRow['wcTextJ25'] = trim(el['WC TEXT J 25']);
        excelRow['wcTextK25'] = trim(el['WC TEXT K 25']);
        excelRow['wcTextL25'] = trim(el['WC TEXT L 25']);
        excelRow['wcTextM25'] = trim(el['WC TEXT M 25']);
        excelRow['wcTextN25'] = trim(el['WC TEXT N 25']);
        excelRow['wcTextO25'] = trim(el['WC TEXT O 25']);
        excelRow['wcItemNo26'] = trim(el['WC ITEM NO 26']);
        excelRow['wcCd26'] = trim(el['WARRANTY/CLAUSE CODE 26']);
        excelRow['wcTextA26'] = trim(el['WC TEXT A 26']);
        excelRow['wcTextB26'] = trim(el['WC TEXT B 26']);
        excelRow['wcTextC26'] = trim(el['WC TEXT C 26']);
        excelRow['wcTextD26'] = trim(el['WC TEXT D 26']);
        excelRow['wcTextE26'] = trim(el['WC TEXT E 26']);
        excelRow['wcTextF26'] = trim(el['WC TEXT F 26']);
        excelRow['wcTextG26'] = trim(el['WC TEXT G 26']);
        excelRow['wcTextH26'] = trim(el['WC TEXT H 26']);
        excelRow['wcTextI26'] = trim(el['WC TEXT I 26']);
        excelRow['wcTextJ26'] = trim(el['WC TEXT J 26']);
        excelRow['wcTextK26'] = trim(el['WC TEXT K 26']);
        excelRow['wcTextL26'] = trim(el['WC TEXT L 26']);
        excelRow['wcTextM26'] = trim(el['WC TEXT M 26']);
        excelRow['wcTextN26'] = trim(el['WC TEXT N 26']);
        excelRow['wcTextO26'] = trim(el['WC TEXT O 26']);
        excelRow['wcItemNo27'] = trim(el['WC ITEM NO 27']);
        excelRow['wcCd27'] = trim(el['WARRANTY/CLAUSE CODE 27']);
        excelRow['wcTextA27'] = trim(el['WC TEXT A 27']);
        excelRow['wcTextB27'] = trim(el['WC TEXT B 27']);
        excelRow['wcTextC27'] = trim(el['WC TEXT C 27']);
        excelRow['wcTextD27'] = trim(el['WC TEXT D 27']);
        excelRow['wcTextE27'] = trim(el['WC TEXT E 27']);
        excelRow['wcTextF27'] = trim(el['WC TEXT F 27']);
        excelRow['wcTextG27'] = trim(el['WC TEXT G 27']);
        excelRow['wcTextH27'] = trim(el['WC TEXT H 27']);
        excelRow['wcTextI27'] = trim(el['WC TEXT I 27']);
        excelRow['wcTextJ27'] = trim(el['WC TEXT J 27']);
        excelRow['wcTextK27'] = trim(el['WC TEXT K 27']);
        excelRow['wcTextL27'] = trim(el['WC TEXT L 27']);
        excelRow['wcTextM27'] = trim(el['WC TEXT M 27']);
        excelRow['wcTextN27'] = trim(el['WC TEXT N 27']);
        excelRow['wcTextO27'] = trim(el['WC TEXT O 27']);
        excelRow['wcItemNo28'] = trim(el['WC ITEM NO 28']);
        excelRow['wcCd28'] = trim(el['WARRANTY/CLAUSE CODE 28']);
        excelRow['wcTextA28'] = trim(el['WC TEXT A 28']);
        excelRow['wcTextB28'] = trim(el['WC TEXT B 28']);
        excelRow['wcTextC28'] = trim(el['WC TEXT C 28']);
        excelRow['wcTextD28'] = trim(el['WC TEXT D 28']);
        excelRow['wcTextE28'] = trim(el['WC TEXT E 28']);
        excelRow['wcTextF28'] = trim(el['WC TEXT F 28']);
        excelRow['wcTextG28'] = trim(el['WC TEXT G 28']);
        excelRow['wcTextH28'] = trim(el['WC TEXT H 28']);
        excelRow['wcTextI28'] = trim(el['WC TEXT I 28']);
        excelRow['wcTextJ28'] = trim(el['WC TEXT J 28']);
        excelRow['wcTextK28'] = trim(el['WC TEXT K 28']);
        excelRow['wcTextL28'] = trim(el['WC TEXT L 28']);
        excelRow['wcTextM28'] = trim(el['WC TEXT M 28']);
        excelRow['wcTextN28'] = trim(el['WC TEXT N 28']);
        excelRow['wcTextO28'] = trim(el['WC TEXT O 28']);
        excelRow['wcItemNo29'] = trim(el['WC ITEM NO 29']);
        excelRow['wcCd29'] = trim(el['WARRANTY/CLAUSE CODE 29']);
        excelRow['wcTextA29'] = trim(el['WC TEXT A 29']);
        excelRow['wcTextB29'] = trim(el['WC TEXT B 29']);
        excelRow['wcTextC29'] = trim(el['WC TEXT C 29']);
        excelRow['wcTextD29'] = trim(el['WC TEXT D 29']);
        excelRow['wcTextE29'] = trim(el['WC TEXT E 29']);
        excelRow['wcTextF29'] = trim(el['WC TEXT F 29']);
        excelRow['wcTextG29'] = trim(el['WC TEXT G 29']);
        excelRow['wcTextH29'] = trim(el['WC TEXT H 29']);
        excelRow['wcTextI29'] = trim(el['WC TEXT I 29']);
        excelRow['wcTextJ29'] = trim(el['WC TEXT J 29']);
        excelRow['wcTextK29'] = trim(el['WC TEXT K 29']);
        excelRow['wcTextL29'] = trim(el['WC TEXT L 29']);
        excelRow['wcTextM29'] = trim(el['WC TEXT M 29']);
        excelRow['wcTextN29'] = trim(el['WC TEXT N 29']);
        excelRow['wcTextO29'] = trim(el['WC TEXT O 29']);
        excelRow['wcItemNo30'] = trim(el['WC ITEM NO 30']);
        excelRow['wcCd30'] = trim(el['WARRANTY/CLAUSE CODE 30']);
        excelRow['wcTextA30'] = trim(el['WC TEXT A 30']);
        excelRow['wcTextB30'] = trim(el['WC TEXT B 30']);
        excelRow['wcTextC30'] = trim(el['WC TEXT C 30']);
        excelRow['wcTextD30'] = trim(el['WC TEXT D 30']);
        excelRow['wcTextE30'] = trim(el['WC TEXT E 30']);
        excelRow['wcTextF30'] = trim(el['WC TEXT F 30']);
        excelRow['wcTextG30'] = trim(el['WC TEXT G 30']);
        excelRow['wcTextH30'] = trim(el['WC TEXT H 30']);
        excelRow['wcTextI30'] = trim(el['WC TEXT I 30']);
        excelRow['wcTextJ30'] = trim(el['WC TEXT J 30']);
        excelRow['wcTextK30'] = trim(el['WC TEXT K 30']);
        excelRow['wcTextL30'] = trim(el['WC TEXT L 30']);
        excelRow['wcTextM30'] = trim(el['WC TEXT M 30']);
        excelRow['wcTextN30'] = trim(el['WC TEXT N 30']);
        excelRow['wcTextO30'] = trim(el['WC TEXT O 30']);
        excelRow['riskName'] = trim(el['RISK NAME']);
        excelRow['cityMun'] = trim(el['CITY MUN']);
        excelRow['districtCd'] = trim(el['DISTRICT CD']);
        excelRow['blockDesc'] = trim(el['BLOCK DESC']);
        excelRow['tariffCode'] = trim(el['TARIFF CODE']);
        excelRow['construction'] = trim(el['CONSTRUCTION']);
        excelRow['constructionDesc1'] = trim(el['CONSTRUCTION DESC 1']);
        excelRow['constructionDesc2'] = trim(el['CONSTRUCTION DESC 2']);
        excelRow['constructionDesc3'] = trim(el['CONSTRUCTION DESC 3']);
        excelRow['constructionDesc4'] = trim(el['CONSTRUCTION DESC 4']);
        excelRow['constructionDesc5'] = trim(el['CONSTRUCTION DESC 5']);
        excelRow['occupancy'] = trim(el['OCCUPANCY']);
        excelRow['occupancyDesc1'] = trim(el['OCCUPANCY DESC 1']);
        excelRow['occupancyDesc2'] = trim(el['OCCUPANCY DESC 2']);
        excelRow['occupancyDesc3'] = trim(el['OCCUPANCY DESC 3']);
        excelRow['occupancyDesc4'] = trim(el['OCCUPANCY DESC 4']);
        excelRow['occupancyDesc5'] = trim(el['OCCUPANCY DESC 5']);
        excelRow['otherRiskDtl'] = trim(el['OTHER RISK DTL']);
        excelRow['bankCd'] = trim(el['BANK CD']);
        excelRow['interestedParty'] = trim(el['INTERESTED PARTY']);
        excelRow['itemRemarks'] = trim(el['ITEM REMARKS']);
        excelRow['fiItemNo1'] = trim(el['FI ITEM NO 1']);
        excelRow['fiItemTitle1'] = trim(el['FI ITEM TITLE 1']);
        excelRow['fiPremClass1'] = trim(el['FI PREM CLASS 1']);
        excelRow['fiItemNo2'] = trim(el['FI ITEM NO 2']);
        excelRow['fiItemTitle2'] = trim(el['FI ITEM TITLE 2']);
        excelRow['fiPremClass2'] = trim(el['FI PREM CLASS 2']);
        excelRow['fiItemNo3'] = trim(el['FI ITEM NO 3']);
        excelRow['fiItemTitle3'] = trim(el['FI ITEM TITLE 3']);
        excelRow['fiPremClass3'] = trim(el['FI PREM CLASS 3']);
        excelRow['fiItemNo4'] = trim(el['FI ITEM NO 4']);
        excelRow['fiItemTitle4'] = trim(el['FI ITEM TITLE 4']);
        excelRow['fiPremClass4'] = trim(el['FI PREM CLASS 4']);
        excelRow['fiItemNo5'] = trim(el['FI ITEM NO 5']);
        excelRow['fiItemTitle5'] = trim(el['FI ITEM TITLE 5']);
        excelRow['fiPremClass5'] = trim(el['FI PREM CLASS 5']);
        excelRow['dependentLname1'] = trim(el['DEPENDENT LNAME 1']);
        excelRow['dependentFname1'] = trim(el['DEPENDENT FNAME 1']);
        excelRow['dependentMname1'] = trim(el['DEPENDENT MNAME 1']);
        excelRow['dependentSuffix1'] = trim(el['DEPENDENT SUFFIX 1']);
        excelRow['depRelationship1'] = trim(el['DEP RELATIONSHIP 1']);
        excelRow['depNationality1'] = trim(el['DEP NATIONALITY 1']);
        excelRow['depBirthdate1'] = el['DEP BIRTHDATE 1'] !== null && el['DEP BIRTHDATE 1'] !== '' && el['DEP BIRTHDATE 1'] !== undefined ? dateToDateString(serialNumberToDate(el['DEP BIRTHDATE 1'])) : null;
        excelRow['depGender1'] = trim(el['DEP GENDER 1']);
        excelRow['depAge1'] = trim(el['DEP AGE 1']);
        excelRow['dependentLname2'] = trim(el['DEPENDENT LNAME 2']);
        excelRow['dependentFname2'] = trim(el['DEPENDENT FNAME 2']);
        excelRow['dependentMname2'] = trim(el['DEPENDENT MNAME 2']);
        excelRow['dependentSuffix2'] = trim(el['DEPENDENT SUFFIX 2']);
        excelRow['depRelationship2'] = trim(el['DEP RELATIONSHIP 2']);
        excelRow['depNationality2'] = trim(el['DEP NATIONALITY 2']);
        excelRow['depBirthdate2'] = el['DEP BIRTHDATE 2'] !== null && el['DEP BIRTHDATE 2'] !== '' && el['DEP BIRTHDATE 2'] !== undefined ? dateToDateString(serialNumberToDate(el['DEP BIRTHDATE 2'])) : null;
        excelRow['depGender2'] = trim(el['DEP GENDER 2']);
        excelRow['depAge2'] = trim(el['DEP AGE 2']);
        excelRow['dependentLname3'] = trim(el['DEPENDENT LNAME 3']);
        excelRow['dependentFname3'] = trim(el['DEPENDENT FNAME 3']);
        excelRow['dependentMname3'] = trim(el['DEPENDENT MNAME 3']);
        excelRow['dependentSuffix3'] = trim(el['DEPENDENT SUFFIX 3']);
        excelRow['depRelationship3'] = trim(el['DEP RELATIONSHIP 3']);
        excelRow['depNationality3'] = trim(el['DEP NATIONALITY 3']);
        excelRow['depBirthdate3'] = el['DEP BIRTHDATE 3'] !== null && el['DEP BIRTHDATE 3'] !== '' && el['DEP BIRTHDATE 3'] !== undefined ? dateToDateString(serialNumberToDate(el['DEP BIRTHDATE 3'])) : null;
        excelRow['depGender3'] = trim(el['DEP GENDER 3']);
        excelRow['depAge3'] = trim(el['DEP AGE 3']);
        excelRow['dependentLname4'] = trim(el['DEPENDENT LNAME 4']);
        excelRow['dependentFname4'] = trim(el['DEPENDENT FNAME 4']);
        excelRow['dependentMname4'] = trim(el['DEPENDENT MNAME 4']);
        excelRow['dependentSuffix4'] = trim(el['DEPENDENT SUFFIX 4']);
        excelRow['depRelationship4'] = trim(el['DEP RELATIONSHIP 4']);
        excelRow['depNationality4'] = trim(el['DEP NATIONALITY 4']);
        excelRow['depBirthdate4'] = el['DEP BIRTHDATE 4'] !== null && el['DEP BIRTHDATE 4'] !== '' && el['DEP BIRTHDATE 4'] !== undefined ? dateToDateString(serialNumberToDate(el['DEP BIRTHDATE 4'])) : null;
        excelRow['depGender4'] = trim(el['DEP GENDER 4']);
        excelRow['depAge4'] = trim(el['DEP AGE 4']);
        excelRow['dependentLname5'] = trim(el['DEPENDENT LNAME 5']);
        excelRow['dependentFname5'] = trim(el['DEPENDENT FNAME 5']);
        excelRow['dependentMname5'] = trim(el['DEPENDENT MNAME 5']);
        excelRow['dependentSuffix5'] = trim(el['DEPENDENT SUFFIX 5']);
        excelRow['depRelationship5'] = trim(el['DEP RELATIONSHIP 5']);
        excelRow['depNationality5'] = trim(el['DEP NATIONALITY 5']);
        excelRow['depBirthdate5'] = el['DEP BIRTHDATE 5'] !== null && el['DEP BIRTHDATE 5'] !== '' && el['DEP BIRTHDATE 5'] !== undefined ? dateToDateString(serialNumberToDate(el['DEP BIRTHDATE 5'])) : null;
        excelRow['depGender5'] = trim(el['DEP GENDER 5']);
        excelRow['depAge5'] = trim(el['DEP AGE 5']);
        excelRow['beneficiaryLname1'] = trim(el['BENEFICIARY LNAME 1']);
        excelRow['beneficiaryFname1'] = trim(el['BENEFICIARY FNAME 1']);
        excelRow['beneficiaryMname1'] = trim(el['BENEFICIARY MNAME 1']);
        excelRow['beneficiarySuffix1'] = trim(el['BENEFICIARY SUFFIX 1']);
        excelRow['benRelationship1'] = trim(el['BEN RELATIONSHIP 1']);
        excelRow['benNationality1'] = trim(el['BEN NATIONALITY 1']);
        excelRow['benBirthdate1'] = el['BEN BIRTHDATE 1'] !== null && el['BEN BIRTHDATE 1'] !== '' && el['BEN BIRTHDATE 1'] !== undefined ? dateToDateString(serialNumberToDate(el['BEN BIRTHDATE 1'])) : null;
        excelRow['benGender1'] = trim(el['BEN GENDER 1']);
        excelRow['benAge1'] = trim(el['BEN AGE 1']);
        excelRow['beneficiaryLname2'] = trim(el['BENEFICIARY LNAME 2']);
        excelRow['beneficiaryFname2'] = trim(el['BENEFICIARY FNAME 2']);
        excelRow['beneficiaryMname2'] = trim(el['BENEFICIARY MNAME 2']);
        excelRow['beneficiarySuffix2'] = trim(el['BENEFICIARY SUFFIX 2']);
        excelRow['benRelationship2'] = trim(el['BEN RELATIONSHIP 2']);
        excelRow['benNationality2'] = trim(el['BEN NATIONALITY 2']);
        excelRow['benBirthdate2'] = el['BEN BIRTHDATE 2'] !== null && el['BEN BIRTHDATE 2'] !== '' && el['BEN BIRTHDATE 2'] !== undefined ? dateToDateString(serialNumberToDate(el['BEN BIRTHDATE 2'])) : null;
        excelRow['benGender2'] = trim(el['BEN GENDER 2']);
        excelRow['benAge2'] = trim(el['BEN AGE 2']);
        excelRow['beneficiaryLname3'] = trim(el['BENEFICIARY LNAME 3']);
        excelRow['beneficiaryFname3'] = trim(el['BENEFICIARY FNAME 3']);
        excelRow['beneficiaryMname3'] = trim(el['BENEFICIARY MNAME 3']);
        excelRow['beneficiarySuffix3'] = trim(el['BENEFICIARY SUFFIX 3']);
        excelRow['benRelationship3'] = trim(el['BEN RELATIONSHIP 3']);
        excelRow['benNationality3'] = trim(el['BEN NATIONALITY 3']);
        excelRow['benBirthdate3'] = el['BEN BIRTHDATE 3'] !== null && el['BEN BIRTHDATE 3'] !== '' && el['BEN BIRTHDATE 3'] !== undefined ? dateToDateString(serialNumberToDate(el['BEN BIRTHDATE 3'])) : null;
        excelRow['benGender3'] = trim(el['BEN GENDER 3']);
        excelRow['benAge3'] = trim(el['BEN AGE 3']);
        excelRow['beneficiaryLname4'] = trim(el['BENEFICIARY LNAME 4']);
        excelRow['beneficiaryFname4'] = trim(el['BENEFICIARY FNAME 4']);
        excelRow['beneficiaryMname4'] = trim(el['BENEFICIARY MNAME 4']);
        excelRow['beneficiarySuffix4'] = trim(el['BENEFICIARY SUFFIX 4']);
        excelRow['benRelationship4'] = trim(el['BEN RELATIONSHIP 4']);
        excelRow['benNationality4'] = trim(el['BEN NATIONALITY 4']);
        excelRow['benBirthdate4'] = el['BEN BIRTHDATE 4'] !== null && el['BEN BIRTHDATE 4'] !== '' && el['BEN BIRTHDATE 4'] !== undefined ? dateToDateString(serialNumberToDate(el['BEN BIRTHDATE 4'])) : null;
        excelRow['benGender4'] = trim(el['BEN GENDER 4']);
        excelRow['benAge4'] = trim(el['BEN AGE 4']);
        excelRow['beneficiaryLname5'] = trim(el['BENEFICIARY LNAME 5']);
        excelRow['beneficiaryFname5'] = trim(el['BENEFICIARY FNAME 5']);
        excelRow['beneficiaryMname5'] = trim(el['BENEFICIARY MNAME 5']);
        excelRow['beneficiarySuffix5'] = trim(el['BENEFICIARY SUFFIX 5']);
        excelRow['benRelationship5'] = trim(el['BEN RELATIONSHIP 5']);
        excelRow['benNationality5'] = trim(el['BEN NATIONALITY 5']);
        excelRow['benBirthdate5'] = el['BEN BIRTHDATE 5'] !== null && el['BEN BIRTHDATE 5'] !== '' && el['BEN BIRTHDATE 5'] !== undefined ? dateToDateString(serialNumberToDate(el['BEN BIRTHDATE 5'])) : null;
        excelRow['benGender5'] = trim(el['BEN GENDER 5']);
        excelRow['benAge5'] = trim(el['BEN AGE 5']);
        excelRow['planCd'] = trim(el['PLAN CODE']);
        excelRow['currencyCd'] = trim(el['CURRENCY']);
        excelRow['currencyRt'] = trim(el['CURRENCY RATE']);
        excelRow['mvFileNo'] = trim(el['MV FILE NO']);
        excelRow['perilToSum'] = trim(el['PRC_CONCAT']);
        excelRow['itemToSum'] = trim(el['DSEQNO']);

        this.excelRows.push(excelRow);

        errorMessage = "";
      }

      // clearInterval(this.updateSessionLastActionTimeInterval);

      // sub functions
      function serialNumberToDate(serial: any) {
        if(typeof(serial) == "string") {
          // dd/mm/yyyy
          let d = serial.split('/');

          return new Date(d[1] + '/' + d[0] + '/' + d[2]);
        }

        // Converts Serial Number from excel to Date
        var utcDays  = Math.floor(serial - 25569);
        var utcValue = utcDays * 86400;
        var dateInfo = new Date(utcValue * 1000);

        var fractionalDay = serial - Math.floor(serial) + 0.0000001;

        var totalSeconds = Math.floor(86400 * fractionalDay);

        var seconds = totalSeconds % 60;

        totalSeconds -= seconds;

        var hours = Math.floor(totalSeconds / (60 * 60));
        var minutes = Math.floor(totalSeconds / 60) % 60;

        return new Date(dateInfo.getFullYear(), dateInfo.getMonth(), dateInfo.getDate(), hours, minutes, seconds);
      }

      function dateToDateString(date: any) {
        if(date.toString() == "Invalid Date") {
          return date.toString();
        }

        // Returns date string with MM/DD/YYYY format
        let newDate = ((date.getMonth() + 1).toString().padStart(2, '0') + '/' + date.getDate().toString().padStart(2, '0') + '/' + date.getFullYear());
        return newDate;
      }

      function isValidStringDate(dateString: string) {
        // Accepted format is MM/DD/YYYY

        // First check for the pattern
        if(!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
          return false;

        // Parse the date parts to integers
        var parts = dateString.split("/");
        var day = parseInt(parts[1], 10);
        var month = parseInt(parts[0], 10);
        var year = parseInt(parts[2], 10);

        // Check the ranges of month and year
        if(year < 1000 || year > 3000 || month == 0 || month > 12)
          return false;

        var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

        // Adjust for leap years
        if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
            monthLength[1] = 29;

        // Check the range of the day
        return day > 0 && day <= monthLength[month - 1];
      }

      function trim(val: string) {
        let x = val;
        let res: any = null;

        if(x !== undefined && x !== null) {
          if(String(x).trim().length == 0) {
            res = null;
          } else {
            res = String(x).trim();
          }
        } else {
          res = val;
        }

        return res;
      }
      // end sub functions

    }
  }

  excelData: any[] = [];
  excelRow: any = null;

  newProcessFile(data: File) {
    this.excelRows = [];
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(data);
    reader.onload = (e: any) => {
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, {type: 'binary', cellDates: true});

      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      const excelData: any = XLSX.utils.sheet_to_json(ws);

      let headerObj: any = XLSX.utils.sheet_to_json(ws, {defval:null})[0];
      var headers = Object.keys(headerObj);

      this.dupHeaderList = [];

      if(headers.length > 0) {
        for (let x = 0; x < headers.length; x++) {
          const el = headers[x].substring(0, headers[x].length-2);

          for (let y = 0; y < headers.length; y++) {
            const el2 = headers[y];

            if(el == el2) {
              this.dupHeaderList.push(el2);
            }
          }
        }
      }

      this.dupHeaderList = this.dupHeaderList.filter((x, i, a) => a.indexOf(x) == i);

      if(this.dupHeaderList.length > 0) {
        return;
      }

      this.excelData = excelData;
    }
  }

  goToNextRow() {
    let row = this.excelData[this.uploadCtr];

    Object.keys(row).forEach(x => {
      if (!row.hasOwnProperty(String(x).toUpperCase())
       || String(x).trim().length !== String(x).length) {
        row[String(x).toUpperCase().trim()] = row[x];
        delete row[x];
      }
    });

    let errorMessage = "No file selected. Please select a file first.";

    let excelRow: any = {};
    let el = row;

    excelRow['excelRowNum'] = el['__rowNum__'] + 1;
    excelRow['lineCd'] = trim(el['LINE CODE']);
    excelRow['policyNo'] = trim(el['POLICY NO']);
    excelRow['totalLossTag'] = trim(el['TOTAL LOSS TAG']);
    excelRow['uploadUser'] = this.retrievalParams.userId;
    excelRow['displayName'] = trim(el['ASSURED NAME']);
    excelRow['assdLastName'] = trim(el['ASSD LAST NAME']);
    excelRow['assdFirstName'] = trim(el['ASSD FIRST NAME']);
    excelRow['assdMiddleName'] = trim(el['ASSD MIDDLE NAME']);
    excelRow['oldEffDate'] = el['OLD EFFECTIVE DATE'] !== null && el['OLD EFFECTIVE DATE'] !== '' && el['OLD EFFECTIVE DATE'] !== undefined ? dateToDateString(serialNumberToDate(el['OLD EFFECTIVE DATE'])) : null;
    excelRow['oldExpiryDate'] = el['OLD EXPIRY DATE'] !== null && el['OLD EXPIRY DATE'] !== '' && el['OLD EXPIRY DATE'] !== undefined ? dateToDateString(serialNumberToDate(el['OLD EXPIRY DATE'])) : null;
    excelRow['effDate'] = el['NEW EFFECTIVE DATE'] !== null && el['NEW EFFECTIVE DATE'] !== '' && el['NEW EFFECTIVE DATE'] !== undefined ? dateToDateString(serialNumberToDate(el['NEW EFFECTIVE DATE'])) : null;
    excelRow['expiryDate'] = el['NEW EXPIRY DATE'] !== null && el['NEW EXPIRY DATE'] !== '' && el['NEW EXPIRY DATE'] !== undefined ? dateToDateString(serialNumberToDate(el['NEW EXPIRY DATE'])) : null;
    excelRow['totalTsiAmt'] = trim(el['TOTAL TSI']);
    excelRow['totalGrossPrem'] = trim(el['TOTAL GROSS PREMIUM']);
    excelRow['totalCommAmt'] = trim(el['TOTAL COMMISSION']);
    excelRow['balanceAmt'] = trim(el['BALANCE']);
    excelRow['issCd'] = trim(el['ISSUE SOURCE']);
    excelRow['deptCd'] = trim(el['DEPT CODE']);
    excelRow['contractTypeCd'] = trim(el['CONTRACT TYPE']);
    excelRow['coverageTypeCd'] = trim(el['COVERAGE TYPE']);
    excelRow['agentCd'] = trim(el['AGENT CODE']);
    excelRow['srcCd'] = trim(el['SOURCE CODE']);
    excelRow['srcExtCd'] = trim(el['SOURCE EXTENSION CODE']);
    excelRow['bmaCd'] = trim(el['BMA CODE']);
    excelRow['referrorCd'] = trim(el['REFERROR CODE']);
    excelRow['clientCd'] = trim(el['CLIENT NUMBER']);
    excelRow['clientGrp'] = trim(el['CLIENT GROUP']);
    excelRow['agentGrp'] = trim(el['AGENT GROUP']);
    excelRow['replacesCd'] = trim(el['REPLACES CODE']);
    excelRow['promoCode'] = trim(el['PROMO CODE']);
    excelRow['bmPolCd'] = trim(el['BM_POL_CD']);
    excelRow['bmPolSeqNo'] = trim(el['BM_POL_SEQ_NO']);
    excelRow['projectCd'] = trim(el['PROJECT CODE']);
    excelRow['prjectName'] = trim(el['PROJECT NAME']);
    excelRow['genpage01'] = trim(el['GENPAGE 1']);
    excelRow['genpage02'] = trim(el['GENPAGE 2']);
    excelRow['genpage03'] = trim(el['GENPAGE 3']);
    excelRow['genpage04'] = trim(el['GENPAGE 4']);
    excelRow['genpage05'] = trim(el['GENPAGE 5']);
    excelRow['genpage06'] = trim(el['GENPAGE 6']);
    excelRow['genpage07'] = trim(el['GENPAGE 7']);
    excelRow['genpage08'] = trim(el['GENPAGE 8']);
    excelRow['genpage09'] = trim(el['GENPAGE 9']);
    excelRow['genpage10'] = trim(el['GENPAGE 10']);
    excelRow['genpage11'] = trim(el['GENPAGE 11']);
    excelRow['genpage12'] = trim(el['GENPAGE 12']);
    excelRow['genpage13'] = trim(el['GENPAGE 13']);
    excelRow['genpage14'] = trim(el['GENPAGE 14']);
    excelRow['genpage15'] = trim(el['GENPAGE 15']);
    excelRow['genpage16'] = trim(el['GENPAGE 16']);
    excelRow['genpage17'] = trim(el['GENPAGE 17']);
    excelRow['genpage18'] = trim(el['GENPAGE 18']);
    excelRow['genpage19'] = trim(el['GENPAGE 19']);
    excelRow['genpage20'] = trim(el['GENPAGE 20']);
    excelRow['genpage21'] = trim(el['GENPAGE 21']);
    excelRow['genpage22'] = trim(el['GENPAGE 22']);
    excelRow['genpage23'] = trim(el['GENPAGE 23']);
    excelRow['genpage24'] = trim(el['GENPAGE 24']);
    excelRow['genpage25'] = trim(el['GENPAGE 25']);
    excelRow['genpage26'] = trim(el['GENPAGE 26']);
    excelRow['genpage27'] = trim(el['GENPAGE 27']);
    excelRow['genpage28'] = trim(el['GENPAGE 28']);
    excelRow['genpage29'] = trim(el['GENPAGE 29']);
    excelRow['genpage30'] = trim(el['GENPAGE 30']);
    excelRow['paytRefNo'] = trim(el['PAYT REF NO']);
    excelRow['paytTranNo'] = trim(el['TRANSACTION NO']);
    excelRow['paytMode'] = trim(el['MODE OF PAMENT']);
    excelRow['paytDate'] = el['PAYMENT DATE'] !== null && el['PAYMENT DATE'] !== '' && el['PAYMENT DATE'] !== undefined ? dateToDateString(serialNumberToDate(el['PAYMENT DATE'])) : null;
    excelRow['clientType'] = trim(el['CLIENT TYPE']);
    excelRow['address01'] = trim(el['ADDRESS LINE 1']);
    excelRow['address02'] = trim(el['ADDRESS LINE 2']);
    excelRow['address03'] = trim(el['ADDRESS LINE 3']);
    excelRow['address04'] = trim(el['ADDRESS LINE 4']);
    excelRow['postalCd'] = trim(el['POSTAL CODE']);
    excelRow['mobileNo'] = trim(el['MOBILE NO']);
    excelRow['telNo'] = trim(el['TELEPHONE NO']);
    excelRow['email'] = trim(el['EMAIL']);
    excelRow['birthDate'] = el['DATE OF BIRTH'] !== null && el['DATE OF BIRTH'] !== '' && el['DATE OF BIRTH'] !== undefined ? dateToDateString(serialNumberToDate(el['DATE OF BIRTH'])) : null;
    excelRow['provCity'] = trim(el['PROVINCE/CITY']);
    excelRow['birthCountry'] = trim(el['COUNTRY OF BIRTH']);
    excelRow['gender'] = trim(el['GENDER']);
    excelRow['maritalStatus'] = trim(el['MARITAL STATUS']);
    excelRow['sTaxIndicator'] = trim(el['S/TAX INDICATOR']);
    excelRow['nationality'] = trim(el['NATIONALITY']);
    excelRow['busRes'] = trim(el['BUS/RES']);
    excelRow['occupation'] = trim(el['OCCUPATION']);
    excelRow['category'] = trim(el['CATEGORY']);
    excelRow['riskProfile'] = trim(el['RISK PROFILE']);
    excelRow['tin'] = trim(el['TIN']);
    excelRow['idType01'] = trim(el['ID TYPE 1']);
    excelRow['idNo01'] = trim(el['ID NUMBER 1']);
    excelRow['idExpiryDate01'] = el['ID EXPIRY DATE 1'] !== null && el['ID EXPIRY DATE 1'] !== '' && el['ID EXPIRY DATE 1'] !== undefined ? dateToDateString(serialNumberToDate(el['ID EXPIRY DATE 1'])) : null;
    excelRow['idType02'] = trim(el['ID TYPE 2']);
    excelRow['idNo02'] = trim(el['ID NUMBER 2']);
    excelRow['idExpiryDate02'] = el['ID EXPIRY DATE 2'] !== null && el['ID EXPIRY DATE 2'] !== '' && el['ID EXPIRY DATE 2'] !== undefined ? dateToDateString(serialNumberToDate(el['ID EXPIRY DATE 2'])) : null;
    excelRow['marketSector'] = trim(el['MARKET SECTOR']);
    excelRow['riskGenpage01'] = trim(el['RISK GENPAGE 1']);
    excelRow['riskGenpage02'] = trim(el['RISK GENPAGE 2']);
    excelRow['riskGenpage03'] = trim(el['RISK GENPAGE 3']);
    excelRow['riskGenpage04'] = trim(el['RISK GENPAGE 4']);
    excelRow['riskGenpage05'] = trim(el['RISK GENPAGE 5']);
    excelRow['riskGenpage06'] = trim(el['RISK GENPAGE 6']);
    excelRow['riskGenpage07'] = trim(el['RISK GENPAGE 7']);
    excelRow['riskGenpage08'] = trim(el['RISK GENPAGE 8']);
    excelRow['riskGenpage09'] = trim(el['RISK GENPAGE 9']);
    excelRow['riskGenpage10'] = trim(el['RISK GENPAGE 10']);
    excelRow['riskGenpage11'] = trim(el['RISK GENPAGE 11']);
    excelRow['riskGenpage12'] = trim(el['RISK GENPAGE 12']);
    excelRow['riskGenpage13'] = trim(el['RISK GENPAGE 13']);
    excelRow['riskGenpage14'] = trim(el['RISK GENPAGE 14']);
    excelRow['riskGenpage15'] = trim(el['RISK GENPAGE 15']);
    excelRow['riskGenpage16'] = trim(el['RISK GENPAGE 16']);
    excelRow['riskGenpage17'] = trim(el['RISK GENPAGE 17']);
    excelRow['riskGenpage18'] = trim(el['RISK GENPAGE 18']);
    excelRow['riskGenpage19'] = trim(el['RISK GENPAGE 19']);
    excelRow['riskGenpage20'] = trim(el['RISK GENPAGE 20']);
    excelRow['riskGenpage21'] = trim(el['RISK GENPAGE 21']);
    excelRow['riskGenpage22'] = trim(el['RISK GENPAGE 22']);
    excelRow['riskGenpage23'] = trim(el['RISK GENPAGE 23']);
    excelRow['riskGenpage24'] = trim(el['RISK GENPAGE 24']);
    excelRow['riskGenpage25'] = trim(el['RISK GENPAGE 25']);
    excelRow['riskGenpage26'] = trim(el['RISK GENPAGE 26']);
    excelRow['riskGenpage27'] = trim(el['RISK GENPAGE 27']);
    excelRow['riskGenpage28'] = trim(el['RISK GENPAGE 28']);
    excelRow['riskGenpage29'] = trim(el['RISK GENPAGE 29']);
    excelRow['riskGenpage30'] = trim(el['RISK GENPAGE 30']);
    excelRow['selField01'] = trim(el['SEL FIELD 1']);
    excelRow['selField02'] = trim(el['SEL FIELD 2']);
    excelRow['selField03'] = trim(el['SEL FIELD 3']);
    excelRow['riMethod'] = trim(el['RI METHOD']);
    excelRow['rateFlag'] = trim(el['RATE FLAG']);
    excelRow['deductibleAmt'] = trim(el['DEDUCTIBLE']);
    excelRow['itemNo'] = trim(el['ITEM NO']);
    excelRow['makeModelCd'] = trim(el['MAKE & MODEL CODE']);
    excelRow['carCompany'] = trim(el['CAR COMPANY']);
    excelRow['makeDesc'] = trim(el['MAKE DESC']);
    excelRow['subModel'] = trim(el['SUB MODEL']);
    excelRow['veTypeTag'] = trim(el['VEHICLE TYPE']);
    excelRow['yearModel'] = trim(el['YEAR MODEL']);
    excelRow['fmv'] = trim(el['FMV']);
    excelRow['cover'] = trim(el['COVER']);
    excelRow['vehicleUsage'] = trim(el['VEHICLE USAGE']);
    excelRow['engineNo'] = trim(el['ENGINE NO']);
    excelRow['chassisNo'] = trim(el['CHASSIS NO']);
    excelRow['plateNo'] = trim(el['VEHICLE PLATE NO']);
    excelRow['makeTypeCd'] = trim(el['MAKE TYPE CODE']);
    excelRow['color01'] = trim(el['COLOR']);
    excelRow['color02'] = trim(el['COLOR 2']);
    excelRow['noOfSeats'] = trim(el['NO OF SEATS']);
    excelRow['seatsCovered'] = trim(el['SEATS COVERED']);
    excelRow['ccWt'] = trim(el['CC/WT']);
    excelRow['repairLimit'] = trim(el['REPAIR LIMIT']);
    excelRow['towingLimit'] = trim(el['TOWING LIMIT']);
    excelRow['fuelType'] = trim(el['FUEL TYPE']);
    excelRow['itemNo01'] = trim(el['ITEM NO 1']);
    excelRow['perilCode01'] = trim(el['PERIL ITEM 1']);
    excelRow['amountCovered01'] = trim(el['AMOUNT COVERED 1']);
    excelRow['calcMethod01'] = trim(el['CALC METHOD 1']);
    excelRow['perilRt01'] = trim(el['PERIL RATE 1']);
    excelRow['grossPrem01'] = trim(el['GROSS PREMIUM 1']);
    excelRow['docStamp01'] = trim(el['DOCUMENT STAMP 1']);
    excelRow['lgt01'] = trim(el['LOCAL GOVT TAX 1']);
    excelRow['vat01'] = trim(el['VAT 1']);
    excelRow['fst01'] = trim(el['FST 1']);
    excelRow['otherTax01'] = trim(el['OTHER TAX 1']);
    excelRow['premTax01'] = trim(el['PREMIUM TAX 1']);
    excelRow['netPrem01'] = trim(el['NET PREMIUM 1']);
    excelRow['commRt01'] = trim(el['COMMISSION RATE 1']);
    excelRow['commAmt01'] = trim(el['COMMISSION AMOUNT 1']);
    excelRow['itemNo02'] = trim(el['ITEM NO 2']);
    excelRow['perilCode02'] = trim(el['PERIL ITEM 2']);
    excelRow['amountCovered02'] = trim(el['AMOUNT COVERED 2']);
    excelRow['calcMethod02'] = trim(el['CALC METHOD 2']);
    excelRow['perilRt02'] = trim(el['PERIL RATE 2']);
    excelRow['grossPrem02'] = trim(el['GROSS PREMIUM 2']);
    excelRow['docStamp02'] = trim(el['DOCUMENT STAMP 2']);
    excelRow['lgt02'] = trim(el['LOCAL GOVT TAX 2']);
    excelRow['vat02'] = trim(el['VAT 2']);
    excelRow['fst02'] = trim(el['FST 2']);
    excelRow['otherTax02'] = trim(el['OTHER TAX 2']);
    excelRow['premTax02'] = trim(el['PREMIUM TAX 2']);
    excelRow['netPrem02'] = trim(el['NET PREMIUM 2']);
    excelRow['commRt02'] = trim(el['COMMISSION RATE 2']);
    excelRow['commAmt02'] = trim(el['COMMISSION AMOUNT 2']);
    excelRow['itemNo03'] = trim(el['ITEM NO 3']);
    excelRow['perilCode03'] = trim(el['PERIL ITEM 3']);
    excelRow['amountCovered03'] = trim(el['AMOUNT COVERED 3']);
    excelRow['calcMethod03'] = trim(el['CALC METHOD 3']);
    excelRow['perilRt03'] = trim(el['PERIL RATE 3']);
    excelRow['grossPrem03'] = trim(el['GROSS PREMIUM 3']);
    excelRow['docStamp03'] = trim(el['DOCUMENT STAMP 3']);
    excelRow['lgt03'] = trim(el['LOCAL GOVT TAX 3']);
    excelRow['vat03'] = trim(el['VAT 3']);
    excelRow['fst03'] = trim(el['FST 3']);
    excelRow['otherTax03'] = trim(el['OTHER TAX 3']);
    excelRow['premTax03'] = trim(el['PREMIUM TAX 3']);
    excelRow['netPrem03'] = trim(el['NET PREMIUM 3']);
    excelRow['commRt03'] = trim(el['COMMISSION RATE 3']);
    excelRow['commAmt03'] = trim(el['COMMISSION AMOUNT 3']);
    excelRow['itemNo04'] = trim(el['ITEM NO 4']);
    excelRow['perilCode04'] = trim(el['PERIL ITEM 4']);
    excelRow['amountCovered04'] = trim(el['AMOUNT COVERED 4']);
    excelRow['calcMethod04'] = trim(el['CALC METHOD 4']);
    excelRow['perilRt04'] = trim(el['PERIL RATE 4']);
    excelRow['grossPrem04'] = trim(el['GROSS PREMIUM 4']);
    excelRow['docStamp04'] = trim(el['DOCUMENT STAMP 4']);
    excelRow['lgt04'] = trim(el['LOCAL GOVT TAX 4']);
    excelRow['vat04'] = trim(el['VAT 4']);
    excelRow['fst04'] = trim(el['FST 4']);
    excelRow['otherTax04'] = trim(el['OTHER TAX 4']);
    excelRow['premTax04'] = trim(el['PREMIUM TAX 4']);
    excelRow['netPrem04'] = trim(el['NET PREMIUM 4']);
    excelRow['commRt04'] = trim(el['COMMISSION RATE 4']);
    excelRow['commAmt04'] = trim(el['COMMISSION AMOUNT 4']);
    excelRow['itemNo05'] = trim(el['ITEM NO 5']);
    excelRow['perilCode05'] = trim(el['PERIL ITEM 5']);
    excelRow['amountCovered05'] = trim(el['AMOUNT COVERED 5']);
    excelRow['calcMethod05'] = trim(el['CALC METHOD 5']);
    excelRow['perilRt05'] = trim(el['PERIL RATE 5']);
    excelRow['grossPrem05'] = trim(el['GROSS PREMIUM 5']);
    excelRow['docStamp05'] = trim(el['DOCUMENT STAMP 5']);
    excelRow['lgt05'] = trim(el['LOCAL GOVT TAX 5']);
    excelRow['vat05'] = trim(el['VAT 5']);
    excelRow['fst05'] = trim(el['FST 5']);
    excelRow['otherTax05'] = trim(el['OTHER TAX 5']);
    excelRow['premTax05'] = trim(el['PREMIUM TAX 5']);
    excelRow['netPrem05'] = trim(el['NET PREMIUM 5']);
    excelRow['commRt05'] = trim(el['COMMISSION RATE 5']);
    excelRow['commAmt05'] = trim(el['COMMISSION AMOUNT 5']);
    excelRow['itemNo06'] = trim(el['ITEM NO 6']);
    excelRow['perilCode06'] = trim(el['PERIL ITEM 6']);
    excelRow['amountCovered06'] = trim(el['AMOUNT COVERED 6']);
    excelRow['calcMethod06'] = trim(el['CALC METHOD 6']);
    excelRow['perilRt06'] = trim(el['PERIL RATE 6']);
    excelRow['grossPrem06'] = trim(el['GROSS PREMIUM 6']);
    excelRow['docStamp06'] = trim(el['DOCUMENT STAMP 6']);
    excelRow['lgt06'] = trim(el['LOCAL GOVT TAX 6']);
    excelRow['vat06'] = trim(el['VAT 6']);
    excelRow['fst06'] = trim(el['FST 6']);
    excelRow['otherTax06'] = trim(el['OTHER TAX 6']);
    excelRow['premTax06'] = trim(el['PREMIUM TAX 6']);
    excelRow['netPrem06'] = trim(el['NET PREMIUM 6']);
    excelRow['commRt06'] = trim(el['COMMISSION RATE 6']);
    excelRow['commAmt06'] = trim(el['COMMISSION AMOUNT 6']);
    excelRow['itemNo07'] = trim(el['ITEM NO 7']);
    excelRow['perilCode07'] = trim(el['PERIL ITEM 7']);
    excelRow['amountCovered07'] = trim(el['AMOUNT COVERED 7']);
    excelRow['calcMethod07'] = trim(el['CALC METHOD 7']);
    excelRow['perilRt07'] = trim(el['PERIL RATE 7']);
    excelRow['grossPrem07'] = trim(el['GROSS PREMIUM 7']);
    excelRow['docStamp07'] = trim(el['DOCUMENT STAMP 7']);
    excelRow['lgt07'] = trim(el['LOCAL GOVT TAX 7']);
    excelRow['vat07'] = trim(el['VAT 7']);
    excelRow['fst07'] = trim(el['FST 7']);
    excelRow['otherTax07'] = trim(el['OTHER TAX 7']);
    excelRow['premTax07'] = trim(el['PREMIUM TAX 7']);
    excelRow['netPrem07'] = trim(el['NET PREMIUM 7']);
    excelRow['commRt07'] = trim(el['COMMISSION RATE 7']);
    excelRow['commAmt07'] = trim(el['COMMISSION AMOUNT 7']);
    excelRow['itemNo08'] = trim(el['ITEM NO 8']);
    excelRow['perilCode08'] = trim(el['PERIL ITEM 8']);
    excelRow['amountCovered08'] = trim(el['AMOUNT COVERED 8']);
    excelRow['calcMethod08'] = trim(el['CALC METHOD 8']);
    excelRow['perilRt08'] = trim(el['PERIL RATE 8']);
    excelRow['grossPrem08'] = trim(el['GROSS PREMIUM 8']);
    excelRow['docStamp08'] = trim(el['DOCUMENT STAMP 8']);
    excelRow['lgt08'] = trim(el['LOCAL GOVT TAX 8']);
    excelRow['vat08'] = trim(el['VAT 8']);
    excelRow['fst08'] = trim(el['FST 8']);
    excelRow['otherTax08'] = trim(el['OTHER TAX 8']);
    excelRow['premTax08'] = trim(el['PREMIUM TAX 8']);
    excelRow['netPrem08'] = trim(el['NET PREMIUM 8']);
    excelRow['commRt08'] = trim(el['COMMISSION RATE 8']);
    excelRow['commAmt08'] = trim(el['COMMISSION AMOUNT 8']);
    excelRow['itemNo09'] = trim(el['ITEM NO 9']);
    excelRow['perilCode09'] = trim(el['PERIL ITEM 9']);
    excelRow['amountCovered09'] = trim(el['AMOUNT COVERED 9']);
    excelRow['calcMethod09'] = trim(el['CALC METHOD 9']);
    excelRow['perilRt09'] = trim(el['PERIL RATE 9']);
    excelRow['grossPrem09'] = trim(el['GROSS PREMIUM 9']);
    excelRow['docStamp09'] = trim(el['DOCUMENT STAMP 9']);
    excelRow['lgt09'] = trim(el['LOCAL GOVT TAX 9']);
    excelRow['vat09'] = trim(el['VAT 9']);
    excelRow['fst09'] = trim(el['FST 9']);
    excelRow['otherTax09'] = trim(el['OTHER TAX 9']);
    excelRow['premTax09'] = trim(el['PREMIUM TAX 9']);
    excelRow['netPrem09'] = trim(el['NET PREMIUM 9']);
    excelRow['commRt09'] = trim(el['COMMISSION RATE 9']);
    excelRow['commAmt09'] = trim(el['COMMISSION AMOUNT 9']);
    excelRow['itemNo10'] = trim(el['ITEM NO 10']);
    excelRow['perilCode10'] = trim(el['PERIL ITEM 10']);
    excelRow['amountCovered10'] = trim(el['AMOUNT COVERED 10']);
    excelRow['calcMethod10'] = trim(el['CALC METHOD 10']);
    excelRow['perilRt10'] = trim(el['PERIL RATE 10']);
    excelRow['grossPrem10'] = trim(el['GROSS PREMIUM 10']);
    excelRow['docStamp10'] = trim(el['DOCUMENT STAMP 10']);
    excelRow['lgt10'] = trim(el['LOCAL GOVT TAX 10']);
    excelRow['vat10'] = trim(el['VAT 10']);
    excelRow['fst10'] = trim(el['FST 10']);
    excelRow['otherTax10'] = trim(el['OTHER TAX 10']);
    excelRow['premTax10'] = trim(el['PREMIUM TAX 10']);
    excelRow['netPrem10'] = trim(el['NET PREMIUM 10']);
    excelRow['commRt10'] = trim(el['COMMISSION RATE 10']);
    excelRow['commAmt10'] = trim(el['COMMISSION AMOUNT 10']);
    excelRow['itemNo11'] = trim(el['ITEM NO 11']);
    excelRow['perilCode11'] = trim(el['PERIL ITEM 11']);
    excelRow['amountCovered11'] = trim(el['AMOUNT COVERED 11']);
    excelRow['calcMethod11'] = trim(el['CALC METHOD 11']);
    excelRow['perilRt11'] = trim(el['PERIL RATE 11']);
    excelRow['grossPrem11'] = trim(el['GROSS PREMIUM 11']);
    excelRow['docStamp11'] = trim(el['DOCUMENT STAMP 11']);
    excelRow['lgt11'] = trim(el['LOCAL GOVT TAX 11']);
    excelRow['vat11'] = trim(el['VAT 11']);
    excelRow['fst11'] = trim(el['FST 11']);
    excelRow['otherTax11'] = trim(el['OTHER TAX 11']);
    excelRow['premTax11'] = trim(el['PREMIUM TAX 11']);
    excelRow['netPrem11'] = trim(el['NET PREMIUM 11']);
    excelRow['commRt11'] = trim(el['COMMISSION RATE 11']);
    excelRow['commAmt11'] = trim(el['COMMISSION AMOUNT 11']);
    excelRow['itemNo12'] = trim(el['ITEM NO 12']);
    excelRow['perilCode12'] = trim(el['PERIL ITEM 12']);
    excelRow['amountCovered12'] = trim(el['AMOUNT COVERED 12']);
    excelRow['calcMethod12'] = trim(el['CALC METHOD 12']);
    excelRow['perilRt12'] = trim(el['PERIL RATE 12']);
    excelRow['grossPrem12'] = trim(el['GROSS PREMIUM 12']);
    excelRow['docStamp12'] = trim(el['DOCUMENT STAMP 12']);
    excelRow['lgt12'] = trim(el['LOCAL GOVT TAX 12']);
    excelRow['vat12'] = trim(el['VAT 12']);
    excelRow['fst12'] = trim(el['FST 12']);
    excelRow['otherTax12'] = trim(el['OTHER TAX 12']);
    excelRow['premTax12'] = trim(el['PREMIUM TAX 12']);
    excelRow['netPrem12'] = trim(el['NET PREMIUM 12']);
    excelRow['commRt12'] = trim(el['COMMISSION RATE 12']);
    excelRow['commAmt12'] = trim(el['COMMISSION AMOUNT 12']);
    excelRow['itemNo13'] = trim(el['ITEM NO 13']);
    excelRow['perilCode13'] = trim(el['PERIL ITEM 13']);
    excelRow['amountCovered13'] = trim(el['AMOUNT COVERED 13']);
    excelRow['calcMethod13'] = trim(el['CALC METHOD 13']);
    excelRow['perilRt13'] = trim(el['PERIL RATE 13']);
    excelRow['grossPrem13'] = trim(el['GROSS PREMIUM 13']);
    excelRow['docStamp13'] = trim(el['DOCUMENT STAMP 13']);
    excelRow['lgt13'] = trim(el['LOCAL GOVT TAX 13']);
    excelRow['vat13'] = trim(el['VAT 13']);
    excelRow['fst13'] = trim(el['FST 13']);
    excelRow['otherTax13'] = trim(el['OTHER TAX 13']);
    excelRow['premTax13'] = trim(el['PREMIUM TAX 13']);
    excelRow['netPrem13'] = trim(el['NET PREMIUM 13']);
    excelRow['commRt13'] = trim(el['COMMISSION RATE 13']);
    excelRow['commAmt13'] = trim(el['COMMISSION AMOUNT 13']);
    excelRow['itemNo14'] = trim(el['ITEM NO 14']);
    excelRow['perilCode14'] = trim(el['PERIL ITEM 14']);
    excelRow['amountCovered14'] = trim(el['AMOUNT COVERED 14']);
    excelRow['calcMethod14'] = trim(el['CALC METHOD 14']);
    excelRow['perilRt14'] = trim(el['PERIL RATE 14']);
    excelRow['grossPrem14'] = trim(el['GROSS PREMIUM 14']);
    excelRow['docStamp14'] = trim(el['DOCUMENT STAMP 14']);
    excelRow['lgt14'] = trim(el['LOCAL GOVT TAX 14']);
    excelRow['vat14'] = trim(el['VAT 14']);
    excelRow['fst14'] = trim(el['FST 14']);
    excelRow['otherTax14'] = trim(el['OTHER TAX 14']);
    excelRow['premTax14'] = trim(el['PREMIUM TAX 14']);
    excelRow['netPrem14'] = trim(el['NET PREMIUM 14']);
    excelRow['commRt14'] = trim(el['COMMISSION RATE 14']);
    excelRow['commAmt14'] = trim(el['COMMISSION AMOUNT 14']);
    excelRow['itemNo15'] = trim(el['ITEM NO 15']);
    excelRow['perilCode15'] = trim(el['PERIL ITEM 15']);
    excelRow['amountCovered15'] = trim(el['AMOUNT COVERED 15']);
    excelRow['calcMethod15'] = trim(el['CALC METHOD 15']);
    excelRow['perilRt15'] = trim(el['PERIL RATE 15']);
    excelRow['grossPrem15'] = trim(el['GROSS PREMIUM 15']);
    excelRow['docStamp15'] = trim(el['DOCUMENT STAMP 15']);
    excelRow['lgt15'] = trim(el['LOCAL GOVT TAX 15']);
    excelRow['vat15'] = trim(el['VAT 15']);
    excelRow['fst15'] = trim(el['FST 15']);
    excelRow['otherTax15'] = trim(el['OTHER TAX 15']);
    excelRow['premTax15'] = trim(el['PREMIUM TAX 15']);
    excelRow['netPrem15'] = trim(el['NET PREMIUM 15']);
    excelRow['commRt15'] = trim(el['COMMISSION RATE 15']);
    excelRow['commAmt15'] = trim(el['COMMISSION AMOUNT 15']);
    excelRow['itemNo16'] = trim(el['ITEM NO 16']);
    excelRow['perilCode16'] = trim(el['PERIL ITEM 16']);
    excelRow['amountCovered16'] = trim(el['AMOUNT COVERED 16']);
    excelRow['calcMethod16'] = trim(el['CALC METHOD 16']);
    excelRow['perilRt16'] = trim(el['PERIL RATE 16']);
    excelRow['grossPrem16'] = trim(el['GROSS PREMIUM 16']);
    excelRow['docStamp16'] = trim(el['DOCUMENT STAMP 16']);
    excelRow['lgt16'] = trim(el['LOCAL GOVT TAX 16']);
    excelRow['vat16'] = trim(el['VAT 16']);
    excelRow['fst16'] = trim(el['FST 16']);
    excelRow['otherTax16'] = trim(el['OTHER TAX 16']);
    excelRow['premTax16'] = trim(el['PREMIUM TAX 16']);
    excelRow['netPrem16'] = trim(el['NET PREMIUM 16']);
    excelRow['commRt16'] = trim(el['COMMISSION RATE 16']);
    excelRow['commAmt16'] = trim(el['COMMISSION AMOUNT 16']);
    excelRow['itemNo17'] = trim(el['ITEM NO 17']);
    excelRow['perilCode17'] = trim(el['PERIL ITEM 17']);
    excelRow['amountCovered17'] = trim(el['AMOUNT COVERED 17']);
    excelRow['calcMethod17'] = trim(el['CALC METHOD 17']);
    excelRow['perilRt17'] = trim(el['PERIL RATE 17']);
    excelRow['grossPrem17'] = trim(el['GROSS PREMIUM 17']);
    excelRow['docStamp17'] = trim(el['DOCUMENT STAMP 17']);
    excelRow['lgt17'] = trim(el['LOCAL GOVT TAX 17']);
    excelRow['vat17'] = trim(el['VAT 17']);
    excelRow['fst17'] = trim(el['FST 17']);
    excelRow['otherTax17'] = trim(el['OTHER TAX 17']);
    excelRow['premTax17'] = trim(el['PREMIUM TAX 17']);
    excelRow['netPrem17'] = trim(el['NET PREMIUM 17']);
    excelRow['commRt17'] = trim(el['COMMISSION RATE 17']);
    excelRow['commAmt17'] = trim(el['COMMISSION AMOUNT 17']);
    excelRow['itemNo18'] = trim(el['ITEM NO 18']);
    excelRow['perilCode18'] = trim(el['PERIL ITEM 18']);
    excelRow['amountCovered18'] = trim(el['AMOUNT COVERED 18']);
    excelRow['calcMethod18'] = trim(el['CALC METHOD 18']);
    excelRow['perilRt18'] = trim(el['PERIL RATE 18']);
    excelRow['grossPrem18'] = trim(el['GROSS PREMIUM 18']);
    excelRow['docStamp18'] = trim(el['DOCUMENT STAMP 18']);
    excelRow['lgt18'] = trim(el['LOCAL GOVT TAX 18']);
    excelRow['vat18'] = trim(el['VAT 18']);
    excelRow['fst18'] = trim(el['FST 18']);
    excelRow['otherTax18'] = trim(el['OTHER TAX 18']);
    excelRow['premTax18'] = trim(el['PREMIUM TAX 18']);
    excelRow['netPrem18'] = trim(el['NET PREMIUM 18']);
    excelRow['commRt18'] = trim(el['COMMISSION RATE 18']);
    excelRow['commAmt18'] = trim(el['COMMISSION AMOUNT 18']);
    excelRow['itemNo19'] = trim(el['ITEM NO 19']);
    excelRow['perilCode19'] = trim(el['PERIL ITEM 19']);
    excelRow['amountCovered19'] = trim(el['AMOUNT COVERED 19']);
    excelRow['calcMethod19'] = trim(el['CALC METHOD 19']);
    excelRow['perilRt19'] = trim(el['PERIL RATE 19']);
    excelRow['grossPrem19'] = trim(el['GROSS PREMIUM 19']);
    excelRow['docStamp19'] = trim(el['DOCUMENT STAMP 19']);
    excelRow['lgt19'] = trim(el['LOCAL GOVT TAX 19']);
    excelRow['vat19'] = trim(el['VAT 19']);
    excelRow['fst19'] = trim(el['FST 19']);
    excelRow['otherTax19'] = trim(el['OTHER TAX 19']);
    excelRow['premTax19'] = trim(el['PREMIUM TAX 19']);
    excelRow['netPrem19'] = trim(el['NET PREMIUM 19']);
    excelRow['commRt19'] = trim(el['COMMISSION RATE 19']);
    excelRow['commAmt19'] = trim(el['COMMISSION AMOUNT 19']);
    excelRow['itemNo20'] = trim(el['ITEM NO 20']);
    excelRow['perilCode20'] = trim(el['PERIL ITEM 20']);
    excelRow['amountCovered20'] = trim(el['AMOUNT COVERED 20']);
    excelRow['calcMethod20'] = trim(el['CALC METHOD 20']);
    excelRow['perilRt20'] = trim(el['PERIL RATE 20']);
    excelRow['grossPrem20'] = trim(el['GROSS PREMIUM 20']);
    excelRow['docStamp20'] = trim(el['DOCUMENT STAMP 20']);
    excelRow['lgt20'] = trim(el['LOCAL GOVT TAX 20']);
    excelRow['vat20'] = trim(el['VAT 20']);
    excelRow['fst20'] = trim(el['FST 20']);
    excelRow['otherTax20'] = trim(el['OTHER TAX 20']);
    excelRow['premTax20'] = trim(el['PREMIUM TAX 20']);
    excelRow['netPrem20'] = trim(el['NET PREMIUM 20']);
    excelRow['commRt20'] = trim(el['COMMISSION RATE 20']);
    excelRow['commAmt20'] = trim(el['COMMISSION AMOUNT 20']);
    excelRow['itemNo21'] = trim(el['ITEM NO 21']);
    excelRow['perilCode21'] = trim(el['PERIL ITEM 21']);
    excelRow['amountCovered21'] = trim(el['AMOUNT COVERED 21']);
    excelRow['calcMethod21'] = trim(el['CALC METHOD 21']);
    excelRow['perilRt21'] = trim(el['PERIL RATE 21']);
    excelRow['grossPrem21'] = trim(el['GROSS PREMIUM 21']);
    excelRow['docStamp21'] = trim(el['DOCUMENT STAMP 21']);
    excelRow['lgt21'] = trim(el['LOCAL GOVT TAX 21']);
    excelRow['vat21'] = trim(el['VAT 21']);
    excelRow['fst21'] = trim(el['FST 21']);
    excelRow['otherTax21'] = trim(el['OTHER TAX 21']);
    excelRow['premTax21'] = trim(el['PREMIUM TAX 21']);
    excelRow['netPrem21'] = trim(el['NET PREMIUM 21']);
    excelRow['commRt21'] = trim(el['COMMISSION RATE 21']);
    excelRow['commAmt21'] = trim(el['COMMISSION AMOUNT 21']);
    excelRow['itemNo22'] = trim(el['ITEM NO 22']);
    excelRow['perilCode22'] = trim(el['PERIL ITEM 22']);
    excelRow['amountCovered22'] = trim(el['AMOUNT COVERED 22']);
    excelRow['calcMethod22'] = trim(el['CALC METHOD 22']);
    excelRow['perilRt22'] = trim(el['PERIL RATE 22']);
    excelRow['grossPrem22'] = trim(el['GROSS PREMIUM 22']);
    excelRow['docStamp22'] = trim(el['DOCUMENT STAMP 22']);
    excelRow['lgt22'] = trim(el['LOCAL GOVT TAX 22']);
    excelRow['vat22'] = trim(el['VAT 22']);
    excelRow['fst22'] = trim(el['FST 22']);
    excelRow['otherTax22'] = trim(el['OTHER TAX 22']);
    excelRow['premTax22'] = trim(el['PREMIUM TAX 22']);
    excelRow['netPrem22'] = trim(el['NET PREMIUM 22']);
    excelRow['commRt22'] = trim(el['COMMISSION RATE 22']);
    excelRow['commAmt22'] = trim(el['COMMISSION AMOUNT 22']);
    excelRow['itemNo23'] = trim(el['ITEM NO 23']);
    excelRow['perilCode23'] = trim(el['PERIL ITEM 23']);
    excelRow['amountCovered23'] = trim(el['AMOUNT COVERED 23']);
    excelRow['calcMethod23'] = trim(el['CALC METHOD 23']);
    excelRow['perilRt23'] = trim(el['PERIL RATE 23']);
    excelRow['grossPrem23'] = trim(el['GROSS PREMIUM 23']);
    excelRow['docStamp23'] = trim(el['DOCUMENT STAMP 23']);
    excelRow['lgt23'] = trim(el['LOCAL GOVT TAX 23']);
    excelRow['vat23'] = trim(el['VAT 23']);
    excelRow['fst23'] = trim(el['FST 23']);
    excelRow['otherTax23'] = trim(el['OTHER TAX 23']);
    excelRow['premTax23'] = trim(el['PREMIUM TAX 23']);
    excelRow['netPrem23'] = trim(el['NET PREMIUM 23']);
    excelRow['commRt23'] = trim(el['COMMISSION RATE 23']);
    excelRow['commAmt23'] = trim(el['COMMISSION AMOUNT 23']);
    excelRow['itemNo24'] = trim(el['ITEM NO 24']);
    excelRow['perilCode24'] = trim(el['PERIL ITEM 24']);
    excelRow['amountCovered24'] = trim(el['AMOUNT COVERED 24']);
    excelRow['calcMethod24'] = trim(el['CALC METHOD 24']);
    excelRow['perilRt24'] = trim(el['PERIL RATE 24']);
    excelRow['grossPrem24'] = trim(el['GROSS PREMIUM 24']);
    excelRow['docStamp24'] = trim(el['DOCUMENT STAMP 24']);
    excelRow['lgt24'] = trim(el['LOCAL GOVT TAX 24']);
    excelRow['vat24'] = trim(el['VAT 24']);
    excelRow['fst24'] = trim(el['FST 24']);
    excelRow['otherTax24'] = trim(el['OTHER TAX 24']);
    excelRow['premTax24'] = trim(el['PREMIUM TAX 24']);
    excelRow['netPrem24'] = trim(el['NET PREMIUM 24']);
    excelRow['commRt24'] = trim(el['COMMISSION RATE 24']);
    excelRow['commAmt24'] = trim(el['COMMISSION AMOUNT 24']);
    excelRow['itemNo25'] = trim(el['ITEM NO 25']);
    excelRow['perilCode25'] = trim(el['PERIL ITEM 25']);
    excelRow['amountCovered25'] = trim(el['AMOUNT COVERED 25']);
    excelRow['calcMethod25'] = trim(el['CALC METHOD 25']);
    excelRow['perilRt25'] = trim(el['PERIL RATE 25']);
    excelRow['grossPrem25'] = trim(el['GROSS PREMIUM 25']);
    excelRow['docStamp25'] = trim(el['DOCUMENT STAMP 25']);
    excelRow['lgt25'] = trim(el['LOCAL GOVT TAX 25']);
    excelRow['vat25'] = trim(el['VAT 25']);
    excelRow['fst25'] = trim(el['FST 25']);
    excelRow['otherTax25'] = trim(el['OTHER TAX 25']);
    excelRow['premTax25'] = trim(el['PREMIUM TAX 25']);
    excelRow['netPrem25'] = trim(el['NET PREMIUM 25']);
    excelRow['commRt25'] = trim(el['COMMISSION RATE 25']);
    excelRow['commAmt25'] = trim(el['COMMISSION AMOUNT 25']);
    excelRow['itemNo26'] = trim(el['ITEM NO 26']);
    excelRow['perilCode26'] = trim(el['PERIL ITEM 26']);
    excelRow['amountCovered26'] = trim(el['AMOUNT COVERED 26']);
    excelRow['calcMethod26'] = trim(el['CALC METHOD 26']);
    excelRow['perilRt26'] = trim(el['PERIL RATE 26']);
    excelRow['grossPrem26'] = trim(el['GROSS PREMIUM 26']);
    excelRow['docStamp26'] = trim(el['DOCUMENT STAMP 26']);
    excelRow['lgt26'] = trim(el['LOCAL GOVT TAX 26']);
    excelRow['vat26'] = trim(el['VAT 26']);
    excelRow['fst26'] = trim(el['FST 26']);
    excelRow['otherTax26'] = trim(el['OTHER TAX 26']);
    excelRow['premTax26'] = trim(el['PREMIUM TAX 26']);
    excelRow['netPrem26'] = trim(el['NET PREMIUM 26']);
    excelRow['commRt26'] = trim(el['COMMISSION RATE 26']);
    excelRow['commAmt26'] = trim(el['COMMISSION AMOUNT 26']);
    excelRow['itemNo27'] = trim(el['ITEM NO 27']);
    excelRow['perilCode27'] = trim(el['PERIL ITEM 27']);
    excelRow['amountCovered27'] = trim(el['AMOUNT COVERED 27']);
    excelRow['calcMethod27'] = trim(el['CALC METHOD 27']);
    excelRow['perilRt27'] = trim(el['PERIL RATE 27']);
    excelRow['grossPrem27'] = trim(el['GROSS PREMIUM 27']);
    excelRow['docStamp27'] = trim(el['DOCUMENT STAMP 27']);
    excelRow['lgt27'] = trim(el['LOCAL GOVT TAX 27']);
    excelRow['vat27'] = trim(el['VAT 27']);
    excelRow['fst27'] = trim(el['FST 27']);
    excelRow['otherTax27'] = trim(el['OTHER TAX 27']);
    excelRow['premTax27'] = trim(el['PREMIUM TAX 27']);
    excelRow['netPrem27'] = trim(el['NET PREMIUM 27']);
    excelRow['commRt27'] = trim(el['COMMISSION RATE 27']);
    excelRow['commAmt27'] = trim(el['COMMISSION AMOUNT 27']);
    excelRow['itemNo28'] = trim(el['ITEM NO 28']);
    excelRow['perilCode28'] = trim(el['PERIL ITEM 28']);
    excelRow['amountCovered28'] = trim(el['AMOUNT COVERED 28']);
    excelRow['calcMethod28'] = trim(el['CALC METHOD 28']);
    excelRow['perilRt28'] = trim(el['PERIL RATE 28']);
    excelRow['grossPrem28'] = trim(el['GROSS PREMIUM 28']);
    excelRow['docStamp28'] = trim(el['DOCUMENT STAMP 28']);
    excelRow['lgt28'] = trim(el['LOCAL GOVT TAX 28']);
    excelRow['vat28'] = trim(el['VAT 28']);
    excelRow['fst28'] = trim(el['FST 28']);
    excelRow['otherTax28'] = trim(el['OTHER TAX 28']);
    excelRow['premTax28'] = trim(el['PREMIUM TAX 28']);
    excelRow['netPrem28'] = trim(el['NET PREMIUM 28']);
    excelRow['commRt28'] = trim(el['COMMISSION RATE 28']);
    excelRow['commAmt28'] = trim(el['COMMISSION AMOUNT 28']);
    excelRow['itemNo29'] = trim(el['ITEM NO 29']);
    excelRow['perilCode29'] = trim(el['PERIL ITEM 29']);
    excelRow['amountCovered29'] = trim(el['AMOUNT COVERED 29']);
    excelRow['calcMethod29'] = trim(el['CALC METHOD 29']);
    excelRow['perilRt29'] = trim(el['PERIL RATE 29']);
    excelRow['grossPrem29'] = trim(el['GROSS PREMIUM 29']);
    excelRow['docStamp29'] = trim(el['DOCUMENT STAMP 29']);
    excelRow['lgt29'] = trim(el['LOCAL GOVT TAX 29']);
    excelRow['vat29'] = trim(el['VAT 29']);
    excelRow['fst29'] = trim(el['FST 29']);
    excelRow['otherTax29'] = trim(el['OTHER TAX 29']);
    excelRow['premTax29'] = trim(el['PREMIUM TAX 29']);
    excelRow['netPrem29'] = trim(el['NET PREMIUM 29']);
    excelRow['commRt29'] = trim(el['COMMISSION RATE 29']);
    excelRow['commAmt29'] = trim(el['COMMISSION AMOUNT 29']);
    excelRow['itemNo30'] = trim(el['ITEM NO 30']);
    excelRow['perilCode30'] = trim(el['PERIL ITEM 30']);
    excelRow['amountCovered30'] = trim(el['AMOUNT COVERED 30']);
    excelRow['calcMethod30'] = trim(el['CALC METHOD 30']);
    excelRow['perilRt30'] = trim(el['PERIL RATE 30']);
    excelRow['grossPrem30'] = trim(el['GROSS PREMIUM 30']);
    excelRow['docStamp30'] = trim(el['DOCUMENT STAMP 30']);
    excelRow['lgt30'] = trim(el['LOCAL GOVT TAX 30']);
    excelRow['vat30'] = trim(el['VAT 30']);
    excelRow['fst30'] = trim(el['FST 30']);
    excelRow['otherTax30'] = trim(el['OTHER TAX 30']);
    excelRow['premTax30'] = trim(el['PREMIUM TAX 30']);
    excelRow['netPrem30'] = trim(el['NET PREMIUM 30']);
    excelRow['commRt30'] = trim(el['COMMISSION RATE 30']);
    excelRow['commAmt30'] = trim(el['COMMISSION AMOUNT 30']);
    excelRow['wcItemNo01'] = trim(el['WC ITEM NO 1']);
    excelRow['wcCd01'] = trim(el['WARRANTY/CLAUSE CODE 1']);
    excelRow['wcTextA01'] = trim(el['WC TEXT A 1']);
    excelRow['wcTextB01'] = trim(el['WC TEXT B 1']);
    excelRow['wcTextC01'] = trim(el['WC TEXT C 1']);
    excelRow['wcTextD01'] = trim(el['WC TEXT D 1']);
    excelRow['wcTextE01'] = trim(el['WC TEXT E 1']);
    excelRow['wcTextF01'] = trim(el['WC TEXT F 1']);
    excelRow['wcTextG01'] = trim(el['WC TEXT G 1']);
    excelRow['wcTextH01'] = trim(el['WC TEXT H 1']);
    excelRow['wcTextI01'] = trim(el['WC TEXT I 1']);
    excelRow['wcTextJ01'] = trim(el['WC TEXT J 1']);
    excelRow['wcTextK01'] = trim(el['WC TEXT K 1']);
    excelRow['wcTextL01'] = trim(el['WC TEXT L 1']);
    excelRow['wcTextM01'] = trim(el['WC TEXT M 1']);
    excelRow['wcTextN01'] = trim(el['WC TEXT N 1']);
    excelRow['wcTextO01'] = trim(el['WC TEXT O 1']);
    excelRow['wcItemNo02'] = trim(el['WC ITEM NO 2']);
    excelRow['wcCd02'] = trim(el['WARRANTY/CLAUSE CODE 2']);
    excelRow['wcTextA02'] = trim(el['WC TEXT A 2']);
    excelRow['wcTextB02'] = trim(el['WC TEXT B 2']);
    excelRow['wcTextC02'] = trim(el['WC TEXT C 2']);
    excelRow['wcTextD02'] = trim(el['WC TEXT D 2']);
    excelRow['wcTextE02'] = trim(el['WC TEXT E 2']);
    excelRow['wcTextF02'] = trim(el['WC TEXT F 2']);
    excelRow['wcTextG02'] = trim(el['WC TEXT G 2']);
    excelRow['wcTextH02'] = trim(el['WC TEXT H 2']);
    excelRow['wcTextI02'] = trim(el['WC TEXT I 2']);
    excelRow['wcTextJ02'] = trim(el['WC TEXT J 2']);
    excelRow['wcTextK02'] = trim(el['WC TEXT K 2']);
    excelRow['wcTextL02'] = trim(el['WC TEXT L 2']);
    excelRow['wcTextM02'] = trim(el['WC TEXT M 2']);
    excelRow['wcTextN02'] = trim(el['WC TEXT N 2']);
    excelRow['wcTextO02'] = trim(el['WC TEXT O 2']);
    excelRow['wcItemNo03'] = trim(el['WC ITEM NO 3']);
    excelRow['wcCd03'] = trim(el['WARRANTY/CLAUSE CODE 3']);
    excelRow['wcTextA03'] = trim(el['WC TEXT A 3']);
    excelRow['wcTextB03'] = trim(el['WC TEXT B 3']);
    excelRow['wcTextC03'] = trim(el['WC TEXT C 3']);
    excelRow['wcTextD03'] = trim(el['WC TEXT D 3']);
    excelRow['wcTextE03'] = trim(el['WC TEXT E 3']);
    excelRow['wcTextF03'] = trim(el['WC TEXT F 3']);
    excelRow['wcTextG03'] = trim(el['WC TEXT G 3']);
    excelRow['wcTextH03'] = trim(el['WC TEXT H 3']);
    excelRow['wcTextI03'] = trim(el['WC TEXT I 3']);
    excelRow['wcTextJ03'] = trim(el['WC TEXT J 3']);
    excelRow['wcTextK03'] = trim(el['WC TEXT K 3']);
    excelRow['wcTextL03'] = trim(el['WC TEXT L 3']);
    excelRow['wcTextM03'] = trim(el['WC TEXT M 3']);
    excelRow['wcTextN03'] = trim(el['WC TEXT N 3']);
    excelRow['wcTextO03'] = trim(el['WC TEXT O 3']);
    excelRow['wcItemNo04'] = trim(el['WC ITEM NO 4']);
    excelRow['wcCd04'] = trim(el['WARRANTY/CLAUSE CODE 4']);
    excelRow['wcTextA04'] = trim(el['WC TEXT A 4']);
    excelRow['wcTextB04'] = trim(el['WC TEXT B 4']);
    excelRow['wcTextC04'] = trim(el['WC TEXT C 4']);
    excelRow['wcTextD04'] = trim(el['WC TEXT D 4']);
    excelRow['wcTextE04'] = trim(el['WC TEXT E 4']);
    excelRow['wcTextF04'] = trim(el['WC TEXT F 4']);
    excelRow['wcTextG04'] = trim(el['WC TEXT G 4']);
    excelRow['wcTextH04'] = trim(el['WC TEXT H 4']);
    excelRow['wcTextI04'] = trim(el['WC TEXT I 4']);
    excelRow['wcTextJ04'] = trim(el['WC TEXT J 4']);
    excelRow['wcTextK04'] = trim(el['WC TEXT K 4']);
    excelRow['wcTextL04'] = trim(el['WC TEXT L 4']);
    excelRow['wcTextM04'] = trim(el['WC TEXT M 4']);
    excelRow['wcTextN04'] = trim(el['WC TEXT N 4']);
    excelRow['wcTextO04'] = trim(el['WC TEXT O 4']);
    excelRow['wcItemNo05'] = trim(el['WC ITEM NO 5']);
    excelRow['wcCd05'] = trim(el['WARRANTY/CLAUSE CODE 5']);
    excelRow['wcTextA05'] = trim(el['WC TEXT A 5']);
    excelRow['wcTextB05'] = trim(el['WC TEXT B 5']);
    excelRow['wcTextC05'] = trim(el['WC TEXT C 5']);
    excelRow['wcTextD05'] = trim(el['WC TEXT D 5']);
    excelRow['wcTextE05'] = trim(el['WC TEXT E 5']);
    excelRow['wcTextF05'] = trim(el['WC TEXT F 5']);
    excelRow['wcTextG05'] = trim(el['WC TEXT G 5']);
    excelRow['wcTextH05'] = trim(el['WC TEXT H 5']);
    excelRow['wcTextI05'] = trim(el['WC TEXT I 5']);
    excelRow['wcTextJ05'] = trim(el['WC TEXT J 5']);
    excelRow['wcTextK05'] = trim(el['WC TEXT K 5']);
    excelRow['wcTextL05'] = trim(el['WC TEXT L 5']);
    excelRow['wcTextM05'] = trim(el['WC TEXT M 5']);
    excelRow['wcTextN05'] = trim(el['WC TEXT N 5']);
    excelRow['wcTextO05'] = trim(el['WC TEXT O 5']);
    excelRow['wcItemNo06'] = trim(el['WC ITEM NO 6']);
    excelRow['wcCd06'] = trim(el['WARRANTY/CLAUSE CODE 6']);
    excelRow['wcTextA06'] = trim(el['WC TEXT A 6']);
    excelRow['wcTextB06'] = trim(el['WC TEXT B 6']);
    excelRow['wcTextC06'] = trim(el['WC TEXT C 6']);
    excelRow['wcTextD06'] = trim(el['WC TEXT D 6']);
    excelRow['wcTextE06'] = trim(el['WC TEXT E 6']);
    excelRow['wcTextF06'] = trim(el['WC TEXT F 6']);
    excelRow['wcTextG06'] = trim(el['WC TEXT G 6']);
    excelRow['wcTextH06'] = trim(el['WC TEXT H 6']);
    excelRow['wcTextI06'] = trim(el['WC TEXT I 6']);
    excelRow['wcTextJ06'] = trim(el['WC TEXT J 6']);
    excelRow['wcTextK06'] = trim(el['WC TEXT K 6']);
    excelRow['wcTextL06'] = trim(el['WC TEXT L 6']);
    excelRow['wcTextM06'] = trim(el['WC TEXT M 6']);
    excelRow['wcTextN06'] = trim(el['WC TEXT N 6']);
    excelRow['wcTextO06'] = trim(el['WC TEXT O 6']);
    excelRow['wcItemNo07'] = trim(el['WC ITEM NO 7']);
    excelRow['wcCd07'] = trim(el['WARRANTY/CLAUSE CODE 7']);
    excelRow['wcTextA07'] = trim(el['WC TEXT A 7']);
    excelRow['wcTextB07'] = trim(el['WC TEXT B 7']);
    excelRow['wcTextC07'] = trim(el['WC TEXT C 7']);
    excelRow['wcTextD07'] = trim(el['WC TEXT D 7']);
    excelRow['wcTextE07'] = trim(el['WC TEXT E 7']);
    excelRow['wcTextF07'] = trim(el['WC TEXT F 7']);
    excelRow['wcTextG07'] = trim(el['WC TEXT G 7']);
    excelRow['wcTextH07'] = trim(el['WC TEXT H 7']);
    excelRow['wcTextI07'] = trim(el['WC TEXT I 7']);
    excelRow['wcTextJ07'] = trim(el['WC TEXT J 7']);
    excelRow['wcTextK07'] = trim(el['WC TEXT K 7']);
    excelRow['wcTextL07'] = trim(el['WC TEXT L 7']);
    excelRow['wcTextM07'] = trim(el['WC TEXT M 7']);
    excelRow['wcTextN07'] = trim(el['WC TEXT N 7']);
    excelRow['wcTextO07'] = trim(el['WC TEXT O 7']);
    excelRow['wcItemNo08'] = trim(el['WC ITEM NO 8']);
    excelRow['wcCd08'] = trim(el['WARRANTY/CLAUSE CODE 8']);
    excelRow['wcTextA08'] = trim(el['WC TEXT A 8']);
    excelRow['wcTextB08'] = trim(el['WC TEXT B 8']);
    excelRow['wcTextC08'] = trim(el['WC TEXT C 8']);
    excelRow['wcTextD08'] = trim(el['WC TEXT D 8']);
    excelRow['wcTextE08'] = trim(el['WC TEXT E 8']);
    excelRow['wcTextF08'] = trim(el['WC TEXT F 8']);
    excelRow['wcTextG08'] = trim(el['WC TEXT G 8']);
    excelRow['wcTextH08'] = trim(el['WC TEXT H 8']);
    excelRow['wcTextI08'] = trim(el['WC TEXT I 8']);
    excelRow['wcTextJ08'] = trim(el['WC TEXT J 8']);
    excelRow['wcTextK08'] = trim(el['WC TEXT K 8']);
    excelRow['wcTextL08'] = trim(el['WC TEXT L 8']);
    excelRow['wcTextM08'] = trim(el['WC TEXT M 8']);
    excelRow['wcTextN08'] = trim(el['WC TEXT N 8']);
    excelRow['wcTextO08'] = trim(el['WC TEXT O 8']);
    excelRow['wcItemNo09'] = trim(el['WC ITEM NO 9']);
    excelRow['wcCd09'] = trim(el['WARRANTY/CLAUSE CODE 9']);
    excelRow['wcTextA09'] = trim(el['WC TEXT A 9']);
    excelRow['wcTextB09'] = trim(el['WC TEXT B 9']);
    excelRow['wcTextC09'] = trim(el['WC TEXT C 9']);
    excelRow['wcTextD09'] = trim(el['WC TEXT D 9']);
    excelRow['wcTextE09'] = trim(el['WC TEXT E 9']);
    excelRow['wcTextF09'] = trim(el['WC TEXT F 9']);
    excelRow['wcTextG09'] = trim(el['WC TEXT G 9']);
    excelRow['wcTextH09'] = trim(el['WC TEXT H 9']);
    excelRow['wcTextI09'] = trim(el['WC TEXT I 9']);
    excelRow['wcTextJ09'] = trim(el['WC TEXT J 9']);
    excelRow['wcTextK09'] = trim(el['WC TEXT K 9']);
    excelRow['wcTextL09'] = trim(el['WC TEXT L 9']);
    excelRow['wcTextM09'] = trim(el['WC TEXT M 9']);
    excelRow['wcTextN09'] = trim(el['WC TEXT N 9']);
    excelRow['wcTextO09'] = trim(el['WC TEXT O 9']);
    excelRow['wcItemNo10'] = trim(el['WC ITEM NO 10']);
    excelRow['wcCd10'] = trim(el['WARRANTY/CLAUSE CODE 10']);
    excelRow['wcTextA10'] = trim(el['WC TEXT A 10']);
    excelRow['wcTextB10'] = trim(el['WC TEXT B 10']);
    excelRow['wcTextC10'] = trim(el['WC TEXT C 10']);
    excelRow['wcTextD10'] = trim(el['WC TEXT D 10']);
    excelRow['wcTextE10'] = trim(el['WC TEXT E 10']);
    excelRow['wcTextF10'] = trim(el['WC TEXT F 10']);
    excelRow['wcTextG10'] = trim(el['WC TEXT G 10']);
    excelRow['wcTextH10'] = trim(el['WC TEXT H 10']);
    excelRow['wcTextI10'] = trim(el['WC TEXT I 10']);
    excelRow['wcTextJ10'] = trim(el['WC TEXT J 10']);
    excelRow['wcTextK10'] = trim(el['WC TEXT K 10']);
    excelRow['wcTextL10'] = trim(el['WC TEXT L 10']);
    excelRow['wcTextM10'] = trim(el['WC TEXT M 10']);
    excelRow['wcTextN10'] = trim(el['WC TEXT N 10']);
    excelRow['wcTextO10'] = trim(el['WC TEXT O 10']);
    excelRow['wcItemNo11'] = trim(el['WC ITEM NO 11']);
    excelRow['wcCd11'] = trim(el['WARRANTY/CLAUSE CODE 11']);
    excelRow['wcTextA11'] = trim(el['WC TEXT A 11']);
    excelRow['wcTextB11'] = trim(el['WC TEXT B 11']);
    excelRow['wcTextC11'] = trim(el['WC TEXT C 11']);
    excelRow['wcTextD11'] = trim(el['WC TEXT D 11']);
    excelRow['wcTextE11'] = trim(el['WC TEXT E 11']);
    excelRow['wcTextF11'] = trim(el['WC TEXT F 11']);
    excelRow['wcTextG11'] = trim(el['WC TEXT G 11']);
    excelRow['wcTextH11'] = trim(el['WC TEXT H 11']);
    excelRow['wcTextI11'] = trim(el['WC TEXT I 11']);
    excelRow['wcTextJ11'] = trim(el['WC TEXT J 11']);
    excelRow['wcTextK11'] = trim(el['WC TEXT K 11']);
    excelRow['wcTextL11'] = trim(el['WC TEXT L 11']);
    excelRow['wcTextM11'] = trim(el['WC TEXT M 11']);
    excelRow['wcTextN11'] = trim(el['WC TEXT N 11']);
    excelRow['wcTextO11'] = trim(el['WC TEXT O 11']);
    excelRow['wcItemNo12'] = trim(el['WC ITEM NO 12']);
    excelRow['wcCd12'] = trim(el['WARRANTY/CLAUSE CODE 12']);
    excelRow['wcTextA12'] = trim(el['WC TEXT A 12']);
    excelRow['wcTextB12'] = trim(el['WC TEXT B 12']);
    excelRow['wcTextC12'] = trim(el['WC TEXT C 12']);
    excelRow['wcTextD12'] = trim(el['WC TEXT D 12']);
    excelRow['wcTextE12'] = trim(el['WC TEXT E 12']);
    excelRow['wcTextF12'] = trim(el['WC TEXT F 12']);
    excelRow['wcTextG12'] = trim(el['WC TEXT G 12']);
    excelRow['wcTextH12'] = trim(el['WC TEXT H 12']);
    excelRow['wcTextI12'] = trim(el['WC TEXT I 12']);
    excelRow['wcTextJ12'] = trim(el['WC TEXT J 12']);
    excelRow['wcTextK12'] = trim(el['WC TEXT K 12']);
    excelRow['wcTextL12'] = trim(el['WC TEXT L 12']);
    excelRow['wcTextM12'] = trim(el['WC TEXT M 12']);
    excelRow['wcTextN12'] = trim(el['WC TEXT N 12']);
    excelRow['wcTextO12'] = trim(el['WC TEXT O 12']);
    excelRow['wcItemNo13'] = trim(el['WC ITEM NO 13']);
    excelRow['wcCd13'] = trim(el['WARRANTY/CLAUSE CODE 13']);
    excelRow['wcTextA13'] = trim(el['WC TEXT A 13']);
    excelRow['wcTextB13'] = trim(el['WC TEXT B 13']);
    excelRow['wcTextC13'] = trim(el['WC TEXT C 13']);
    excelRow['wcTextD13'] = trim(el['WC TEXT D 13']);
    excelRow['wcTextE13'] = trim(el['WC TEXT E 13']);
    excelRow['wcTextF13'] = trim(el['WC TEXT F 13']);
    excelRow['wcTextG13'] = trim(el['WC TEXT G 13']);
    excelRow['wcTextH13'] = trim(el['WC TEXT H 13']);
    excelRow['wcTextI13'] = trim(el['WC TEXT I 13']);
    excelRow['wcTextJ13'] = trim(el['WC TEXT J 13']);
    excelRow['wcTextK13'] = trim(el['WC TEXT K 13']);
    excelRow['wcTextL13'] = trim(el['WC TEXT L 13']);
    excelRow['wcTextM13'] = trim(el['WC TEXT M 13']);
    excelRow['wcTextN13'] = trim(el['WC TEXT N 13']);
    excelRow['wcTextO13'] = trim(el['WC TEXT O 13']);
    excelRow['wcItemNo14'] = trim(el['WC ITEM NO 14']);
    excelRow['wcCd14'] = trim(el['WARRANTY/CLAUSE CODE 14']);
    excelRow['wcTextA14'] = trim(el['WC TEXT A 14']);
    excelRow['wcTextB14'] = trim(el['WC TEXT B 14']);
    excelRow['wcTextC14'] = trim(el['WC TEXT C 14']);
    excelRow['wcTextD14'] = trim(el['WC TEXT D 14']);
    excelRow['wcTextE14'] = trim(el['WC TEXT E 14']);
    excelRow['wcTextF14'] = trim(el['WC TEXT F 14']);
    excelRow['wcTextG14'] = trim(el['WC TEXT G 14']);
    excelRow['wcTextH14'] = trim(el['WC TEXT H 14']);
    excelRow['wcTextI14'] = trim(el['WC TEXT I 14']);
    excelRow['wcTextJ14'] = trim(el['WC TEXT J 14']);
    excelRow['wcTextK14'] = trim(el['WC TEXT K 14']);
    excelRow['wcTextL14'] = trim(el['WC TEXT L 14']);
    excelRow['wcTextM14'] = trim(el['WC TEXT M 14']);
    excelRow['wcTextN14'] = trim(el['WC TEXT N 14']);
    excelRow['wcTextO14'] = trim(el['WC TEXT O 14']);
    excelRow['wcItemNo15'] = trim(el['WC ITEM NO 15']);
    excelRow['wcCd15'] = trim(el['WARRANTY/CLAUSE CODE 15']);
    excelRow['wcTextA15'] = trim(el['WC TEXT A 15']);
    excelRow['wcTextB15'] = trim(el['WC TEXT B 15']);
    excelRow['wcTextC15'] = trim(el['WC TEXT C 15']);
    excelRow['wcTextD15'] = trim(el['WC TEXT D 15']);
    excelRow['wcTextE15'] = trim(el['WC TEXT E 15']);
    excelRow['wcTextF15'] = trim(el['WC TEXT F 15']);
    excelRow['wcTextG15'] = trim(el['WC TEXT G 15']);
    excelRow['wcTextH15'] = trim(el['WC TEXT H 15']);
    excelRow['wcTextI15'] = trim(el['WC TEXT I 15']);
    excelRow['wcTextJ15'] = trim(el['WC TEXT J 15']);
    excelRow['wcTextK15'] = trim(el['WC TEXT K 15']);
    excelRow['wcTextL15'] = trim(el['WC TEXT L 15']);
    excelRow['wcTextM15'] = trim(el['WC TEXT M 15']);
    excelRow['wcTextN15'] = trim(el['WC TEXT N 15']);
    excelRow['wcTextO15'] = trim(el['WC TEXT O 15']);
    excelRow['wcItemNo16'] = trim(el['WC ITEM NO 16']);
    excelRow['wcCd16'] = trim(el['WARRANTY/CLAUSE CODE 16']);
    excelRow['wcTextA16'] = trim(el['WC TEXT A 16']);
    excelRow['wcTextB16'] = trim(el['WC TEXT B 16']);
    excelRow['wcTextC16'] = trim(el['WC TEXT C 16']);
    excelRow['wcTextD16'] = trim(el['WC TEXT D 16']);
    excelRow['wcTextE16'] = trim(el['WC TEXT E 16']);
    excelRow['wcTextF16'] = trim(el['WC TEXT F 16']);
    excelRow['wcTextG16'] = trim(el['WC TEXT G 16']);
    excelRow['wcTextH16'] = trim(el['WC TEXT H 16']);
    excelRow['wcTextI16'] = trim(el['WC TEXT I 16']);
    excelRow['wcTextJ16'] = trim(el['WC TEXT J 16']);
    excelRow['wcTextK16'] = trim(el['WC TEXT K 16']);
    excelRow['wcTextL16'] = trim(el['WC TEXT L 16']);
    excelRow['wcTextM16'] = trim(el['WC TEXT M 16']);
    excelRow['wcTextN16'] = trim(el['WC TEXT N 16']);
    excelRow['wcTextO16'] = trim(el['WC TEXT O 16']);
    excelRow['wcItemNo17'] = trim(el['WC ITEM NO 17']);
    excelRow['wcCd17'] = trim(el['WARRANTY/CLAUSE CODE 17']);
    excelRow['wcTextA17'] = trim(el['WC TEXT A 17']);
    excelRow['wcTextB17'] = trim(el['WC TEXT B 17']);
    excelRow['wcTextC17'] = trim(el['WC TEXT C 17']);
    excelRow['wcTextD17'] = trim(el['WC TEXT D 17']);
    excelRow['wcTextE17'] = trim(el['WC TEXT E 17']);
    excelRow['wcTextF17'] = trim(el['WC TEXT F 17']);
    excelRow['wcTextG17'] = trim(el['WC TEXT G 17']);
    excelRow['wcTextH17'] = trim(el['WC TEXT H 17']);
    excelRow['wcTextI17'] = trim(el['WC TEXT I 17']);
    excelRow['wcTextJ17'] = trim(el['WC TEXT J 17']);
    excelRow['wcTextK17'] = trim(el['WC TEXT K 17']);
    excelRow['wcTextL17'] = trim(el['WC TEXT L 17']);
    excelRow['wcTextM17'] = trim(el['WC TEXT M 17']);
    excelRow['wcTextN17'] = trim(el['WC TEXT N 17']);
    excelRow['wcTextO17'] = trim(el['WC TEXT O 17']);
    excelRow['wcItemNo18'] = trim(el['WC ITEM NO 18']);
    excelRow['wcCd18'] = trim(el['WARRANTY/CLAUSE CODE 18']);
    excelRow['wcTextA18'] = trim(el['WC TEXT A 18']);
    excelRow['wcTextB18'] = trim(el['WC TEXT B 18']);
    excelRow['wcTextC18'] = trim(el['WC TEXT C 18']);
    excelRow['wcTextD18'] = trim(el['WC TEXT D 18']);
    excelRow['wcTextE18'] = trim(el['WC TEXT E 18']);
    excelRow['wcTextF18'] = trim(el['WC TEXT F 18']);
    excelRow['wcTextG18'] = trim(el['WC TEXT G 18']);
    excelRow['wcTextH18'] = trim(el['WC TEXT H 18']);
    excelRow['wcTextI18'] = trim(el['WC TEXT I 18']);
    excelRow['wcTextJ18'] = trim(el['WC TEXT J 18']);
    excelRow['wcTextK18'] = trim(el['WC TEXT K 18']);
    excelRow['wcTextL18'] = trim(el['WC TEXT L 18']);
    excelRow['wcTextM18'] = trim(el['WC TEXT M 18']);
    excelRow['wcTextN18'] = trim(el['WC TEXT N 18']);
    excelRow['wcTextO18'] = trim(el['WC TEXT O 18']);
    excelRow['wcItemNo19'] = trim(el['WC ITEM NO 19']);
    excelRow['wcCd19'] = trim(el['WARRANTY/CLAUSE CODE 19']);
    excelRow['wcTextA19'] = trim(el['WC TEXT A 19']);
    excelRow['wcTextB19'] = trim(el['WC TEXT B 19']);
    excelRow['wcTextC19'] = trim(el['WC TEXT C 19']);
    excelRow['wcTextD19'] = trim(el['WC TEXT D 19']);
    excelRow['wcTextE19'] = trim(el['WC TEXT E 19']);
    excelRow['wcTextF19'] = trim(el['WC TEXT F 19']);
    excelRow['wcTextG19'] = trim(el['WC TEXT G 19']);
    excelRow['wcTextH19'] = trim(el['WC TEXT H 19']);
    excelRow['wcTextI19'] = trim(el['WC TEXT I 19']);
    excelRow['wcTextJ19'] = trim(el['WC TEXT J 19']);
    excelRow['wcTextK19'] = trim(el['WC TEXT K 19']);
    excelRow['wcTextL19'] = trim(el['WC TEXT L 19']);
    excelRow['wcTextM19'] = trim(el['WC TEXT M 19']);
    excelRow['wcTextN19'] = trim(el['WC TEXT N 19']);
    excelRow['wcTextO19'] = trim(el['WC TEXT O 19']);
    excelRow['wcItemNo20'] = trim(el['WC ITEM NO 20']);
    excelRow['wcCd20'] = trim(el['WARRANTY/CLAUSE CODE 20']);
    excelRow['wcTextA20'] = trim(el['WC TEXT A 20']);
    excelRow['wcTextB20'] = trim(el['WC TEXT B 20']);
    excelRow['wcTextC20'] = trim(el['WC TEXT C 20']);
    excelRow['wcTextD20'] = trim(el['WC TEXT D 20']);
    excelRow['wcTextE20'] = trim(el['WC TEXT E 20']);
    excelRow['wcTextF20'] = trim(el['WC TEXT F 20']);
    excelRow['wcTextG20'] = trim(el['WC TEXT G 20']);
    excelRow['wcTextH20'] = trim(el['WC TEXT H 20']);
    excelRow['wcTextI20'] = trim(el['WC TEXT I 20']);
    excelRow['wcTextJ20'] = trim(el['WC TEXT J 20']);
    excelRow['wcTextK20'] = trim(el['WC TEXT K 20']);
    excelRow['wcTextL20'] = trim(el['WC TEXT L 20']);
    excelRow['wcTextM20'] = trim(el['WC TEXT M 20']);
    excelRow['wcTextN20'] = trim(el['WC TEXT N 20']);
    excelRow['wcTextO20'] = trim(el['WC TEXT O 20']);
    excelRow['wcItemNo21'] = trim(el['WC ITEM NO 21']);
    excelRow['wcCd21'] = trim(el['WARRANTY/CLAUSE CODE 21']);
    excelRow['wcTextA21'] = trim(el['WC TEXT A 21']);
    excelRow['wcTextB21'] = trim(el['WC TEXT B 21']);
    excelRow['wcTextC21'] = trim(el['WC TEXT C 21']);
    excelRow['wcTextD21'] = trim(el['WC TEXT D 21']);
    excelRow['wcTextE21'] = trim(el['WC TEXT E 21']);
    excelRow['wcTextF21'] = trim(el['WC TEXT F 21']);
    excelRow['wcTextG21'] = trim(el['WC TEXT G 21']);
    excelRow['wcTextH21'] = trim(el['WC TEXT H 21']);
    excelRow['wcTextI21'] = trim(el['WC TEXT I 21']);
    excelRow['wcTextJ21'] = trim(el['WC TEXT J 21']);
    excelRow['wcTextK21'] = trim(el['WC TEXT K 21']);
    excelRow['wcTextL21'] = trim(el['WC TEXT L 21']);
    excelRow['wcTextM21'] = trim(el['WC TEXT M 21']);
    excelRow['wcTextN21'] = trim(el['WC TEXT N 21']);
    excelRow['wcTextO21'] = trim(el['WC TEXT O 21']);
    excelRow['wcItemNo22'] = trim(el['WC ITEM NO 22']);
    excelRow['wcCd22'] = trim(el['WARRANTY/CLAUSE CODE 22']);
    excelRow['wcTextA22'] = trim(el['WC TEXT A 22']);
    excelRow['wcTextB22'] = trim(el['WC TEXT B 22']);
    excelRow['wcTextC22'] = trim(el['WC TEXT C 22']);
    excelRow['wcTextD22'] = trim(el['WC TEXT D 22']);
    excelRow['wcTextE22'] = trim(el['WC TEXT E 22']);
    excelRow['wcTextF22'] = trim(el['WC TEXT F 22']);
    excelRow['wcTextG22'] = trim(el['WC TEXT G 22']);
    excelRow['wcTextH22'] = trim(el['WC TEXT H 22']);
    excelRow['wcTextI22'] = trim(el['WC TEXT I 22']);
    excelRow['wcTextJ22'] = trim(el['WC TEXT J 22']);
    excelRow['wcTextK22'] = trim(el['WC TEXT K 22']);
    excelRow['wcTextL22'] = trim(el['WC TEXT L 22']);
    excelRow['wcTextM22'] = trim(el['WC TEXT M 22']);
    excelRow['wcTextN22'] = trim(el['WC TEXT N 22']);
    excelRow['wcTextO22'] = trim(el['WC TEXT O 22']);
    excelRow['wcItemNo23'] = trim(el['WC ITEM NO 23']);
    excelRow['wcCd23'] = trim(el['WARRANTY/CLAUSE CODE 23']);
    excelRow['wcTextA23'] = trim(el['WC TEXT A 23']);
    excelRow['wcTextB23'] = trim(el['WC TEXT B 23']);
    excelRow['wcTextC23'] = trim(el['WC TEXT C 23']);
    excelRow['wcTextD23'] = trim(el['WC TEXT D 23']);
    excelRow['wcTextE23'] = trim(el['WC TEXT E 23']);
    excelRow['wcTextF23'] = trim(el['WC TEXT F 23']);
    excelRow['wcTextG23'] = trim(el['WC TEXT G 23']);
    excelRow['wcTextH23'] = trim(el['WC TEXT H 23']);
    excelRow['wcTextI23'] = trim(el['WC TEXT I 23']);
    excelRow['wcTextJ23'] = trim(el['WC TEXT J 23']);
    excelRow['wcTextK23'] = trim(el['WC TEXT K 23']);
    excelRow['wcTextL23'] = trim(el['WC TEXT L 23']);
    excelRow['wcTextM23'] = trim(el['WC TEXT M 23']);
    excelRow['wcTextN23'] = trim(el['WC TEXT N 23']);
    excelRow['wcTextO23'] = trim(el['WC TEXT O 23']);
    excelRow['wcItemNo24'] = trim(el['WC ITEM NO 24']);
    excelRow['wcCd24'] = trim(el['WARRANTY/CLAUSE CODE 24']);
    excelRow['wcTextA24'] = trim(el['WC TEXT A 24']);
    excelRow['wcTextB24'] = trim(el['WC TEXT B 24']);
    excelRow['wcTextC24'] = trim(el['WC TEXT C 24']);
    excelRow['wcTextD24'] = trim(el['WC TEXT D 24']);
    excelRow['wcTextE24'] = trim(el['WC TEXT E 24']);
    excelRow['wcTextF24'] = trim(el['WC TEXT F 24']);
    excelRow['wcTextG24'] = trim(el['WC TEXT G 24']);
    excelRow['wcTextH24'] = trim(el['WC TEXT H 24']);
    excelRow['wcTextI24'] = trim(el['WC TEXT I 24']);
    excelRow['wcTextJ24'] = trim(el['WC TEXT J 24']);
    excelRow['wcTextK24'] = trim(el['WC TEXT K 24']);
    excelRow['wcTextL24'] = trim(el['WC TEXT L 24']);
    excelRow['wcTextM24'] = trim(el['WC TEXT M 24']);
    excelRow['wcTextN24'] = trim(el['WC TEXT N 24']);
    excelRow['wcTextO24'] = trim(el['WC TEXT O 24']);
    excelRow['wcItemNo25'] = trim(el['WC ITEM NO 25']);
    excelRow['wcCd25'] = trim(el['WARRANTY/CLAUSE CODE 25']);
    excelRow['wcTextA25'] = trim(el['WC TEXT A 25']);
    excelRow['wcTextB25'] = trim(el['WC TEXT B 25']);
    excelRow['wcTextC25'] = trim(el['WC TEXT C 25']);
    excelRow['wcTextD25'] = trim(el['WC TEXT D 25']);
    excelRow['wcTextE25'] = trim(el['WC TEXT E 25']);
    excelRow['wcTextF25'] = trim(el['WC TEXT F 25']);
    excelRow['wcTextG25'] = trim(el['WC TEXT G 25']);
    excelRow['wcTextH25'] = trim(el['WC TEXT H 25']);
    excelRow['wcTextI25'] = trim(el['WC TEXT I 25']);
    excelRow['wcTextJ25'] = trim(el['WC TEXT J 25']);
    excelRow['wcTextK25'] = trim(el['WC TEXT K 25']);
    excelRow['wcTextL25'] = trim(el['WC TEXT L 25']);
    excelRow['wcTextM25'] = trim(el['WC TEXT M 25']);
    excelRow['wcTextN25'] = trim(el['WC TEXT N 25']);
    excelRow['wcTextO25'] = trim(el['WC TEXT O 25']);
    excelRow['wcItemNo26'] = trim(el['WC ITEM NO 26']);
    excelRow['wcCd26'] = trim(el['WARRANTY/CLAUSE CODE 26']);
    excelRow['wcTextA26'] = trim(el['WC TEXT A 26']);
    excelRow['wcTextB26'] = trim(el['WC TEXT B 26']);
    excelRow['wcTextC26'] = trim(el['WC TEXT C 26']);
    excelRow['wcTextD26'] = trim(el['WC TEXT D 26']);
    excelRow['wcTextE26'] = trim(el['WC TEXT E 26']);
    excelRow['wcTextF26'] = trim(el['WC TEXT F 26']);
    excelRow['wcTextG26'] = trim(el['WC TEXT G 26']);
    excelRow['wcTextH26'] = trim(el['WC TEXT H 26']);
    excelRow['wcTextI26'] = trim(el['WC TEXT I 26']);
    excelRow['wcTextJ26'] = trim(el['WC TEXT J 26']);
    excelRow['wcTextK26'] = trim(el['WC TEXT K 26']);
    excelRow['wcTextL26'] = trim(el['WC TEXT L 26']);
    excelRow['wcTextM26'] = trim(el['WC TEXT M 26']);
    excelRow['wcTextN26'] = trim(el['WC TEXT N 26']);
    excelRow['wcTextO26'] = trim(el['WC TEXT O 26']);
    excelRow['wcItemNo27'] = trim(el['WC ITEM NO 27']);
    excelRow['wcCd27'] = trim(el['WARRANTY/CLAUSE CODE 27']);
    excelRow['wcTextA27'] = trim(el['WC TEXT A 27']);
    excelRow['wcTextB27'] = trim(el['WC TEXT B 27']);
    excelRow['wcTextC27'] = trim(el['WC TEXT C 27']);
    excelRow['wcTextD27'] = trim(el['WC TEXT D 27']);
    excelRow['wcTextE27'] = trim(el['WC TEXT E 27']);
    excelRow['wcTextF27'] = trim(el['WC TEXT F 27']);
    excelRow['wcTextG27'] = trim(el['WC TEXT G 27']);
    excelRow['wcTextH27'] = trim(el['WC TEXT H 27']);
    excelRow['wcTextI27'] = trim(el['WC TEXT I 27']);
    excelRow['wcTextJ27'] = trim(el['WC TEXT J 27']);
    excelRow['wcTextK27'] = trim(el['WC TEXT K 27']);
    excelRow['wcTextL27'] = trim(el['WC TEXT L 27']);
    excelRow['wcTextM27'] = trim(el['WC TEXT M 27']);
    excelRow['wcTextN27'] = trim(el['WC TEXT N 27']);
    excelRow['wcTextO27'] = trim(el['WC TEXT O 27']);
    excelRow['wcItemNo28'] = trim(el['WC ITEM NO 28']);
    excelRow['wcCd28'] = trim(el['WARRANTY/CLAUSE CODE 28']);
    excelRow['wcTextA28'] = trim(el['WC TEXT A 28']);
    excelRow['wcTextB28'] = trim(el['WC TEXT B 28']);
    excelRow['wcTextC28'] = trim(el['WC TEXT C 28']);
    excelRow['wcTextD28'] = trim(el['WC TEXT D 28']);
    excelRow['wcTextE28'] = trim(el['WC TEXT E 28']);
    excelRow['wcTextF28'] = trim(el['WC TEXT F 28']);
    excelRow['wcTextG28'] = trim(el['WC TEXT G 28']);
    excelRow['wcTextH28'] = trim(el['WC TEXT H 28']);
    excelRow['wcTextI28'] = trim(el['WC TEXT I 28']);
    excelRow['wcTextJ28'] = trim(el['WC TEXT J 28']);
    excelRow['wcTextK28'] = trim(el['WC TEXT K 28']);
    excelRow['wcTextL28'] = trim(el['WC TEXT L 28']);
    excelRow['wcTextM28'] = trim(el['WC TEXT M 28']);
    excelRow['wcTextN28'] = trim(el['WC TEXT N 28']);
    excelRow['wcTextO28'] = trim(el['WC TEXT O 28']);
    excelRow['wcItemNo29'] = trim(el['WC ITEM NO 29']);
    excelRow['wcCd29'] = trim(el['WARRANTY/CLAUSE CODE 29']);
    excelRow['wcTextA29'] = trim(el['WC TEXT A 29']);
    excelRow['wcTextB29'] = trim(el['WC TEXT B 29']);
    excelRow['wcTextC29'] = trim(el['WC TEXT C 29']);
    excelRow['wcTextD29'] = trim(el['WC TEXT D 29']);
    excelRow['wcTextE29'] = trim(el['WC TEXT E 29']);
    excelRow['wcTextF29'] = trim(el['WC TEXT F 29']);
    excelRow['wcTextG29'] = trim(el['WC TEXT G 29']);
    excelRow['wcTextH29'] = trim(el['WC TEXT H 29']);
    excelRow['wcTextI29'] = trim(el['WC TEXT I 29']);
    excelRow['wcTextJ29'] = trim(el['WC TEXT J 29']);
    excelRow['wcTextK29'] = trim(el['WC TEXT K 29']);
    excelRow['wcTextL29'] = trim(el['WC TEXT L 29']);
    excelRow['wcTextM29'] = trim(el['WC TEXT M 29']);
    excelRow['wcTextN29'] = trim(el['WC TEXT N 29']);
    excelRow['wcTextO29'] = trim(el['WC TEXT O 29']);
    excelRow['wcItemNo30'] = trim(el['WC ITEM NO 30']);
    excelRow['wcCd30'] = trim(el['WARRANTY/CLAUSE CODE 30']);
    excelRow['wcTextA30'] = trim(el['WC TEXT A 30']);
    excelRow['wcTextB30'] = trim(el['WC TEXT B 30']);
    excelRow['wcTextC30'] = trim(el['WC TEXT C 30']);
    excelRow['wcTextD30'] = trim(el['WC TEXT D 30']);
    excelRow['wcTextE30'] = trim(el['WC TEXT E 30']);
    excelRow['wcTextF30'] = trim(el['WC TEXT F 30']);
    excelRow['wcTextG30'] = trim(el['WC TEXT G 30']);
    excelRow['wcTextH30'] = trim(el['WC TEXT H 30']);
    excelRow['wcTextI30'] = trim(el['WC TEXT I 30']);
    excelRow['wcTextJ30'] = trim(el['WC TEXT J 30']);
    excelRow['wcTextK30'] = trim(el['WC TEXT K 30']);
    excelRow['wcTextL30'] = trim(el['WC TEXT L 30']);
    excelRow['wcTextM30'] = trim(el['WC TEXT M 30']);
    excelRow['wcTextN30'] = trim(el['WC TEXT N 30']);
    excelRow['wcTextO30'] = trim(el['WC TEXT O 30']);
    excelRow['wcItemNo31'] = trim(el['WC ITEM NO 31']);
    excelRow['wcCd31'] = trim(el['WARRANTY/CLAUSE CODE 31']);
    excelRow['wcItemNo32'] = trim(el['WC ITEM NO 32']);
    excelRow['wcCd32'] = trim(el['WARRANTY/CLAUSE CODE 32']);
    excelRow['wcItemNo33'] = trim(el['WC ITEM NO 33']);
    excelRow['wcCd33'] = trim(el['WARRANTY/CLAUSE CODE 33']);
    excelRow['wcItemNo34'] = trim(el['WC ITEM NO 34']);
    excelRow['wcCd34'] = trim(el['WARRANTY/CLAUSE CODE 34']);
    excelRow['wcItemNo35'] = trim(el['WC ITEM NO 35']);
    excelRow['wcCd35'] = trim(el['WARRANTY/CLAUSE CODE 35']);
    excelRow['wcItemNo36'] = trim(el['WC ITEM NO 36']);
    excelRow['wcCd36'] = trim(el['WARRANTY/CLAUSE CODE 36']);
    excelRow['wcItemNo37'] = trim(el['WC ITEM NO 37']);
    excelRow['wcCd37'] = trim(el['WARRANTY/CLAUSE CODE 37']);
    excelRow['wcItemNo38'] = trim(el['WC ITEM NO 38']);
    excelRow['wcCd38'] = trim(el['WARRANTY/CLAUSE CODE 38']);
    excelRow['wcItemNo39'] = trim(el['WC ITEM NO 39']);
    excelRow['wcCd39'] = trim(el['WARRANTY/CLAUSE CODE 39']);
    excelRow['wcItemNo40'] = trim(el['WC ITEM NO 40']);
    excelRow['wcCd40'] = trim(el['WARRANTY/CLAUSE CODE 40']);
    excelRow['wcItemNo41'] = trim(el['WC ITEM NO 41']);
    excelRow['wcCd41'] = trim(el['WARRANTY/CLAUSE CODE 41']);
    excelRow['wcItemNo42'] = trim(el['WC ITEM NO 42']);
    excelRow['wcCd42'] = trim(el['WARRANTY/CLAUSE CODE 42']);
    excelRow['wcItemNo43'] = trim(el['WC ITEM NO 43']);
    excelRow['wcCd43'] = trim(el['WARRANTY/CLAUSE CODE 43']);
    excelRow['wcItemNo44'] = trim(el['WC ITEM NO 44']);
    excelRow['wcCd44'] = trim(el['WARRANTY/CLAUSE CODE 44']);
    excelRow['wcItemNo45'] = trim(el['WC ITEM NO 45']);
    excelRow['wcCd45'] = trim(el['WARRANTY/CLAUSE CODE 45']);
    excelRow['wcItemNo46'] = trim(el['WC ITEM NO 46']);
    excelRow['wcCd46'] = trim(el['WARRANTY/CLAUSE CODE 46']);
    excelRow['wcItemNo47'] = trim(el['WC ITEM NO 47']);
    excelRow['wcCd47'] = trim(el['WARRANTY/CLAUSE CODE 47']);
    excelRow['wcItemNo48'] = trim(el['WC ITEM NO 48']);
    excelRow['wcCd48'] = trim(el['WARRANTY/CLAUSE CODE 48']);
    excelRow['wcItemNo49'] = trim(el['WC ITEM NO 49']);
    excelRow['wcCd49'] = trim(el['WARRANTY/CLAUSE CODE 49']);
    excelRow['wcItemNo50'] = trim(el['WC ITEM NO 50']);
    excelRow['wcCd50'] = trim(el['WARRANTY/CLAUSE CODE 50']);
    excelRow['wcItemNo51'] = trim(el['WC ITEM NO 51']);
    excelRow['wcCd51'] = trim(el['WARRANTY/CLAUSE CODE 51']);
    excelRow['wcItemNo52'] = trim(el['WC ITEM NO 52']);
    excelRow['wcCd52'] = trim(el['WARRANTY/CLAUSE CODE 52']);
    excelRow['wcItemNo53'] = trim(el['WC ITEM NO 53']);
    excelRow['wcCd53'] = trim(el['WARRANTY/CLAUSE CODE 53']);
    excelRow['wcItemNo54'] = trim(el['WC ITEM NO 54']);
    excelRow['wcCd54'] = trim(el['WARRANTY/CLAUSE CODE 54']);
    excelRow['wcItemNo55'] = trim(el['WC ITEM NO 55']);
    excelRow['wcCd55'] = trim(el['WARRANTY/CLAUSE CODE 55']);
    excelRow['wcItemNo56'] = trim(el['WC ITEM NO 56']);
    excelRow['wcCd56'] = trim(el['WARRANTY/CLAUSE CODE 56']);
    excelRow['wcItemNo57'] = trim(el['WC ITEM NO 57']);
    excelRow['wcCd57'] = trim(el['WARRANTY/CLAUSE CODE 57']);
    excelRow['wcItemNo58'] = trim(el['WC ITEM NO 58']);
    excelRow['wcCd58'] = trim(el['WARRANTY/CLAUSE CODE 58']);
    excelRow['wcItemNo59'] = trim(el['WC ITEM NO 59']);
    excelRow['wcCd59'] = trim(el['WARRANTY/CLAUSE CODE 59']);
    excelRow['wcItemNo60'] = trim(el['WC ITEM NO 60']);
    excelRow['wcCd60'] = trim(el['WARRANTY/CLAUSE CODE 60']);
    excelRow['wcItemNo61'] = trim(el['WC ITEM NO 61']);
    excelRow['wcCd61'] = trim(el['WARRANTY/CLAUSE CODE 61']);
    excelRow['wcItemNo62'] = trim(el['WC ITEM NO 62']);
    excelRow['wcCd62'] = trim(el['WARRANTY/CLAUSE CODE 62']);
    excelRow['wcItemNo63'] = trim(el['WC ITEM NO 63']);
    excelRow['wcCd63'] = trim(el['WARRANTY/CLAUSE CODE 63']);
    excelRow['wcItemNo64'] = trim(el['WC ITEM NO 64']);
    excelRow['wcCd64'] = trim(el['WARRANTY/CLAUSE CODE 64']);
    excelRow['wcItemNo65'] = trim(el['WC ITEM NO 65']);
    excelRow['wcCd65'] = trim(el['WARRANTY/CLAUSE CODE 65']);
    excelRow['wcItemNo66'] = trim(el['WC ITEM NO 66']);
    excelRow['wcCd66'] = trim(el['WARRANTY/CLAUSE CODE 66']);
    excelRow['wcItemNo67'] = trim(el['WC ITEM NO 67']);
    excelRow['wcCd67'] = trim(el['WARRANTY/CLAUSE CODE 67']);
    excelRow['wcItemNo68'] = trim(el['WC ITEM NO 68']);
    excelRow['wcCd68'] = trim(el['WARRANTY/CLAUSE CODE 68']);
    excelRow['wcItemNo69'] = trim(el['WC ITEM NO 69']);
    excelRow['wcCd69'] = trim(el['WARRANTY/CLAUSE CODE 69']);
    excelRow['wcItemNo70'] = trim(el['WC ITEM NO 70']);
    excelRow['wcCd70'] = trim(el['WARRANTY/CLAUSE CODE 70']);
    excelRow['wcItemNo71'] = trim(el['WC ITEM NO 71']);
    excelRow['wcCd71'] = trim(el['WARRANTY/CLAUSE CODE 71']);
    excelRow['wcItemNo72'] = trim(el['WC ITEM NO 72']);
    excelRow['wcCd72'] = trim(el['WARRANTY/CLAUSE CODE 72']);
    excelRow['wcItemNo73'] = trim(el['WC ITEM NO 73']);
    excelRow['wcCd73'] = trim(el['WARRANTY/CLAUSE CODE 73']);
    excelRow['wcItemNo74'] = trim(el['WC ITEM NO 74']);
    excelRow['wcCd74'] = trim(el['WARRANTY/CLAUSE CODE 74']);
    excelRow['wcItemNo75'] = trim(el['WC ITEM NO 75']);
    excelRow['wcCd75'] = trim(el['WARRANTY/CLAUSE CODE 75']);
    excelRow['wcItemNo76'] = trim(el['WC ITEM NO 76']);
    excelRow['wcCd76'] = trim(el['WARRANTY/CLAUSE CODE 76']);
    excelRow['wcItemNo77'] = trim(el['WC ITEM NO 77']);
    excelRow['wcCd77'] = trim(el['WARRANTY/CLAUSE CODE 77']);
    excelRow['wcItemNo78'] = trim(el['WC ITEM NO 78']);
    excelRow['wcCd78'] = trim(el['WARRANTY/CLAUSE CODE 78']);
    excelRow['wcItemNo79'] = trim(el['WC ITEM NO 79']);
    excelRow['wcCd79'] = trim(el['WARRANTY/CLAUSE CODE 79']);
    excelRow['wcItemNo80'] = trim(el['WC ITEM NO 80']);
    excelRow['wcCd80'] = trim(el['WARRANTY/CLAUSE CODE 80']);
    excelRow['wcItemNo81'] = trim(el['WC ITEM NO 81']);
    excelRow['wcCd81'] = trim(el['WARRANTY/CLAUSE CODE 81']);
    excelRow['wcItemNo82'] = trim(el['WC ITEM NO 82']);
    excelRow['wcCd82'] = trim(el['WARRANTY/CLAUSE CODE 82']);
    excelRow['wcItemNo83'] = trim(el['WC ITEM NO 83']);
    excelRow['wcCd83'] = trim(el['WARRANTY/CLAUSE CODE 83']);
    excelRow['wcItemNo84'] = trim(el['WC ITEM NO 84']);
    excelRow['wcCd84'] = trim(el['WARRANTY/CLAUSE CODE 84']);
    excelRow['wcItemNo85'] = trim(el['WC ITEM NO 85']);
    excelRow['wcCd85'] = trim(el['WARRANTY/CLAUSE CODE 85']);
    excelRow['wcItemNo86'] = trim(el['WC ITEM NO 86']);
    excelRow['wcCd86'] = trim(el['WARRANTY/CLAUSE CODE 86']);
    excelRow['wcItemNo87'] = trim(el['WC ITEM NO 87']);
    excelRow['wcCd87'] = trim(el['WARRANTY/CLAUSE CODE 87']);
    excelRow['wcItemNo88'] = trim(el['WC ITEM NO 88']);
    excelRow['wcCd88'] = trim(el['WARRANTY/CLAUSE CODE 88']);
    excelRow['wcItemNo89'] = trim(el['WC ITEM NO 89']);
    excelRow['wcCd89'] = trim(el['WARRANTY/CLAUSE CODE 89']);
    excelRow['wcItemNo90'] = trim(el['WC ITEM NO 90']);
    excelRow['wcCd90'] = trim(el['WARRANTY/CLAUSE CODE 90']);
    excelRow['wcItemNo91'] = trim(el['WC ITEM NO 91']);
    excelRow['wcCd91'] = trim(el['WARRANTY/CLAUSE CODE 91']);
    excelRow['wcItemNo92'] = trim(el['WC ITEM NO 92']);
    excelRow['wcCd92'] = trim(el['WARRANTY/CLAUSE CODE 92']);
    excelRow['wcItemNo93'] = trim(el['WC ITEM NO 93']);
    excelRow['wcCd93'] = trim(el['WARRANTY/CLAUSE CODE 93']);
    excelRow['wcItemNo94'] = trim(el['WC ITEM NO 94']);
    excelRow['wcCd94'] = trim(el['WARRANTY/CLAUSE CODE 94']);
    excelRow['wcItemNo95'] = trim(el['WC ITEM NO 95']);
    excelRow['wcCd95'] = trim(el['WARRANTY/CLAUSE CODE 95']);
    excelRow['wcItemNo96'] = trim(el['WC ITEM NO 96']);
    excelRow['wcCd96'] = trim(el['WARRANTY/CLAUSE CODE 96']);
    excelRow['renewalText1'] = trim(el['RENEWAL TEXT 1']);
    excelRow['renewalText2'] = trim(el['RENEWAL TEXT 2']);
    excelRow['renewalText3'] = trim(el['RENEWAL TEXT 3']);
    excelRow['renewalText4'] = trim(el['RENEWAL TEXT 4']);
    excelRow['renewalText5'] = trim(el['RENEWAL TEXT 5']);
    excelRow['renewalText6'] = trim(el['RENEWAL TEXT 6']);
    excelRow['renewalText7'] = trim(el['RENEWAL TEXT 7']);
    excelRow['renewalText8'] = trim(el['RENEWAL TEXT 8']);
    excelRow['renewalText9'] = trim(el['RENEWAL TEXT 9']);
    excelRow['renewalText10'] = trim(el['RENEWAL TEXT 10']);
    excelRow['renewalText11'] = trim(el['RENEWAL TEXT 11']);
    excelRow['renewalText12'] = trim(el['RENEWAL TEXT 12']);
    excelRow['renewalText13'] = trim(el['RENEWAL TEXT 13']);
    excelRow['renewalText14'] = trim(el['RENEWAL TEXT 14']);
    excelRow['renewalText15'] = trim(el['RENEWAL TEXT 15']);
    excelRow['renewalText16'] = trim(el['RENEWAL TEXT 16']);
    excelRow['renewalText17'] = trim(el['RENEWAL TEXT 17']);
    excelRow['renewalText18'] = trim(el['RENEWAL TEXT 18']);
    excelRow['renewalText19'] = trim(el['RENEWAL TEXT 19']);
    excelRow['renewalText20'] = trim(el['RENEWAL TEXT 20']);
    excelRow['renewalText21'] = trim(el['RENEWAL TEXT 21']);
    excelRow['renewalText22'] = trim(el['RENEWAL TEXT 22']);
    excelRow['renewalText23'] = trim(el['RENEWAL TEXT 23']);
    excelRow['renewalText24'] = trim(el['RENEWAL TEXT 24']);
    excelRow['renewalText25'] = trim(el['RENEWAL TEXT 25']);
    excelRow['riskName'] = trim(el['RISK NAME']);
    excelRow['cityMun'] = trim(el['CITY MUN']);
    excelRow['districtCd'] = trim(el['DISTRICT CD']);
    excelRow['blockDesc'] = trim(el['BLOCK DESC']);
    excelRow['tariffCode'] = trim(el['TARIFF CODE']);
    excelRow['construction'] = trim(el['CONSTRUCTION']);
    excelRow['constructionDesc1'] = trim(el['CONSTRUCTION DESC 1']);
    excelRow['constructionDesc2'] = trim(el['CONSTRUCTION DESC 2']);
    excelRow['constructionDesc3'] = trim(el['CONSTRUCTION DESC 3']);
    excelRow['constructionDesc4'] = trim(el['CONSTRUCTION DESC 4']);
    excelRow['constructionDesc5'] = trim(el['CONSTRUCTION DESC 5']);
    excelRow['occupancy'] = trim(el['OCCUPANCY']);
    excelRow['occupancyDesc1'] = trim(el['OCCUPANCY DESC 1']);
    excelRow['occupancyDesc2'] = trim(el['OCCUPANCY DESC 2']);
    excelRow['occupancyDesc3'] = trim(el['OCCUPANCY DESC 3']);
    excelRow['occupancyDesc4'] = trim(el['OCCUPANCY DESC 4']);
    excelRow['occupancyDesc5'] = trim(el['OCCUPANCY DESC 5']);
    excelRow['otherRiskDtl'] = trim(el['OTHER RISK DTL']);
    excelRow['bankCd'] = trim(el['BANK CD']);
    excelRow['interestedParty'] = trim(el['INTERESTED PARTY']);
    excelRow['itemRemarks'] = trim(el['ITEM REMARKS']);
    excelRow['fiItemNo1'] = trim(el['FI ITEM NO 1']);
    excelRow['fiItemTitle1'] = trim(el['FI ITEM TITLE 1']);
    excelRow['fiPremClass1'] = trim(el['FI PREM CLASS 1']);
    excelRow['fiItemNo2'] = trim(el['FI ITEM NO 2']);
    excelRow['fiItemTitle2'] = trim(el['FI ITEM TITLE 2']);
    excelRow['fiPremClass2'] = trim(el['FI PREM CLASS 2']);
    excelRow['fiItemNo3'] = trim(el['FI ITEM NO 3']);
    excelRow['fiItemTitle3'] = trim(el['FI ITEM TITLE 3']);
    excelRow['fiPremClass3'] = trim(el['FI PREM CLASS 3']);
    excelRow['fiItemNo4'] = trim(el['FI ITEM NO 4']);
    excelRow['fiItemTitle4'] = trim(el['FI ITEM TITLE 4']);
    excelRow['fiPremClass4'] = trim(el['FI PREM CLASS 4']);
    excelRow['fiItemNo5'] = trim(el['FI ITEM NO 5']);
    excelRow['fiItemTitle5'] = trim(el['FI ITEM TITLE 5']);
    excelRow['fiPremClass5'] = trim(el['FI PREM CLASS 5']);
    excelRow['dependentLname1'] = trim(el['DEPENDENT LNAME 1']);
    excelRow['dependentFname1'] = trim(el['DEPENDENT FNAME 1']);
    excelRow['dependentMname1'] = trim(el['DEPENDENT MNAME 1']);
    excelRow['dependentSuffix1'] = trim(el['DEPENDENT SUFFIX 1']);
    excelRow['depRelationship1'] = trim(el['DEP RELATIONSHIP 1']);
    excelRow['depNationality1'] = trim(el['DEP NATIONALITY 1']);
    excelRow['depBirthdate1'] = el['DEP BIRTHDATE 1'] !== null && el['DEP BIRTHDATE 1'] !== '' && el['DEP BIRTHDATE 1'] !== undefined ? dateToDateString(serialNumberToDate(el['DEP BIRTHDATE 1'])) : null;
    excelRow['depGender1'] = trim(el['DEP GENDER 1']);
    excelRow['depAge1'] = trim(el['DEP AGE 1']);
    excelRow['dependentLname2'] = trim(el['DEPENDENT LNAME 2']);
    excelRow['dependentFname2'] = trim(el['DEPENDENT FNAME 2']);
    excelRow['dependentMname2'] = trim(el['DEPENDENT MNAME 2']);
    excelRow['dependentSuffix2'] = trim(el['DEPENDENT SUFFIX 2']);
    excelRow['depRelationship2'] = trim(el['DEP RELATIONSHIP 2']);
    excelRow['depNationality2'] = trim(el['DEP NATIONALITY 2']);
    excelRow['depBirthdate2'] = el['DEP BIRTHDATE 2'] !== null && el['DEP BIRTHDATE 2'] !== '' && el['DEP BIRTHDATE 2'] !== undefined ? dateToDateString(serialNumberToDate(el['DEP BIRTHDATE 2'])) : null;
    excelRow['depGender2'] = trim(el['DEP GENDER 2']);
    excelRow['depAge2'] = trim(el['DEP AGE 2']);
    excelRow['dependentLname3'] = trim(el['DEPENDENT LNAME 3']);
    excelRow['dependentFname3'] = trim(el['DEPENDENT FNAME 3']);
    excelRow['dependentMname3'] = trim(el['DEPENDENT MNAME 3']);
    excelRow['dependentSuffix3'] = trim(el['DEPENDENT SUFFIX 3']);
    excelRow['depRelationship3'] = trim(el['DEP RELATIONSHIP 3']);
    excelRow['depNationality3'] = trim(el['DEP NATIONALITY 3']);
    excelRow['depBirthdate3'] = el['DEP BIRTHDATE 3'] !== null && el['DEP BIRTHDATE 3'] !== '' && el['DEP BIRTHDATE 3'] !== undefined ? dateToDateString(serialNumberToDate(el['DEP BIRTHDATE 3'])) : null;
    excelRow['depGender3'] = trim(el['DEP GENDER 3']);
    excelRow['depAge3'] = trim(el['DEP AGE 3']);
    excelRow['dependentLname4'] = trim(el['DEPENDENT LNAME 4']);
    excelRow['dependentFname4'] = trim(el['DEPENDENT FNAME 4']);
    excelRow['dependentMname4'] = trim(el['DEPENDENT MNAME 4']);
    excelRow['dependentSuffix4'] = trim(el['DEPENDENT SUFFIX 4']);
    excelRow['depRelationship4'] = trim(el['DEP RELATIONSHIP 4']);
    excelRow['depNationality4'] = trim(el['DEP NATIONALITY 4']);
    excelRow['depBirthdate4'] = el['DEP BIRTHDATE 4'] !== null && el['DEP BIRTHDATE 4'] !== '' && el['DEP BIRTHDATE 4'] !== undefined ? dateToDateString(serialNumberToDate(el['DEP BIRTHDATE 4'])) : null;
    excelRow['depGender4'] = trim(el['DEP GENDER 4']);
    excelRow['depAge4'] = trim(el['DEP AGE 4']);
    excelRow['dependentLname5'] = trim(el['DEPENDENT LNAME 5']);
    excelRow['dependentFname5'] = trim(el['DEPENDENT FNAME 5']);
    excelRow['dependentMname5'] = trim(el['DEPENDENT MNAME 5']);
    excelRow['dependentSuffix5'] = trim(el['DEPENDENT SUFFIX 5']);
    excelRow['depRelationship5'] = trim(el['DEP RELATIONSHIP 5']);
    excelRow['depNationality5'] = trim(el['DEP NATIONALITY 5']);
    excelRow['depBirthdate5'] = el['DEP BIRTHDATE 5'] !== null && el['DEP BIRTHDATE 5'] !== '' && el['DEP BIRTHDATE 5'] !== undefined ? dateToDateString(serialNumberToDate(el['DEP BIRTHDATE 5'])) : null;
    excelRow['depGender5'] = trim(el['DEP GENDER 5']);
    excelRow['depAge5'] = trim(el['DEP AGE 5']);
    excelRow['beneficiaryLname1'] = trim(el['BENEFICIARY LNAME 1']);
    excelRow['beneficiaryFname1'] = trim(el['BENEFICIARY FNAME 1']);
    excelRow['beneficiaryMname1'] = trim(el['BENEFICIARY MNAME 1']);
    excelRow['beneficiarySuffix1'] = trim(el['BENEFICIARY SUFFIX 1']);
    excelRow['benRelationship1'] = trim(el['BEN RELATIONSHIP 1']);
    excelRow['benNationality1'] = trim(el['BEN NATIONALITY 1']);
    excelRow['benBirthdate1'] = el['BEN BIRTHDATE 1'] !== null && el['BEN BIRTHDATE 1'] !== '' && el['BEN BIRTHDATE 1'] !== undefined ? dateToDateString(serialNumberToDate(el['BEN BIRTHDATE 1'])) : null;
    excelRow['benGender1'] = trim(el['BEN GENDER 1']);
    excelRow['benAge1'] = trim(el['BEN AGE 1']);
    excelRow['beneficiaryLname2'] = trim(el['BENEFICIARY LNAME 2']);
    excelRow['beneficiaryFname2'] = trim(el['BENEFICIARY FNAME 2']);
    excelRow['beneficiaryMname2'] = trim(el['BENEFICIARY MNAME 2']);
    excelRow['beneficiarySuffix2'] = trim(el['BENEFICIARY SUFFIX 2']);
    excelRow['benRelationship2'] = trim(el['BEN RELATIONSHIP 2']);
    excelRow['benNationality2'] = trim(el['BEN NATIONALITY 2']);
    excelRow['benBirthdate2'] = el['BEN BIRTHDATE 2'] !== null && el['BEN BIRTHDATE 2'] !== '' && el['BEN BIRTHDATE 2'] !== undefined ? dateToDateString(serialNumberToDate(el['BEN BIRTHDATE 2'])) : null;
    excelRow['benGender2'] = trim(el['BEN GENDER 2']);
    excelRow['benAge2'] = trim(el['BEN AGE 2']);
    excelRow['beneficiaryLname3'] = trim(el['BENEFICIARY LNAME 3']);
    excelRow['beneficiaryFname3'] = trim(el['BENEFICIARY FNAME 3']);
    excelRow['beneficiaryMname3'] = trim(el['BENEFICIARY MNAME 3']);
    excelRow['beneficiarySuffix3'] = trim(el['BENEFICIARY SUFFIX 3']);
    excelRow['benRelationship3'] = trim(el['BEN RELATIONSHIP 3']);
    excelRow['benNationality3'] = trim(el['BEN NATIONALITY 3']);
    excelRow['benBirthdate3'] = el['BEN BIRTHDATE 3'] !== null && el['BEN BIRTHDATE 3'] !== '' && el['BEN BIRTHDATE 3'] !== undefined ? dateToDateString(serialNumberToDate(el['BEN BIRTHDATE 3'])) : null;
    excelRow['benGender3'] = trim(el['BEN GENDER 3']);
    excelRow['benAge3'] = trim(el['BEN AGE 3']);
    excelRow['beneficiaryLname4'] = trim(el['BENEFICIARY LNAME 4']);
    excelRow['beneficiaryFname4'] = trim(el['BENEFICIARY FNAME 4']);
    excelRow['beneficiaryMname4'] = trim(el['BENEFICIARY MNAME 4']);
    excelRow['beneficiarySuffix4'] = trim(el['BENEFICIARY SUFFIX 4']);
    excelRow['benRelationship4'] = trim(el['BEN RELATIONSHIP 4']);
    excelRow['benNationality4'] = trim(el['BEN NATIONALITY 4']);
    excelRow['benBirthdate4'] = el['BEN BIRTHDATE 4'] !== null && el['BEN BIRTHDATE 4'] !== '' && el['BEN BIRTHDATE 4'] !== undefined ? dateToDateString(serialNumberToDate(el['BEN BIRTHDATE 4'])) : null;
    excelRow['benGender4'] = trim(el['BEN GENDER 4']);
    excelRow['benAge4'] = trim(el['BEN AGE 4']);
    excelRow['beneficiaryLname5'] = trim(el['BENEFICIARY LNAME 5']);
    excelRow['beneficiaryFname5'] = trim(el['BENEFICIARY FNAME 5']);
    excelRow['beneficiaryMname5'] = trim(el['BENEFICIARY MNAME 5']);
    excelRow['beneficiarySuffix5'] = trim(el['BENEFICIARY SUFFIX 5']);
    excelRow['benRelationship5'] = trim(el['BEN RELATIONSHIP 5']);
    excelRow['benNationality5'] = trim(el['BEN NATIONALITY 5']);
    excelRow['benBirthdate5'] = el['BEN BIRTHDATE 5'] !== null && el['BEN BIRTHDATE 5'] !== '' && el['BEN BIRTHDATE 5'] !== undefined ? dateToDateString(serialNumberToDate(el['BEN BIRTHDATE 5'])) : null;
    excelRow['benGender5'] = trim(el['BEN GENDER 5']);
    excelRow['benAge5'] = trim(el['BEN AGE 5']);
    excelRow['planCd'] = trim(el['PLAN CODE']);
    excelRow['currencyCd'] = trim(el['CURRENCY']);
    excelRow['currencyRt'] = trim(el['CURRENCY RATE']);
    excelRow['mvFileNo'] = trim(el['MV FILE NO']);
    excelRow['perilToSum'] = trim(el['PRC_CONCAT']);
    excelRow['itemToSum'] = trim(el['DSEQNO']);

    // this.excelRows.push(excelRow);
    this.excelRow = excelRow;
    this.proceedUpload();

    errorMessage = "";
    // }

    // sub functions
    function serialNumberToDate(serial: any) {
      if(typeof(serial) == "string") {
        // dd/mm/yyyy
        let d = serial.split('/');

        return new Date(d[1] + '/' + d[0] + '/' + d[2]);
      } else if(typeof(serial.getMonth) === 'function') {
        // let converted = 25569.0 + ((serial.getTime() - (serial.getTimezoneOffset() * 60 * 1000)) / (1000 * 60 * 60 * 24));
        return new Date(serial - (new Date().getTimezoneOffset() * 60000));

        // Converts Serial Number from excel to Date
        // var utcDays  = Math.floor(converted - 25569);
        // var utcValue = utcDays * 86400;
        // var dateInfo = new Date(utcValue * 1000);

        // var fractionalDay = converted - Math.floor(converted) + 0.0000001;

        // var totalSeconds = Math.floor(86400 * fractionalDay);

        // var seconds = totalSeconds % 60;

        // totalSeconds -= seconds;

        // var hours = Math.floor(totalSeconds / (60 * 60));
        // var minutes = Math.floor(totalSeconds / 60) % 60;

        // return new Date(dateInfo.getFullYear(), dateInfo.getMonth(), dateInfo.getDate(), hours, minutes, seconds);
      } else {
        return "Invalid Date";
      }
    }

    function dateToDateString(date: any) {
      if(date.toString() == "Invalid Date") {
        return date.toString();
      }

      // Returns date string with MM/DD/YYYY format
      let newDate = ((date.getMonth() + 1).toString().padStart(2, '0') + '/' + date.getDate().toString().padStart(2, '0') + '/' + date.getFullYear());
      return newDate;
    }

    function isValidStringDate(dateString: string) {
      // Accepted format is MM/DD/YYYY

      // First check for the pattern
      if(!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
        return false;

      // Parse the date parts to integers
      var parts = dateString.split("/");
      var day = parseInt(parts[1], 10);
      var month = parseInt(parts[0], 10);
      var year = parseInt(parts[2], 10);

      // Check the ranges of month and year
      if(year < 1000 || year > 3000 || month == 0 || month > 12)
        return false;

      var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

      // Adjust for leap years
      if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
          monthLength[1] = 29;

      // Check the range of the day
      return day > 0 && day <= monthLength[month - 1];
    }

    function trim(val: string) {
      let x = val;
      let res: any = null;

      if(x !== undefined && x !== null) {
        if(String(x).trim().length == 0) {
          res = null;
        } else {
          res = String(x).trim();
        }
      } else {
        res = val;
      }

      return res;
    }
    // end sub functions

  }

  proceedUpload() {
    this.excelRow['uploadId'] = this.uploadId == 0 ? null : this.uploadId;

    try {
      this.apiCallService.saveUploadRenewalData(
        this.excelRow
      ).subscribe((response: any) => {
        this.securityService.checkRequestKeyResponse(response, () => {
            response = JSON.parse(this.jsonDataService.decrypt(response.content));

            if (response.status == "SUCCESS") {
              this.uploadId = response.uploadId == null ? 0 : response.uploadId;
              this.uploadCtr++;
              if(this.uploadCtr < this.excelData.length) {
                this.goToNextRow();
              }

              if(this.uploadCtr == this.excelData.length) {
                this.fileName = this.fileUpload.value.file.name;
                this.fileUpload.patchValue({file: ""});
                this.fileInput.nativeElement.value = "";

                this.logs = [];

                this.getLogs({
                  uploadId: this.uploadId,
                  processId: null,
                  first: 0
                });
              }
            } else {
              this.uploadCtr++;
              if(this.uploadCtr < this.excelData.length) {
                this.goToNextRow();
              }
            }
        });
      });
    } catch(error) {
      console.log(error);

      this.uploadCtr++;
      if(this.uploadCtr < this.excelData.length) {
        this.goToNextRow();
      }
    }
  }

  totalLogs: number = 0;
  logs: any = [];

  getLogs(prm: any) {
    this.apiCallService.getRenewalProcessLogs({
      uploadId: prm.uploadId,
      processId: prm.processId,
      first: prm.first,
      rows: 100,
      moduleId: this.moduleId,
      type: "MODULE"
    }).subscribe((data: any) => {
      this.securityService.checkRequestKeyResponse(data, () => {
        data['content'] = JSON.parse(this.jsonDataService.decrypt(data.content));
        this.totalLogs += data['content']['data'].length;

        if(this.logs.length == 0) {
          this.logs = data['content']['data'];
        } else if(this.logs.length > 0) {
          this.logs = this.logs.concat(data['content']['data']);
        }

        if(this.totalLogs < data['content']['count']) {
          this.getLogs({
            uploadId: prm.uploadId,
            processId: prm.processId,
            first: this.logs.length
          });
        } else {
          if(prm.uploadId == null) {
            if(!this.view) {
              this.setSelectedStatus(this.selectedStatus, 1);
              this.lazyLoad({
                first: this.tblConfig.tblData[0]['rownum_'] - 1,
                globalFilter: this.tbl.filterText
              });
            }
            this.saveRenewalData = [];
            this.processList = [];
            this.excludeProcessList = [];
            this.saveCtr = 0;
            this.totalBulkRecords = 0;
          } else if(prm.processId == null) {
            this.clearTblFilter();
          }

          if(this.logs.length > 0) {
            if(this.view) {
              this.jsonDataService.contorlLoading(false);
            }

            this.dialogContent = this.constructLogs(this.logs, prm.uploadId == null ? 'p' : 'u');
            this.openLogsDialog();
          } else {
            if(this.view) {
              this.jsonDataService.contorlLoading(false);
            }

            const dialogRole = this.dialog.open(DialogMsgComponent, {
              disableClose: true,
              width: "500px",
              data: {
                title: 'SUCCESS',
                content: prm.uploadId == null ? 'Successfully sent notice' : 'Upload successful'
              }
            });
          }
        }
      });
    });
  }

  showSendButton: boolean = true;
  showUpdateButton: boolean = true;

  getUploadedRenewalData(prm?: any) {
    let qryPrm: any = {};

    if(prm) {
      qryPrm = {
        first: prm.first,
        rows: prm.rows,
        search: prm.search,
        sortBy: prm.sortBy,
        orderBy: prm.orderBy,
        column: prm.column
      }
    }

    this.showSendButton = ['forProcessing', 'sentRenewalNotice', 'forwardedToAgent', 'failedSending'].includes(this.selectedStatus);
    this.showUpdateButton = !['cancelled', 'expired', 'renewed', 'all'].includes(this.selectedStatus);

    this.tblConfig.loading = true;
    this.resetForm();
    this.jsonDataService.contorlLoading(true);
    this.apiCallService.getUploadRenewalData({
        status: this.selectedStatus,
        first: qryPrm.first,
        rows: qryPrm.rows,
        search: qryPrm.search,
        sortBy: qryPrm.sortBy,
        orderBy: qryPrm.orderBy,
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE",
        isGetAddtlData: this.isGetAddtlData,
        column: qryPrm.column
    }).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          if (data.status === 'SUCCESS') {
            data['content'] = JSON.parse(this.jsonDataService.decrypt(data.content));

            if(this.isGetAddtlData){
              this.jsonDataService.updateAddtlData(data?.content?.jsonData || {});
              // this.options.agent = this.jsonDataService.data.agent;
              this.options.bma = [{bmCode: null, bmaDesc: ''}].concat(this.jsonDataService.data.bma);
              this.options.referror = [{bmCode: null, referrorDesc: ''}].concat(this.jsonDataService.data.referror);
              this.isGetAddtlData = false;
            }

            this.jsonDataService.contorlLoading(false);
            this.tblConfig.loading = false;

            var rows = [];
            rows = data['content']['data'];
            this.tblConfig.totalRecords = data['content']['count'];
            this.tblConfig.pageLinks = 10;
            this.tblConfig.cols[1].selectAll = this.showSendButton; // process select all
            this.tblConfig.cols[1].checked = this.processAllTag;

            for (let i = 0; i < rows.length; i++) {
              const row = rows[i];

              row.disabledFields = [];
              if(row['REN_STAT_CD'] == 5 ||
                row['REN_STAT_CD'] == 7 ||
                row['REN_STAT_CD'] == 9) {
                row.disabledFields = [
                  'PROCESS_TAG',
                  'BALANCE_TAG',
                  'TOTAL_LOSS_TAG',
                  'BLOCKED_TAG'
                ];
              }

              if(row['REN_STAT_CD'] == 5 ||
               row['REN_STAT_CD'] == 7) {
                row.disabledFields.push('PURGE_TAG');
              }


              if(row['REN_STAT_CD'] == 6 ||
               row['REN_STAT_CD'] == 3) {
                row.disabledFields = [
                  'PROCESS_TAG'
                ];
              }

              if(![1, 2, 3, 6, 9].includes(row['REN_STAT_CD']) ||
               this.selectedStatus == 'all') {
                row.disabledFields.push('PURGE_TAG');
                this.tblConfig.cols[this.tblConfig.cols.length-1].selectAll = false;
              } else {
                this.tblConfig.cols[this.tblConfig.cols.length-1].selectAll = true;
              }

              if([4, 8].includes(row['REN_STAT_CD'])) {
                row.disabledFields.push('BALANCE_TAG');
              }

              if(this.selectedStatus == 'all') {
                row.disabledFields = [
                  'PROCESS_TAG',
                  'BALANCE_TAG',
                  'TOTAL_LOSS_TAG',
                  'BLOCKED_TAG',
                  'PURGE_TAG'
                ];
              }

              if(this.selectedStatus == 'sentRenewalNotice' ||
               this.selectedStatus == 'forwardedToAgent') {
                row['PROCESS_TAG'] = 'N';
              }

              if(this.selectedStatus == 'forProcessing' ||
               this.selectedStatus == 'sentRenewalNotice' ||
               this.selectedStatus == 'forwardedToAgent') {

                // prod fix
                // row['PROCESS_TAG'] = this.processAllTag || (this.processList.filter((a: any) => a.RENEWAL_ID == row['RENEWAL_ID']).length !== 0) ? 'Y' : 'N';
                row['PROCESS_TAG'] = ((this.processAllTag || (this.processList.filter((a: any) => a.RENEWAL_ID == row['RENEWAL_ID']).length !== 0)) && this.excludeProcessList.filter((a: any) => a.RENEWAL_ID == row['RENEWAL_ID']).length == 0) ? 'Y' : 'N';
                // prod fix

              }
            }

            this.tblConfig.tblData = data['content']['data'];

                // if(this.tbl) {
                //   this.tbl.addFiller();
                // }
          } else {
            this.jsonDataService.contorlLoading(false);
            this.tblConfig.loading = false;
            this.appMessageService.showAppMessage(data.message, "error");
          }
        });
    });
  }

  tagSrc: string = '';
  tagVal: boolean = false;
  dlgBalanceAmt: string = '';
  dlgBlockedReason: string = '';
  singlePurge: boolean = true;
  tblFilterText: string = '';
  tblPage: number = 0;
  processList: any = [];
  excludeProcessList: any = [];

  rowEvent(data: any) {
    this.singlePurge = true;
    if(data.src && data.src == 'PURGE_TAG') {
      this.rowEventData = data['rowData'];

      if(this.rowEventData.PURGE_TAG == 'N') {
        const dialogRole = this.dialog.open(this.purgeDialog, {
          width: "500px",
          disableClose: true
        });
      }

      data['rowData'].edited = true;
    } else if(data.src && data.src == 'PROCESS_TAG') {
      data['rowData'].edited = true;
      if (data['rowData'].PROCESS_TAG == 'N') {
        this.processList = this.processList.filter((a: any) => a.RENEWAL_ID != data['rowData'].RENEWAL_ID);
        this.processList.push(data['rowData']);

        if(this.processAllTag) {
          this.excludeProcessList = this.excludeProcessList.filter((a: any) => a.RENEWAL_ID != data['rowData'].RENEWAL_ID);
        }
      } else if (data['rowData'].PROCESS_TAG == 'Y') {
        this.processList = this.processList.filter((a: any) => a.RENEWAL_ID != data['rowData'].RENEWAL_ID);

        if(this.processAllTag) {
          this.excludeProcessList = this.excludeProcessList.filter((a: any) => a.RENEWAL_ID != data['rowData'].RENEWAL_ID);
          this.excludeProcessList.push(data['rowData']);
        }
      }
    } else if(data.src && data.src == 'BALANCE_TAG'
        || data.src && data.src == 'TOTAL_LOSS_TAG'
        || data.src && data.src == 'BLOCKED_TAG') {

      this.tagVal = data['rowData'][data.src] == 'Y';

      this.tagSrc = data.src;
      this.rowEventData = data['rowData'];

      const dialogRole = this.dialog.open(this.tagDialog, {
        width: "500px",
        disableClose: true
      });

      data['rowData'].edited = true;
    } else if(data.src == undefined) { // VIEW BUTTON
      this.seeMorePropertyInfo = false;
      this.seeMorePaInfo = false;

      this.seeMoreConstruction = false;
      this.seeMoreOccupancy = false;
      this.seeMoreOtherRiskDtl = false;
      this.seeMoreInterestedParty = false;
      this.seeMoreRiskEndtWc = false;

      this.tblFilterText = this.tbl.filterText;
      this.tblPage = this.tbl.first;
      this.tbl.tbl.saveState();

      var param = {
        renewalId: data['rowData']['RENEWAL_ID'],
        moduleId: this.moduleId,
        type: "MODULE"
      };

      this.jsonDataService.contorlLoading(true);
      this.apiCallService.getRenewalData(param).subscribe((data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
                data = JSON.parse(this.jsonDataService.decrypt(data.content));
                this.view = true;
                this.jsonDataService.contorlLoading(false);

                var r = data['renewalData'][0][0];

                var wcLink = data['renewalData'][2].filter((a: any) => (
                  a['LINE_CD'] == r['LINE_CD']
                  && a['SUBLINE_CD'] == r['SUBLINE_CD']
                  && a['PLAN_CD'] == r['PLAN_CD']
                  && a['LINK_NO'] == 1
                )).map((b: any) => {
                  return {
                    linkName: b['LINK_NAME'],
                    linkPath: b['LINK_PATH']
                  }
                })[0];

                var hyperlink = '';
                var host = data['renewalData'][3][0]['HOST'];
                var wcText = '';

                if(wcLink) {
                  if (wcLink.linkPath.includes("https://")) {
                    hyperlink = `<a target="_blank" href='${wcLink.linkPath}'>${wcLink.linkName}</a>`;
                  } else {
                    hyperlink = `<a target="_blank" href='https://${host}${wcLink.linkPath.substring(1)}'>${wcLink.linkName}</a>`;
                  }

                  wcText = '\n\n\n' + data['renewalData'][3][0]['LINK_TEXT'].split(wcLink.linkName).join(hyperlink);
                }

                let taxes = data['renewalData'][1].map((a: any) => {
                  let obj = {};
                  for (let index = 0; index < this.jsonDataService.data.tax.length; index++) {
                    const el = this.jsonDataService.data.tax[index];

                    if(el.taxCd == a.taxCd) {
                      obj = {
                        taxCd: a.taxCd,
                        taxName: el.taxName,
                        value: a.taxAmt
                      }

                      break;
                    }
                  }

                  return obj;
                });

                this.disableViewSendNotice = [3, 5, 6, 7, 9].includes(r['REN_STAT_CD']);
                var p = data['renewalPerilData'][0].map((a: any) => {
                  return {
                      amountCovered: a['AMOUNT_COVERED'],
                      calcMethod: a['CALC_METHOD'],
                      commAmt: a['COMM_AMT'],
                      commRt: a['COMM_RT'],
                      docStamp: a['DOC_STAMP'],
                      fst: a['FST'],
                      grossPrem: a['GROSS_PREM'],
                      itemNo: a['ITEM_NO'],
                      lgt: a['LGT'],
                      netPrem: a['NET_PREM'],
                      otherTax: a['OTHER_TAX'],
                      perilCode: a['PERIL_CODE'],
                      perilLname: a['PERIL_LNAME'],
                      perilRt: a['PERIL_RT'],
                      premTax: a['PREM_TAX'],
                      renewalId: a['RENEWAL_ID'],
                      uploadId: a['UPLOAD_ID'],
                      vat: a['VAT']
                  }
                });

                var pFi = data['renewalPerilData'][1].map((a: any) => {
                  return {
                    itemNo: a['ITEM_NO'],
                    itemTitle: a['ITEM_TITLE'],
                    totalPrem: a['TOTAL_PREM'],
                  }
                });

                var pad = data['renewalPaData']['renewalPaDepData'].map((a: any) => {
                return {
                    depAge: a['DEP_AGE'],
                    depBirthdate: a['DEP_BIRTHDATE'],
                    depGenderCd: a['DEP_GENDER_CD'],
                    depGenderDesc: a['DEP_GENDER_DESC'],
                    depItemRemarks: a['DEP_ITEM_REMARKS'],
                    depName: a['DEP_NAME'],
                    depNationalityCd: a['DEP_NATIONALITY_CD'],
                    depNationalityDesc: a['DEP_NATIONALITY_DESC'],
                    depRelationshipCd: a['DEP_RELATIONSHIP_CD'],
                    depRelationshipDesc: a['DEP_RELATIONSHIP_DESC']
                }
                });

                var pab = data['renewalPaData']['renewalPaBenData'].map((a: any) => {
                return {
                    benAge: a['BEN_AGE'],
                    benBirthdate: a['BEN_BIRTHDATE'],
                    benGenderCd: a['BEN_GENDER_CD'],
                    benGenderDesc: a['BEN_GENDER_DESC'],
                    benItemRemarks: a['BEN_ITEM_REMARKS'],
                    benName: a['BEN_NAME'],
                    benNationalityCd: a['BEN_NATIONALITY_CD'],
                    benNationalityDesc: a['BEN_NATIONALITY_DESC'],
                    benRelationshipCd: a['BEN_RELATIONSHIP_CD'],
                    benRelationshipDesc: a['BEN_RELATIONSHIP_DESC']
                }
                });

                this.renewalData = {
                    uploadId: r['UPLOAD_ID'],
                    renewalId: r['RENEWAL_ID'],
                    assdFirstName: r['ASSD_FIRST_NAME'],
                    assdLastName: r['ASSD_LAST_NAME'],
                    assdMiddleName: r['ASSD_MIDDLE_NAME'],
                    birthCountry: r['BIRTH_COUNTRY'],
                    birthDate: r['BIRTH_DATE'],
                    category: r['CATEGORY'],
                    categoryDesc: r['CATEGORY_DESC'],
                    ccWt: r['CC_WT'],
                    chassisNo: r['CHASSIS_NO'],
                    color01: r['COLOR_01'],
                    completeAddress: r['COMPLETE_ADDRESS'],
                    contractTypeDesc: r['CONTRACT_TYPE_DESC'],
                    countryName: r['COUNTRY_NAME'],
                    coverageTypeCd: r['COVERAGE_TYPE_CD'],
                    displayName: r['DISPLAY_NAME'],
                    effDate: r['EFF_DATE'],
                    email: r['EMAIL'],
                    engineNo: r['ENGINE_NO'],
                    expiryDate: r['EXPIRY_DATE'],
                    fuelDesc: r['FUEL_DESC'],
                    fuelType: r['FUEL_TYPE'],
                    gender: r['GENDER'],
                    genderDesc: r['GENDER_DESC'],
                    idNo01: r['ID_NO_01'],
                    idNo02: r['ID_NO_02'],
                    idType01: r['ID_TYPE_01'],
                    idType02: r['ID_TYPE_02'],
                    idType01Desc: r['ID_TYPE_01_DESC'],
                    idType02Desc: r['ID_TYPE_02_DESC'],
                    lineCd: r['LINE_CD'],
                    sublineCd: r['SUBLINE_CD'],
                    menuLineCd: r['MENU_LINE_CD'],
                    makeModel: r['MAKE_MODEL'],
                    makeTypeCd: r['MAKE_TYPE_CD'],
                    maritalDesc: r['MARITAL_DESC'],
                    maritalStatus: r['MARITAL_STATUS'],
                    mobileNo: r['MOBILE_NO'],
                    nationality: r['NATIONALITY'],
                    nationalityDesc: r['NATIONALITY_DESC'],
                    noOfSeats: r['NO_OF_SEATS'],
                    oldEffDate: r['OLD_EFF_DATE'],
                    oldExpiryDate: r['OLD_EXPIRY_DATE'],
                    planName: r['PLAN_NAME'],
                    planName2: r['PLAN_NAME_2'],
                    plateNo: r['PLATE_NO'],
                    policyNo: r['POLICY_NO'],
                    postalCd: r['POSTAL_CD'],
                    postalDesc: r['POSTAL_DESC'],
                    provCity: r['PROV_CITY'],
                    provCityDesc: r['PROV_CITY_DESC'],
                    renStatCd: r['REN_STAT_CD'],
                    renStatDesc: r['REN_STAT_DESC'],
                    riskProfile: r['RISK_PROFILE'],
                    riskProfDesc: r['RISK_PROF_DESC'],
                    seatsCovered: r['SEATS_COVERED'],
                    subModel: r['SUB_MODEL'],
                    telNo: r['TEL_NO'],
                    tin: r['TIN'],
                    veTypeDesc: r['VE_TYPE_DESC'],
                    veTypeTag: r['VE_TYPE_TAG'],
                    yearModel: r['YEAR_MODEL'],
                    deductibleAmt: r['DEDUCTIBLE_AMT'],
                    towingLimit: r['TOWING_LIMIT'],
                    repairLimit: r['REPAIR_LIMIT'],
                    totalGrossPrem: r['TOTAL_GROSS_PREM'],
                    totalDocStamp: r['TOTAL_DOC_STAMP'],
                    totalVat: r['TOTAL_VAT'],
                    totalLgt: r['TOTAL_LGT'],
                    totalAmt: r['TOTAL_AMT'],
                    totalCommAmt: r['TOTAL_COMM_AMT'],
                    totalNetPrem: r['TOTAL_NET_PREM'],
                    paytRefNo: r['PAYT_REF_NO'],
                    paytTranNo: r['PAYT_TRAN_NO'],
                    paytMode: r['PAYT_MODE'],
                    paytDate: r['PAYT_DATE'],
                    agentCd: r['AGENT_CD'],
                    agentName: r['AGENT_NAME'],
                    srcCd: r['SRC_CD'],
                    srcCdDesc: r['SRC_CD_DESC'],
                    srcExtCd: r['SRC_EXT_CD'],
                    srcExtDesc: r['SRC_EXT_DESC'],
                    bmaCd: r['BMA_CD'],
                    bmaDesc: r['BMA_DESC'],
                    referrorCd: r['REFERROR_CD'],
                    referrorDesc: r['REFERROR_DESC'],
                    projectCd: r['PROJECT_CD'],
                    projectDesc: r['PROJECT_DESC'],
                    clientCd: r['CLIENT_GRP'],
                    clientDesc: r['CLIENT_DESC'],
                    riskNo: r['RISK_NO'],
                    riskName: r['RISK_NAME'],
                    cityMun: r['CITY_MUN'],
                    cityDesc: r['CITY_DESC'],
                    districtCd: r['DISTRICT_CD'],
                    districtDesc: r['DISTRICT_DESC'],
                    blockDesc: r['BLOCK_DESC'],
                    tariffCode: r['TARIFF_CD'],
                    constructionCd: r['CONSTRUCTION_CD'],
                    constructionDesc: r['CONSTRUCTION_DESC'],
                    occupancyCd: r['OCCUPANCY_CD'],
                    occupancyDesc: r['OCCUPANCY_DESC'],
                    fireOtherRiskDtl: r['FIRE_OTHER_RISK_DTL'],
                    bankCd: r['BANK_CD'],
                    bankName: r['BANK_NAME'],
                    riskEndtWc: r['RISK_ENDT_WC'],
                    wcLink: wcText,
                    assdAge: r['ASSD_AGE'],
                    occupation: r['OCCUPATION'],
                    occupationDesc: r['OCCUPATION_DESC'],
                    paOtherRiskDtl: r['PA_OTHER_RISK_DTL'],
                    interestedParty: r['INTERESTED_PARTY'],
                    insuredRisk: r['INSURED_RISK'],
                    balanceAmt: r['BALANCE_AMT'],
                    remarks: r['REMARKS'],
                    address01: r['ADDRESS_01'],
                    address02: r['ADDRESS_02'],
                    address03: r['ADDRESS_03'],
                    address04: r['ADDRESS_04'],
                    taxes: taxes,
                    renewalPerilData: p,
                    renewalPerilFiData: pFi,
                    renewalPaData: {
                        renewalPaDepData: pad,
                        renewalPaBenData: pab
                    }
                };

                this.setCoverages("", "");
            });
      });
    }
  }

  fiCoverages: any[] = [];

  setCoverages(sortColumn:string, key:string):void {
    if(this.renewalData.menuLineCd == 'FI') {
      this.fiCoverages = [];
      this.renewalData.renewalPerilFiData.forEach((item: any) => {
        let arr = this.renewalData.renewalPerilData.filter((a: any) => a.itemNo == item.itemNo);

        if (!arr[arr.length - 1].perilCode) {
          arr.pop();
        }

        arr.push({
          perilLname: "Item Premium",
          tsiAmt: null,
          premRt: null,
          grossPrem: item.totalPrem
        });

        this.fiCoverages.push(arr);
      });

      // this.fiCoverages[this.fiCoverages.length-1].push({
      //   perilLname: "TOTAL PREMIUM",
      //   tsiAmt: null,
      //   premRt: null,
      //   grossPrem: this.renewalData.totalGrossPrem
      // });
    } else {
      this.coverages = this.renewalData.renewalPerilData;

      if (!this.coverages[this.coverages.length - 1].perilCode) {
          this.coverages.pop();
      }

      this.sortCoverages(sortColumn, key);
      this.coverages.push({
        perilLname: "Total Premium",
        tsiAmt: null,
        premRt: null,
        grossPrem: this.renewalData.totalGrossPrem
      });
    }

    // const perils = this.jsonDataService.data.peril.filter((a:any) => a.lineCd === this.renewalData.lineCd);
    // this.coverages = this.renewalData.renewalPerilData.map((a:any) => {
    //   return {
    //     ...a
    //   }
    // });
  }

  sortCoverages(sortColumn:string, key:string):void {
    if (sortColumn) {
      if (this.lastSortedColumn === sortColumn) {
          this.sorting = this.sorting === "ASC" ? "DESC" : "ASC";
      } else {
          this.lastSortedColumn = sortColumn;
          this.sorting = "ASC";
      }

      this.coverages.sort((a, b) => {
        if (this.sorting === "ASC") {
            return this.compareStrings(a[key], b[key]);
        } else {
            return this.compareStrings(b[key], a[key]);
        }
      });
    }
  }

  compareStrings(a:any, b:any):number {
    if (typeof a === "string") {
      a = a.toLowerCase();
      b = b.toLowerCase();
    }

    return (a < b) ? -1 : (a > b) ? 1 : 0;
  }

  onClickBack() {
    this.view = false;
    this.renewalData = null;
    window.scrollTo({ top: 0, behavior: 'smooth' });

    setTimeout(() => {
      if(this.tblFilterText !== '') {
        this.tbl.filterText = this.tblFilterText;
        this.tbl.filter();
      }

      this.tbl.tbl.restoreState();
    }, 0);
  }

  clearTblFilter() {
    this.tbl?.clearFilter();
  }

  downloadLogs(data: any[]) {
    var element = document.createElement('a');
    var dlFileName = 'pol_ren_logs';
    var list = '';

    for (let i = 0; i < data.length; i++) {
      const el = data[i];

      list += el['POLICY_NO'] + '\n' + el['ERROR_MSG'] + '\n\n';
    }

    var content = 'Upload User: ' + this.retrievalParams.userId + '\n' +
                  'File Name: ' + dlFileName + '\n' +
                  'Upload Date: ' + new Date() + '\n\n' +
                  'Uploading failed for the following: \n\n' +
                  list;

    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
    element.setAttribute('download', dlFileName);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  downloadLogsContent() {
    var element = document.createElement('a');
    var dlFileName = 'pol_ren_logs';

    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.dialogContent));
    element.setAttribute('download', dlFileName);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  constructLogs(data: any[], type?: any, headers?: any[]) {
    var dlFileName = 'pol_ren_logs' + (type == 'u' ? '_upload' : '_process');
    var list = '';

    for (let i = 0; i < data.length; i++) {
      const el = data[i];

      list += el['POLICY_NO'] + '\n' + (el['REMARKS'] == null ? '' : el['REMARKS'] + '\n' ) + el['ERROR_MSG'] + '\n\n';
    }

    if(headers !== undefined) {
      for (let i = 0; i < headers.length; i++) {
        const el = headers[i];

        list += el + '\n';
      }
    }

    return 'User: ' + this.retrievalParams.userId + '\n' +
                  'File Name: ' + dlFileName + '\n' +
                  (type == 'u' ? 'Upload' : 'Process') + ' Date: ' + new Date() + '\n\n' +
                  (type == 'u' ? 'Uploading' : 'Processing') +
                  (headers == undefined ? ' failed for the following: ' : ' failed.\nDuplicate column headers found in the upload file.') + '\n\n' +
                  list;
  }

  openLogsDialog() {
    const dialogRole = this.dialog.open(this.logsDialog, {
      disableClose: true,
      width: "650px"
    });
  }

  onRowClick(data: any) {
    this.selectedRow = data;

    this.enableForm(data !== null);

    let provCity = null;

    if(data !== null) {
      provCity = this.options.provCity.filter((a: any) => a.value == data['PC_CITY_CD'] && a.countryCd == data['PC_COUNTRY_CD'] && a.provCd == data['PC_PROVINCE_CD'])[0];

      this.options.agent = [{bmCode: null, agentName: ''}].concat(this.jsonDataService.data.agent.filter((a: any) => a.issCd == data['ISS_CD']));
      this.options.srcExt = [{bmCode: null, srcExtDesc: ''}].concat(this.jsonDataService.data.srcExt.filter((a: any) => a.issCd == data['ISS_CD']));

      var srcExtCd = this.jsonDataService.data.srcExt.filter((a: any) => a.issCd == data['ISS_CD'] && a.bmCode == data['SRC_EXT_CD'])[0]?.srcExtCd;
      this.options.referror = [{bmCode: null, referrorDesc: ''}].concat(this.jsonDataService.data.referror);
    }

    this.updateRenewal.patchValue({
      balanceAmt: data == null ? "" : this.formService.setToAmountFormat(data['BALANCE_AMT']),
      blockedReason: data == null ? "" : data['BLOCKED_REASON'],
      remarks: data == null ? "" : data['REMARKS'],
      email: data == null ? "" : data['EMAIL'],
      mobileNo: data == null ? "" : data['MOBILE_NO'],
      telNo: data == null ? "" : data['TEL_NO'],
      maritalStatus: data == null ? "" : data['MARITAL_STATUS'],
      address01: data == null ? "" : data['ADDRESS_01'],
      address02: data == null ? "" : data['ADDRESS_02'],
      address03: data == null ? "" : data['ADDRESS_03'],
      address04: data == null ? "" : data['ADDRESS_04'],
      provCity: data == null || provCity == undefined ? "" : provCity,
      birthCountry: data == null ? "" : data['COUNTRY_CD'], // BIRTH_COUNTRY
      postalCd: data == null ? "" : data['POSTAL_CD'],
      agent: data == null ? "" : data['AGENT_CD'],
      bma: data == null ? "" : data['BMA_CD'],
      src: data == null ? "" : data['SRC_CD'],
      srcExt: data == null ? null : data['SRC_EXT_CD'],
      referror: data == null ? "" : data['REFERROR_CD'],
      project: data == null ? "" : data['PROJECT_CD'],
      client: data == null ? "" : data['CLIENT_GRP']
    });

    if(data !== null) {
      // if(data['REN_STAT_CD'] == 5 || // renewed
      //    data['REN_STAT_CD'] == 7 || // cancelled
      //    data['REN_STAT_CD'] == 9 || // expired
      //    this.selectedStatus == 'all') {
      //   this.enableForm(false);
      // } else if() {

      // } else {
      //   this.enableForm(true);
      // }

      if(data['REN_STAT_CD'] == 5 || // renewed
         data['REN_STAT_CD'] == 7 || // cancelled
         data['REN_STAT_CD'] == 9 || // expired
         this.selectedStatus == 'all') {
        this.enableForm(false);
      } else {
        this.enableForm(true);
      }

      if(this.selectedStatus == 'sentRenewalNotice'
       || this.selectedStatus == 'forwardedToAgent'
       || [5, 7, 9].includes(data['REN_STAT_CD'])
       || this.selectedStatus == 'all') {
        this.updateRenewal.controls["balanceAmt"].disable();
      } else {
        this.updateRenewal.controls["balanceAmt"].enable();
      }

      let y = this.updateRenewal.getRawValue();

      if(y.provCity == '' || y.provCity == null || this.selectedStatus == 'all') {
        this.updateRenewal.controls["postalCd"].disable();
      } else {
        this.updateRenewal.controls["postalCd"].enable();
      }

      if(this.selectedStatus == 'forProcessing') {
        this.updateRenewal.controls["agent"].enable();
        this.updateRenewal.controls["bma"].enable();
        this.updateRenewal.controls["src"].enable();
        this.updateRenewal.controls["srcExt"].enable();
        this.updateRenewal.controls["referror"].enable();
        this.updateRenewal.controls["project"].enable();
        this.updateRenewal.controls["client"].enable();
      } else {
        this.updateRenewal.controls["agent"].disable();
        this.updateRenewal.controls["bma"].disable();
        this.updateRenewal.controls["src"].disable();
        this.updateRenewal.controls["srcExt"].disable();
        this.updateRenewal.controls["referror"].disable();
        this.updateRenewal.controls["project"].disable();
        this.updateRenewal.controls["client"].disable();
      }

      if(data['SRC_EXT_CD'] == null) {
        this.updateRenewal.controls["referror"].disable();
      }

    }
  }

  enablePostalCd() {
    this.updateRenewal.controls["postalCd"].enable();
  }

  onClickUpdate() {
    if(this.updateRenewal.valid) {
      const form = this.updateRenewal.getRawValue();

      var val = {
        uploadId: this.selectedRow.UPLOAD_ID,
        renewalId: this.selectedRow.RENEWAL_ID,
        renStatCd: this.selectedRow.REN_STAT_CD,
        processTag: this.selectedRow.PROCESS_TAG,
        balanceTag: this.selectedRow.BALANCE_TAG,
        totalLossTag: this.selectedRow.TOTAL_LOSS_TAG,
        blockedTag: this.selectedRow.BLOCKED_TAG,
        balanceAmt: this.formService.setToNumberWithDecimal(form.balanceAmt),
        blockedReason: form.blockedReason,
        remarks: form.remarks,
        email: form.email,
        mobileNo: form.mobileNo,
        purgeTag: this.selectedRow.PURGE_TAG,
        telNo: form.telNo,
        maritalStatus: form.maritalStatus,
        address01: form.address01,
        address02: form.address02,
        address03: form.address03,
        address04: form.address04,
        birthCountry: form.birthCountry,
        postalCd: form.postalCd,
        lastUser: this.retrievalParams.userId,
        cityCd: form.provCity?.value,
        countryCd: form.provCity?.countryCd,
        provinceCd: form.provCity?.provCd,
        agent: form.agent,
        bma: form.bma,
        src: form.src,
        srcExt: form.srcExt,
        referror: form.referror,
        project: form.project,
        client: form.client
      }

      this.saveRenewalData.push(val);

      this.save();

      this.resetForm();
      this.enableForm(false);
      this.selectedRow = null;
    }
  }

  resetForm() {
    this.updateRenewal.patchValue({
      balanceAmt: "",
      blockedReason: "",
      remarks: "",
      email: "",
      mobileNo: "",
      telNo: "",
      maritalStatus: "",
      address01: "",
      address02: "",
      address03: "",
      address04: "",
      provCity: "",
      birthCountry: "",
      postalCd: "",
      agent: "",
      bma: "",
      src: "",
      srcExt: null,
      referror: "",
      project: "",
      client: ""
    });
  }

  onClickSave() {
    var edited = this.tblConfig.tblData.filter((x: any) => x.edited).map((a: any) => {
      return {
        uploadId: a.UPLOAD_ID,
        renewalId: a.RENEWAL_ID,
        renStatCd: a.REN_STAT_CD,
        processTag: a.PROCESS_TAG,
        balanceTag: a.BALANCE_TAG,
        totalLossTag: a.TOTAL_LOSS_TAG,
        blockedTag: a.BLOCKED_TAG,
        purgeTag: a.PURGE_TAG,
        balanceAmt: a.BALANCE_TAG,
        blockedReason: a.BLOCKED_REASON,
        remarks: a.REMARKS,
        email: a.EMAIL,
        mobileNo: a.MOBILE_NO,
        telNo: a.TEL_NO,
        maritalStatus: a.MARITAL_STATUS,
        address01: a.ADDRESS_01,
        address02: a.ADDRESS_02,
        address03: a.ADDRESS_03,
        address04: a.ADDRESS_04,
        provCity: a.PROV_CITY,
        birthCountry: a.BIRTH_COUNTRY,
        postalCd: a.POSTAL_CD,
        lastUser: this.retrievalParams.userId,
        agent: a.AGENT_CD,
        bma: a.BMA_CD,
        src: a.SRC_CD,
        srcExt: a.SRC_EXT_CD,
        referror: a.REFERROR_CD,
        project: a.PROJECT_CD,
        client: a.CLIENT_GRP
      }
    });

    var exists = false;
    for (let i = 0; i < edited.length; i++) {
      const el = edited[i];

      loop2:
      for (let j = 0; j < this.saveRenewalData.length; j++) {
        const el2 = this.saveRenewalData[j];
        exists = false;
        if(el.uploadId == el2.uploadId &&
           el.renewalId == el2.renewalId) {
          el2.processTag = el.processTag;
          el2.balanceTag = el.balanceTag;
          el2.totalLossTag = el.totalLossTag;
          el2.blockedTag = el.blockedTag;
          el2.purgeTag = el.purgeTag;

          exists = true;
          break loop2;
        }
      }

      if(!exists) {
        this.saveRenewalData.push(el);
      }
    }

    var proceed = true;
    for (let i = 0; i < this.saveRenewalData.length; i++) {
      const el = this.saveRenewalData[i];

      // if(el.balanceTag == 'Y' && el.balanceAmt == 0) {
      //   this.openDialog({
      //     dlgTitle: 'Warning',
      //     dlgContent: 'Balance of records with checked Balance Tag cannot be zero.'
      //   });

      //   proceed = false;
      //   break;
      // }

      if(el.blockedTag == 'Y' && (el.blockedReason == null ||  el.blockedReason.trim() == "")) {
        this.openDialog({
          dlgTitle: 'Warning',
          dlgContent: 'Records with checked Blocked Tag must have a Blocking Reason.'
        });

        this.saveRenewalData = [];
        proceed = false;
        break;
      }
    }

    if(proceed) {
      this.save();
    }
  }

  saveCtr: number = 0;

  async save(process?: boolean) {
    this.jsonDataService.contorlLoading(true);

    if(process) {
      if(this.view) {
        this.saveRenewalData = [
          {
            uploadId: this.renewalData.uploadId,
            renewalId: this.renewalData.renewalId,
            lineCd: this.renewalData.lineCd,
            sublineCd: this.renewalData.sublineCd,
            menuLineCd: this.renewalData.menuLineCd,
            renStatCd: 4,
            processTag: '',
            balanceTag: '',
            totalLossTag: '',
            blockedTag: '',
            balanceAmt: this.renewalData.balanceAmt,
            blockedReason: '',
            remarks: this.renewalData.remarks,
            mobileNo: this.renewalData.mobileNo,
            purgeTag: '',
            telNo: this.renewalData.telNo,
            maritalStatus: this.renewalData.maritalStatus,
            address01: this.renewalData.address01,
            address02: this.renewalData.address02,
            address03: this.renewalData.address03,
            address04: this.renewalData.address04,
            provCity: this.renewalData.provCity,
            birthCountry: this.retrievalParams.countryCd,
            postalCd: this.renewalData.postalCd,
            lastUser: this.retrievalParams.userId,
            policyNo: this.renewalData.policyNo,
            oldExpiryDate: this.renewalData.oldExpiryDate,
            email: this.renewalData.email,
            assdFirstName: this.renewalData.assdFirstName,
            insuredRisk: this.renewalData.insuredRisk,
            processFlag: true,
            planName: this.renewalData.planName2,
            agent: this.renewalData.agentCd,
            bma: this.renewalData.bmaCd,
            src: this.renewalData.srcCd,
            srcExt: this.renewalData.srcExtCd,
            referror: this.renewalData.referrorCd,
            project: this.renewalData.projectCd,
            client: this.renewalData.clientCd,
            paytRefNo: this.renewalData.paytRefNo
          }
        ]
      } else {
        // this.saveRenewalData = this.tblConfig.tblData.filter((a: any) => a.PROCESS_TAG == 'Y' && [1,2,4,8].includes(a.REN_STAT_CD)).map((b: any) => {

        if(this.processAllTag) {
          if(this.tblConfig.totalRecords == 0) {
            this.jsonDataService.contorlLoading(false);

            this.openDialog({
              dlgTitle: 'Error',
              dlgContent: 'No record/s selected',
              disableClose: true
            });

            return;
          }

          this.totalBulkRecords = 0;
          this.bulkProcessRetriever({first: 0});
          return;
        } else {
          this.saveRenewalData = this.processList.filter((a: any) => a.PROCESS_TAG == 'Y' && [1,2,4,8].includes(a.REN_STAT_CD)).map((b: any) => {
            return {
              uploadId: b.UPLOAD_ID,
              renewalId: b.RENEWAL_ID,
              lineCd: b.LINE_CD,
              sublineCd: b.SUBLINE_CD,
              menuLineCd: b.MENU_LINE_CD,
              renStatCd: 4,
              processTag: b.PROCESS_TAG,
              balanceTag: b.BALANCE_TAG,
              totalLossTag: b.TOTAL_LOSS_TAG,
              blockedTag: b.BLOCKED_TAG,
              purgeTag: b.PURGE_TAG,
              balanceAmt: b.BALANCE_TAG,
              blockedReason: b.BLOCKED_REASON,
              remarks: b.REMARKS,
              mobileNo: b.MOBILE_NO,
              telNo: b.TEL_NO,
              maritalStatus: b.MARITAL_STATUS,
              address01: b.ADDRESS_01,
              address02: b.ADDRESS_02,
              address03: b.ADDRESS_03,
              address04: b.ADDRESS_04,
              provCity: b.PROV_CITY,
              birthCountry: b.COUNTRY_CD,
              postalCd: b.POSTAL_CD,
              lastUser: this.retrievalParams.userId,
              policyNo: b.POLICY_NO,
              oldExpiryDate: b.OLD_EXPIRY_DATE,
              email: b.EMAIL,
              assdFirstName: b.ASSD_FIRST_NAME,
              insuredRisk: b.INSURED_RISK,
              processFlag: true,
              planName: b.PLAN_NAME_2,
              agent: b.AGENT_CD,
              bma: b.BMA_CD,
              src: b.SRC_CD,
              srcExt: b.SRC_EXT_CD,
              referror: b.REFERROR_CD,
              project: b.PROJECT_CD,
              client: b.CLIENT_GRP,
              paytRefNo: b.PAYT_REF_NO
            }
          });
        }
      }

      if(this.saveRenewalData.length == 0) {
        this.jsonDataService.contorlLoading(false);

        this.openDialog({
          dlgTitle: 'Error',
          dlgContent: 'No record/s selected',
          disableClose: true
        });
      } else {
        this.saveCtr = 0;
        this.processId = this.generateProcessId();
        this.newProcessRenewal();
      }
    } else {
      this.jsonDataService.contorlLoading(true);
      this.apiCallService.saveRenewalData(this.saveRenewalData).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
            this.jsonDataService.contorlLoading(false);
            this.lazyLoad({
              first: this.tblConfig.tblData[0]['rownum_'] - 1,
              globalFilter: this.tbl.filterText
            });
            this.saveRenewalData = [];
        });
      });
    }
  }

  totalBulkRecords: number = 0;

  bulkProcessRetriever(param: any) {
    this.apiCallService.getUploadRenewalData({
      status: this.selectedStatus,
      first: param.first,
      rows: 100,
      search: this.tbl.filterText,
      moduleId: this.moduleId,
      userId: this.userDetailService.userId,
      type: "MODULE"
    }).subscribe((data: any) => {
      this.securityService.checkRequestKeyResponse(data, () => {
        data['content'] = JSON.parse(this.jsonDataService.decrypt(data.content));
        this.totalBulkRecords += data['content']['data'].length; //data['content']['count'];

        if(this.processList.length == 0) {
          this.processList = data['content']['data'];
        } else if(this.processList.length > 0) {
          this.processList = this.processList.concat(data['content']['data']);
        }

        if(this.totalBulkRecords < data['content']['count']) {
          this.bulkProcessRetriever({first: this.processList.length});
        } else {
          this.processList = this.processList.filter((p: any) => !this.excludeProcessList.some((e: any) => e.RENEWAL_ID == p.RENEWAL_ID));

          this.saveRenewalData = this.processList.map((b: any) => {
            return {
              uploadId: b.UPLOAD_ID,
              renewalId: b.RENEWAL_ID,
              lineCd: b.LINE_CD,
              sublineCd: b.SUBLINE_CD,
              menuLineCd: b.MENU_LINE_CD,
              renStatCd: 4,
              processTag: b.PROCESS_TAG,
              balanceTag: b.BALANCE_TAG,
              totalLossTag: b.TOTAL_LOSS_TAG,
              blockedTag: b.BLOCKED_TAG,
              purgeTag: b.PURGE_TAG,
              balanceAmt: b.BALANCE_TAG,
              blockedReason: b.BLOCKED_REASON,
              remarks: b.REMARKS,
              mobileNo: b.MOBILE_NO,
              telNo: b.TEL_NO,
              maritalStatus: b.MARITAL_STATUS,
              address01: b.ADDRESS_01,
              address02: b.ADDRESS_02,
              address03: b.ADDRESS_03,
              address04: b.ADDRESS_04,
              provCity: b.PROV_CITY,
              birthCountry: b.COUNTRY_CD,
              postalCd: b.POSTAL_CD,
              lastUser: this.retrievalParams.userId,
              policyNo: b.POLICY_NO,
              oldExpiryDate: b.OLD_EXPIRY_DATE,
              email: b.EMAIL,
              assdFirstName: b.ASSD_FIRST_NAME,
              insuredRisk: b.INSURED_RISK,
              processFlag: true,
              planName: b.PLAN_NAME_2,
              agent: b.AGENT_CD,
              bma: b.BMA_CD,
              src: b.SRC_CD,
              srcExt: b.SRC_EXT_CD,
              referror: b.REFERROR_CD,
              project: b.PROJECT_CD,
              client: b.CLIENT_GRP,
              paytRefNo: b.PAYT_REF_NO
            }
          });

          this.saveCtr = 0;
          this.processId = this.generateProcessId();
          this.newProcessRenewal();
        }
      });
    });
  }

  reportPromises: any[] = [];
  emailParamsArr: any[] = [];
  processId: string = '';
  renewalProcessLogs = [];
  showSuccess: boolean = true;

  async processRenewal() {
    let promises: any[] = [];
    this.processId = this.generateProcessId();

    for (let i = 0; i < this.saveRenewalData.length; i++) {
      const el = this.saveRenewalData[i];
      el.processId = this.processId;
      promises.push(this.apiCallService.saveRenewalData([el]).toPromise());
    }

    Promise.all(promises).then(async (res: any) => {
        this.securityService.checkRequestKeyResponse(res, () => {
            this.reportPromises = [];
            this.emailParamsArr = [];

            this.apiCallService.getRenewalProcessLogs({
              processId: this.processId,
              moduleId: this.moduleId,
              type: "MODULE"
            }).subscribe((data: any) => {
                    this.securityService.checkRequestKeyResponse(data, () => {
                        data['content'] = JSON.parse(this.jsonDataService.decrypt(data.content));
                        this.dialogContent = this.constructLogs(data.content, 'p');

                        if(data.content.length > 0) {
                        this.showSuccess = false;
                        }
                    });
            });

            for (let i = 0; i < res.length; i++) {
                const el = this.saveRenewalData[i];
                const r = JSON.parse(this.jsonDataService.decrypt(res[i]['content']));

                if(r.agentRenNoticeTag == 'N' && r.policyId !== null) {
                var encryptedParams = this.jsonDataService.encrypt({
                    policyId: r.policyId,
                    requestId: r.requestId
                });
                var link = "https://" + r.ospLink + "/renewal?params=" + `${btoa(encryptedParams)}`;

                var info = {
                    uploadId: el.uploadId,
                    renewalId: el.renewalId,
                    policyId: r.policyId,
                    policyNo: el.policyNo,
                    oldExpiryDate: el.oldExpiryDate,
                    email: el.email,
                    assdFirstName: el.assdFirstName,
                    link: link,
                    mobileNo: el.mobileNo,
                    countryCd: el.birthCountry,
                    lineCd: el.lineCd,
                    menuLineCd: el.menuLineCd,
                    insuredRisk: el.insuredRisk
                };

                this.constructMail(info);
                }
            }
            this.generateReport();
        });
    });
  }

  newProcessRenewal() {
    let el = this.saveRenewalData[this.saveCtr];
    el.processId = this.processId;

    this.apiCallService.saveRenewalData([el]).subscribe((data: any) => {
      this.securityService.checkRequestKeyResponse(data, async () => {
        try{
          let r = JSON.parse(this.jsonDataService.decrypt(data['content']));

          if(r.agentRenNoticeTag == 'N' && r.policyId !== null) {
            let encryptedParams = this.jsonDataService.encrypt({
                policyId: r.policyId,
                requestId: r.requestId
            });
            // let link = "https://" + r.ospLink + "/renewal?params=" + `${btoa(encryptedParams)}`;
            let link = "https://" + r.ospLink + '/renewal-and-payments';

            let info = {
                uploadId: el.uploadId,
                renewalId: el.renewalId,
                policyId: r.policyId,
                policyNo: el.policyNo,
                oldExpiryDate: el.oldExpiryDate,
                email: el.email,
                assdFirstName: el.assdFirstName,
                link: link,
                mobileNo: el.mobileNo,
                countryCd: el.birthCountry,
                lineCd: el.lineCd,
                sublineCd: el.sublineCd,
                menuLineCd: el.menuLineCd,
                insuredRisk: el.insuredRisk,
                reportId: r.reportId,
                reportName: r.reportName,
                ospLink: r.ospLink,
                planName: el.planName,
                paytRefNo: el.paytRefNo
            };

            await this.newGenerateReport(info);
          } else {
            this.goToNext();
          }
        } catch(error) {
          console.log(error);

          if(data.status == 'ERROR') {
            el.renStatCd = 901; // error while processing
            this.apiCallService.saveRenewalData([el]).subscribe((d: any) => {
              this.securityService.checkRequestKeyResponse(d, () => {
                this.goToNext();
              });
            });
          }
        }
      });
    });
  }

  onClickSendNotice() {
    this.save(true);
  }

  constructMail(info: any): any {
    var polNo = 'XXXX' + info.policyNo.substring(info.policyNo.length-4, info.policyNo.length);
    var oldExpiryDate = this.datePipe.transform(info.oldExpiryDate, 'MMMM d, YYYY');
    // var startLink = '<div style="display:flex"><div style="display:inline-flex; background:#B11116; padding:10px; border-radius:10px; font-weight:bold; -webkit-user-select: none; -khtml-user-select: none; -webkit-touch-callout: none; -moz-user-select: none; -o-user-select: none; user-select: none;"><a style="text-decoration:none"><span style="color:#FFF">';
    // var endLink = '</span></a></div></div>';
    var pLink = '<div style="display:flex; padding-left:20%; padding-right:20%">P_REN_LINK</div>';

    let email = this.jsonDataService.data.policyEmail.filter((policyEmail: any) =>
      policyEmail.lineCd == info.lineCd &&
      policyEmail.sublineCd == info.sublineCd &&
      policyEmail.emailType == 5)[0];

    let header = email?.headerImgPath;
    header = "https://"+ info.ospLink + header;
    header = header.replace(/\\/g,"/");
    let emailBody = email.emailBody.split('P_POL_NO').join(polNo);
    emailBody = emailBody.split('P_OLD_EXP_DATE').join(oldExpiryDate);
    emailBody = emailBody.split('P_REN_LINK').join(pLink);
    emailBody = emailBody.split('P_ASSURED_NAME').join(info.assdFirstName);
    emailBody = emailBody.split('P_PLAN_NAME').join(info.planName);
    emailBody = emailBody.split('P_DOC_NAME').join('policy');
    emailBody = emailBody.split('P_RISK_NAME').join(info.insuredRisk);
    emailBody = emailBody.split('P_PAYT_REF_NO').join(info.paytRefNo);

    this.emailParams.recipient = info.email;
    this.emailParams.header = header;
    this.emailParams.subject = email.emailSubject.split('P_ASSURED_NAME').join(info.assdFirstName);

    let sms = this.jsonDataService.data.policyEmail.filter((policyEmail: any) =>
      policyEmail.lineCd == info.lineCd &&
      policyEmail.sublineCd == info.sublineCd &&
      policyEmail.emailType == 9)[0];

    let smsContent = sms.emailBody.split('P_ASSURED_NAME').join(info.assdFirstName);
    smsContent = smsContent.split('P_RISK_NAME').join(info.insuredRisk);
    smsContent = smsContent.split('P_OLD_EXP_DATE').join(oldExpiryDate);
    smsContent = smsContent.split('P_LINK').join(info.link);
    smsContent = smsContent.split('P_POL_NO').join(polNo);
    smsContent = smsContent.split('P_PAYT_REF_NO').join(info.paytRefNo);
    smsContent = smsContent.split('P_PLAN_NAME').join(info.planName);

    this.emailParams.smsContent = smsContent;

    this.emailParams.emailContent = emailBody;
    this.emailParams.mobileNo = info.mobileNo;
    this.emailParams.countryCd = info.countryCd;
    this.emailParams.policyId = info.policyId;
    this.emailParams.uploadId = info.uploadId;
    this.emailParams.renewalId = info.renewalId;

    if(info?.reportId === 'BMR008') {
      this.emailParams.emailType = 4;
    } else if (info?.reportId === 'BMR010') {
      this.emailParams.emailType = 5;
    } else if (info?.reportId === 'BMR011') {
      this.emailParams.emailType = 6;
    }

    this.reportParams.uploadId = info.uploadId;
    this.reportParams.renewalId = info.renewalId;
    this.reportParams.policyId = info.policyId;
    this.reportParams.reportType = info.reportId;
    this.reportParams.reportName = info.reportName;

    let newObjR = {};
    Object.assign(newObjR, this.reportParams);
    this.reportPromises.push(newObjR);

    let newObj = {};
    Object.assign(newObj, this.emailParams);
    this.emailParamsArr.push(newObj);

    return {
      reportParam: newObjR,
      emailParam: newObj
    }
  }

  async generateReport() {
    Promise.all(this.reportPromises).then(async (res: any) => {
        this.securityService.checkRequestKeyResponse(res, () => {
            for (let i = 0; i < res.length; i++) {
                const r = JSON.parse(this.jsonDataService.decrypt(res[i]['content']));
                this.emailParamsArr[i].attach = [
                {
                    path: 'https://' + r.link
                }
                ];
            }
            this.sendMail();
        });
        //await this.sendMail();
    });
  }

  async newGenerateReport(info: any) {
    let params: any;

    try {
      params = this.constructMail(info);

      this.apiCallService.generateReports(params.reportParam, 'pdf').subscribe(async (data2: any) => {
          if (data2?.status === 'SUCCESS') {
            const res = JSON.parse(this.jsonDataService.decrypt(data2['content']));
            params.emailParam.folderName = res.link.split("/")[3];
            await this.newSendMail(params.emailParam);
          } else {
            let x = this.saveRenewalData[this.saveCtr];
            x.renStatCd = 902; // failed sending
            this.apiCallService.saveRenewalData([x]).subscribe((d: any) => {
              this.securityService.checkRequestKeyResponse(d, () => {
                this.jsonDataService.contorlLoading(false);
                // let x = params.emailParam;

                let param = [
                  {
                    emailType: 5,
                    policyId: info.policyId,
                    uploadId: info.uploadId,
                    renewalId: info.renewalId,
                    recipientEmail: info.email,
                    recipientSms: null,
                    successTag: null,
                    sendUser: this.userDetailService.userId
                  },
                  {
                    emailType: null,
                    policyId: info.policyId,
                    uploadId: info.uploadId,
                    renewalId: info.renewalId,
                    recipientEmail: null,
                    recipientSms: info.mobileNo,
                    successTag: null,
                    sendUser: this.userDetailService.userId
                  }
                ];

                this.saveRenewalAuditTrail(param);
              });
            });
          }
      });
    } catch(error) {
      console.log(error);

      let x = this.saveRenewalData[this.saveCtr];
      x.renStatCd = 902; // failed sending
      this.apiCallService.saveRenewalData([x]).subscribe((d: any) => {
        this.securityService.checkRequestKeyResponse(d, () => {
          // let x = params.emailParam;

          let param = [
            {
              emailType: 5,
              policyId: info.policyId,
              uploadId: info.uploadId,
              renewalId: info.renewalId,
              recipientEmail: info.email,
              recipientSms: null,
              successTag: null,
              sendUser: this.userDetailService.userId
            },
            {
              emailType: null,
              policyId: info.policyId,
              uploadId: info.uploadId,
              renewalId: info.renewalId,
              recipientEmail: null,
              recipientSms: info.mobileNo,
              successTag: null,
              sendUser: this.userDetailService.userId
            }
          ];

          this.saveRenewalAuditTrail(param);
        });
      });
    }
  }

  async sendMail() {
    this.jsonDataService.contorlLoading(true);

    let emailPromises: any[] = [];

    emailPromises.push(this.apiCallService.sendMail(this.emailParamsArr).toPromise());

    Promise.all(emailPromises).then((res: any) => {
      this.jsonDataService.contorlLoading(false);

      if(this.showSuccess) {
        const dialogRole = this.dialog.open(DialogMsgComponent, {
          disableClose: true,
          width: "500px",
          data: {
            title: 'SUCCESS',
            content: 'Successfully sent notice'
          }
        });
      } else {
        this.openLogsDialog();
      }

      var smsParamsArr = this.emailParamsArr.map((a: any) => {
        return {
          mobileNo: a.mobileNo,
          message: a.smsContent,
          countryCd: a.countryCd
        }
      });

      for (var i = 0; i < smsParamsArr.length; i++) {
        const el = smsParamsArr[i];
        var smsCode = this.smsCountryCd[0]; // this.smsCountryCd.filter((a: any) => a.countryCd == el.countryCd)[0];

        el.mobileNo = el.mobileNo.trim();

        if(el.mobileNo.charAt(0) == '0') {
          el.mobileNo = smsCode.smsCountryCd + (el.mobileNo.substr(el.mobileNo.indexOf(smsCode.prefix) + smsCode.prefix.length, el.mobileNo.length - smsCode.prefix.length));
        } else if(el.mobileNo.charAt(0) == '+') {
          el.mobileNo = el.mobileNo.substr(1, el.mobileNo.length);
        }
      }

      this.apiCallService.sendSms(smsParamsArr).subscribe((data: any) => {
      });

      this.getUploadedRenewalData();
      this.saveRenewalData = [];
      this.reportPromises = [];
      this.emailParamsArr = [];
    });
  }

  failedEmail: boolean = false;

  async newSendMail(emailParam: any) {
    this.failedEmail = false;

    this.apiCallService.sendMail([emailParam]).subscribe(async (data3: any) => {
      this.securityService.checkRequestKeyResponse(data3, async () => {
        try {
          let emailRes = JSON.parse(this.jsonDataService.decrypt(data3.response));
          if(emailRes.status == 'ERROR') { // invalid email
            this.failedEmail = true;
          }
        } catch(error) {
          console.log(error);

          if(data3.status == 'ERROR') {
            this.failedEmail = true;
          }
        } finally {
          await this.newSendSms(emailParam);
        }
      });
    });
  }

  async newSendSms(emailParam: any) {
    let smsParam = {
      mobileNo: emailParam.mobileNo,
      message: emailParam.smsContent,
      countryCd: emailParam.countryCd
    }

    let x = emailParam;

    let auditParam = [
      {
        emailType: 5,
        policyId: x.policyId,
        uploadId: x.uploadId,
        renewalId: x.renewalId,
        recipientEmail: x.recipient,
        recipientSms: '',
        successTag: '',
        sendUser: this.userDetailService.userId
      },
      {
        emailType: 9,
        policyId: x.policyId,
        uploadId: x.uploadId,
        renewalId: x.renewalId,
        recipientEmail: '',
        recipientSms: x.mobileNo,
        successTag: '',
        sendUser: this.userDetailService.userId
      }
    ];

    let smsCode = this.smsCountryCd[0]; // this.smsCountryCd.filter((a: any) => a.countryCd == el.countryCd)[0];

    if(smsParam.mobileNo == null) {
      smsParam.mobileNo = '';
    } else {
      smsParam.mobileNo = smsParam.mobileNo.trim();

      if(smsParam.mobileNo.charAt(0) == '0') {
        smsParam.mobileNo = smsCode.smsCountryCd + (smsParam.mobileNo.substr(smsParam.mobileNo.indexOf(smsCode.prefix) + smsCode.prefix.length, smsParam.mobileNo.length - smsCode.prefix.length));
      } else if(smsParam.mobileNo.charAt(0) == '+') {
        smsParam.mobileNo = smsParam.mobileNo.substr(1, smsParam.mobileNo.length);
      }
    }

    try {
      this.apiCallService.sendSms([smsParam]).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          try {
            let smsRes = JSON.parse(this.jsonDataService.decrypt(data.content));

            auditParam[0].successTag = this.failedEmail ? '' : 'Y';
            auditParam[1].successTag = 'Y';

            this.saveRenewalAuditTrail(auditParam);
          } catch(error) {
            console.log(error);

            if(data.status == 'ERROR' && this.failedEmail) {
              let x = this.saveRenewalData[this.saveCtr];
              x.renStatCd = 902; // failed sending
              this.apiCallService.saveRenewalData([x]).subscribe((d: any) => {
                this.securityService.checkRequestKeyResponse(d, () => {
                  auditParam[0].successTag = '';
                  auditParam[1].successTag = '';

                  this.saveRenewalAuditTrail(auditParam);
                });
              });
            } else {
              auditParam[0].successTag = this.failedEmail ? '' : 'Y';
              auditParam[1].successTag = '';

              this.saveRenewalAuditTrail(auditParam);
            }
          }
        });
      });
    } catch(error) {
      console.log(error);
      if(this.failedEmail) {
        let x = this.saveRenewalData[this.saveCtr];
        x.renStatCd = 902; // failed sending
        this.apiCallService.saveRenewalData([x]).subscribe((d: any) => {
          this.securityService.checkRequestKeyResponse(d, () => {

            auditParam[0].successTag = '';
            auditParam[1].successTag = '';

            this.saveRenewalAuditTrail(auditParam);
          });
        });
      } else {
        auditParam[0].successTag = 'Y';
        auditParam[1].successTag = '';

        this.saveRenewalAuditTrail(auditParam);
      }
    }
  }

  saveRenewalAuditTrail(param: any) {
    this.apiCallService.saveRenewalAuditTrail(param).subscribe((d: any) => {
      this.securityService.checkRequestKeyResponse(d, () => {
        try {
          this.goToNext();
        } catch (error) {
          console.error(error);
          this.goToNext();
        }
      });
    });
  }

  goToNext() {
    this.saveCtr++;
    if(this.saveCtr < this.saveRenewalData.length) {
      this.newProcessRenewal();
    }

    if(this.saveCtr == this.saveRenewalData.length) {
      this.logs = [];

      this.getLogs({
        uploadId: null,
        processId: this.processId,
        first: 0
      });
    }
  }

  openDialog(data: any) {
    const dialogRole = this.dialog.open(DialogMsgComponent, {
      disableClose: true,
      width: "500px",
      data: {
        title: data.dlgTitle,
        content: data.dlgContent
      }
    });
  }

  confirmDialog(data: boolean) {
    if(this.singlePurge) {
      this.rowEventData['PURGE_TAG'] = data ? 'Y' : 'N';
    } else {
      this.tblConfig.cols[this.tblConfig.cols.length-1].checked = false;

      this.tblConfig.tblData.forEach((a: any) => {
        a['PURGE_TAG'] = 'N';
      });
    }
  }

  toggleSelectAll(ev: any) {
    if(ev.src == 'PURGE_TAG') {
      this.openPurgeAllDialog();
    } else if(ev.src == 'PROCESS_TAG') {
      this.tblConfig.tblData.forEach((a: any) => {
        a['PROCESS_TAG'] = ev.val;
      });

      this.processAllTag = ev.val == 'Y';
      this.tblConfig.cols[1].checked = ev.val == 'Y';
      this.processList = [];
      this.excludeProcessList = [];
    }
  }

  openPurgeAllDialog() {
    this.tblConfig.cols[this.tblConfig.cols.length-1].checked = true;

    this.tblConfig.tblData.forEach((a: any) => {
      a['PURGE_TAG'] = 'Y';
    });

    if(this.tblConfig.tblData.length > 0) {
      this.singlePurge = false;
      const dialogRole = this.dialog.open(this.purgeDialog, {
        width: "500px",
        disableClose: true
      });
    }
  }

  numDec(event:any):void {
    if (event.target.value) {
      const nm = (event.target.value + "").replace(/[^0-9\.]/g, "");
      this.dlgBalanceAmt = nm;
    }
  }

  amtFm(event:any):void {
    if (event.target.value) {
      const nm = (event.target.value + "").split(".");
      const wn = nm[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      const dn = parseFloat("0." + (nm[1] || "00")).toFixed(2).substr(1);
      this.dlgBalanceAmt = (wn || "0") + dn;
    }
  }

  confirmTagDialog(data: boolean) {
    if(data) {
      this.dlgBalanceAmt = '';
      this.dlgBlockedReason = '';

      if((this.tagSrc == 'BALANCE_TAG' && !this.tagVal)
       || (this.tagSrc == 'BLOCKED_TAG' && !this.tagVal)) {
        const dialogRole = this.dialog.open(this.tagInfoDialog, {
          width: this.tagSrc == 'BALANCE_TAG' ? "300px" : "600px",
          disableClose: true
        });
      } else {
        this.confirmTagInfoDialog(true);
      }
    } else {
      this.rowEventData[this.tagSrc] = this.tagVal ? 'Y' : 'N';
    }
  }

  confirmTagInfoDialog(data: boolean) {
    if(data) {
      if(this.singlePurge) {
        let a = this.rowEventData;
        let provCity = this.options.provCity.filter((x: any) => x.value == a['PC_CITY_CD'] && x.countryCd == a['PC_COUNTRY_CD'] && x.provCd == a['PC_PROVINCE_CD'])[0];

        let param = {
          uploadId: a.UPLOAD_ID,
          renewalId: a.RENEWAL_ID,
          renStatCd: a.REN_STAT_CD,
          processTag: a.PROCESS_TAG,
          balanceTag: a.BALANCE_TAG,
          totalLossTag: a.TOTAL_LOSS_TAG,
          blockedTag: a.BLOCKED_TAG,
          purgeTag: a.PURGE_TAG,
          balanceAmt: a.BALANCE_TAG == 'N' ? 0 : (this.dlgBalanceAmt + "").replace(/[^0-9\.]/g, ""),
          blockedReason: this.dlgBlockedReason,
          remarks: a.REMARKS,
          email: a.EMAIL,
          mobileNo: a.MOBILE_NO,
          telNo: a.TEL_NO,
          maritalStatus: a.MARITAL_STATUS,
          address01: a.ADDRESS_01,
          address02: a.ADDRESS_02,
          address03: a.ADDRESS_03,
          address04: a.ADDRESS_04,
          // provCity: a.PROV_CITY2,
          birthCountry: a.BIRTH_COUNTRY,
          postalCd: a.POSTAL_CD,
          lastUser: this.retrievalParams.userId,
          cityCd: provCity?.value,
          countryCd: provCity?.countryCd,
          provinceCd: provCity?.provCd
        };

        this.saveRenewalData.push(param);
        this.save();
      } else {

        // function to get all expired records before cancellation
        if(this.selectedStatus === 'expired') {
          this.batchCancellation({
            first: 0,
            rows: 10,
            sortBy: null,
            orderBy: 1
          });
        } else {
          this.saveRenewalData = this.tblConfig.tblData.map((b: any) => {
            let provCity = this.options.provCity.filter((x: any) => x.value == b['PC_CITY_CD'] && x.countryCd == b['PC_COUNTRY_CD'] && x.provCd == b['PC_PROVINCE_CD'])[0];

            return {
              uploadId: b.UPLOAD_ID,
              renewalId: b.RENEWAL_ID,
              lineCd: b.LINE_CD,
              menuLineCd: b.MENU_LINE_CD,
              renStatCd: b.REN_STAT_CD,
              processTag: b.PROCESS_TAG,
              balanceTag: b.BALANCE_TAG,
              totalLossTag: b.TOTAL_LOSS_TAG,
              blockedTag: b.BLOCKED_TAG,
              purgeTag: b.PURGE_TAG,
              balanceAmt: b.BALANCE_TAG,
              blockedReason: b.BLOCKED_REASON,
              remarks: b.REMARKS,
              mobileNo: b.MOBILE_NO,
              telNo: b.TEL_NO,
              maritalStatus: b.MARITAL_STATUS,
              address01: b.ADDRESS_01,
              address02: b.ADDRESS_02,
              address03: b.ADDRESS_03,
              address04: b.ADDRESS_04,
              // provCity: b.PROV_CITY,
              birthCountry: b.COUNTRY_CD,
              postalCd: b.POSTAL_CD,
              lastUser: this.retrievalParams.userId,
              policyNo: b.POLICY_NO,
              oldExpiryDate: b.OLD_EXPIRY_DATE,
              email: b.EMAIL,
              assdFirstName: b.ASSD_FIRST_NAME,
              insuredRisk: b.INSURED_RISK,
              cityCd: provCity?.value,
              countryCd: provCity?.countryCd,
              provinceCd: provCity?.provCd
            }
          });

          this.save();
        }
      }
    } else {
      this.rowEventData[this.tagSrc] = 'N';
    }
  }

  enableForm(a: boolean) {
    if(a) {
      this.updateRenewal.controls["balanceAmt"].enable();
      this.updateRenewal.controls["blockedReason"].enable();
      this.updateRenewal.controls["remarks"].enable();
      this.updateRenewal.controls["email"].enable();
      this.updateRenewal.controls["mobileNo"].enable();
      this.updateRenewal.controls["telNo"].enable();
      this.updateRenewal.controls["maritalStatus"].enable();
      this.updateRenewal.controls["address01"].enable();
      this.updateRenewal.controls["address02"].enable();
      this.updateRenewal.controls["address03"].enable();
      this.updateRenewal.controls["address04"].enable();
      this.updateRenewal.controls["provCity"].enable();
      this.updateRenewal.controls["birthCountry"].enable();
      this.updateRenewal.controls["postalCd"].enable();
      this.updateRenewal.controls["agent"].enable();
      this.updateRenewal.controls["bma"].enable();
      this.updateRenewal.controls["src"].enable();
      this.updateRenewal.controls["srcExt"].enable();
      this.updateRenewal.controls["referror"].enable();
      this.updateRenewal.controls["project"].enable();
      this.updateRenewal.controls["client"].enable();
    } else {
      this.updateRenewal.controls["balanceAmt"].disable();
      this.updateRenewal.controls["blockedReason"].disable();
      this.updateRenewal.controls["remarks"].disable();
      this.updateRenewal.controls["email"].disable();
      this.updateRenewal.controls["mobileNo"].disable();
      this.updateRenewal.controls["telNo"].disable();
      this.updateRenewal.controls["maritalStatus"].disable();
      this.updateRenewal.controls["address01"].disable();
      this.updateRenewal.controls["address02"].disable();
      this.updateRenewal.controls["address03"].disable();
      this.updateRenewal.controls["address04"].disable();
      this.updateRenewal.controls["provCity"].disable();
      this.updateRenewal.controls["birthCountry"].disable();
      this.updateRenewal.controls["postalCd"].disable();
      this.updateRenewal.controls["agent"].disable();
      this.updateRenewal.controls["bma"].disable();
      this.updateRenewal.controls["src"].disable();
      this.updateRenewal.controls["srcExt"].disable();
      this.updateRenewal.controls["referror"].disable();
      this.updateRenewal.controls["project"].disable();
      this.updateRenewal.controls["client"].disable();
    }
  }

  updateProvCityOpts(country?: any) {
    let name = "";
    for (let prov of this.jsonDataService.data.province) {
      for(let city of this.jsonDataService.data.city.filter((a:any) => a.provinceCd === prov.provinceCd && a.countryCd === prov.countryCd &&
        a.countryCd === (country ? Number(this.selectedRow.countryCd) : a.countryCd))){ // Number(country)
            this.options.provCity.push({
                value: city.cityCd,
                text: name.concat(city.cityName, ", ", prov.provinceName),
                provCd: city.provinceCd,
                countryCd: city.countryCd
            });
        }
    }
  }

  generateProcessId() {
    var length = 15;
    var result = [];
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result.push(characters.charAt(Math.floor(Math.random() *
            charactersLength)));
    }
    return result.join('');
  }

  reset: boolean = false;

  setSelectedStatus(status: string, clr?: any) {
    this.enableForm(false);
    this.selectedStatus = status;
    this.processList = [];
    this.excludeProcessList = [];
    this.processAllTag = (status == 'forProcessing' || status == 'failedSending');

    if(clr === undefined) {
	  if(this.tbl) {
        this.reset = true;
        this.jsonDataService.contorlLoading(true);
      }

      this.clearTblFilter();
    }
  }

  afterFilter() {
    if(this.selectedStatus == 'sentRenewalNotice'
     || this.selectedStatus == 'forwardedToAgent') {
      this.tblConfig.tblData.forEach((a: any) => {
        a['PROCESS_TAG'] = 'N';
      });
    }
  }

  lazyLoad(ev: LazyLoadEvent) {
    setTimeout(() => {
      if(this.reset) {
        this.tbl.tbl.reset();
        this.reset = false;
      } else {
        this.getUploadedRenewalData({
          first: ev.first,
          rows: this.tblConfig.rowsPerPage,
          search: ev.globalFilter?.search,
          sortBy: ev.sortField,
          orderBy: ev.sortOrder,
          column: ev.globalFilter?.column
        });
      }
    }, 0);
  }

  onFieldChange(ev: any, from: string) {
    if(from == 'agent') {
      var bmaCd = this.jsonDataService.data.agent.filter((a: any) => a.issCd == this.selectedRow['ISS_CD'] && a.bmCode == ev.value)[0].bmaCd;
      var bmaBmCd = this.jsonDataService.data.bma.filter((a: any) => a.bmaCd == bmaCd)[0]?.bmCode;

      this.updateRenewal.patchValue({
        bma: bmaBmCd ? bmaBmCd : ""
      });
    } else if(from == 'srcExt') {
      if(ev.value == null) {
        this.updateRenewal.patchValue({
          referror: null
        });

        this.updateRenewal.controls["referror"].disable();
      } else {
        var srcExtCd = this.jsonDataService.data.srcExt.filter((a: any) => a.issCd == this.selectedRow['ISS_CD'] && a.bmCode == ev.value)[0]?.srcExtCd;
        this.options.referror = [{bmCode: null, referrorDesc: ''}].concat(this.jsonDataService.data.referror.filter((a: any) => a.srcExtCd == srcExtCd));

        this.updateRenewal.patchValue({
          referror: null
        });

        this.updateRenewal.controls["referror"].enable();
      }
    }
  }


  //FOR BATCH CANCELLATION OF RENEWAL RECORDS --- START

  batchCancellation(prm?: any) {
    let qryPrm: any = {};

    if(prm) {
      qryPrm = {
        first: prm.first,
        rows: prm.rows,
        sortBy: prm.sortBy,
        orderBy: prm.orderBy,
      }
    }

    this.showSendButton = ['forProcessing', 'sentRenewalNotice', 'forwardedToAgent', 'failedSending'].includes(this.selectedStatus);
    this.showUpdateButton = !['cancelled', 'expired', 'renewed', 'all'].includes(this.selectedStatus);

    this.tblConfig.loading = true;
    this.resetForm();
    this.jsonDataService.contorlLoading(true);
    this.apiCallService.getUploadRenewalData({
        status: this.selectedStatus,
        first: qryPrm.first,
        rows: qryPrm.rows,
        search: qryPrm.search,
        sortBy: qryPrm.sortBy,
        orderBy: qryPrm.orderBy,
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE",
        isGetAddtlData: this.isGetAddtlData,
        column: qryPrm.column
    }).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          if (data.status === 'SUCCESS') {
            data['content'] = JSON.parse(this.jsonDataService.decrypt(data.content));

            this.jsonDataService.contorlLoading(false);
            this.tblConfig.loading = false;

            data['content']['data'].forEach((item: any) => {
              this.expiredList.push({...item, PURGE_TAG: "Y"});
            });

            if(this.tblConfig.totalRecords > this.expiredList.length) {
              this.batchCancellation({
                first: qryPrm.first + 10,
                rows: 10,
                sortBy: null,
                orderBy: 1
              });
            } else {
              if(this.tblConfig.totalRecords === this.expiredList.length) {
                this.saveRenewalData = this.expiredList.map((b: any) => {
                  let provCity = this.options.provCity.filter((x: any) => x.value == b['PC_CITY_CD'] && x.countryCd == b['PC_COUNTRY_CD'] && x.provCd == b['PC_PROVINCE_CD'])[0];

                  return {
                    uploadId: b.UPLOAD_ID,
                    renewalId: b.RENEWAL_ID,
                    lineCd: b.LINE_CD,
                    menuLineCd: b.MENU_LINE_CD,
                    renStatCd: b.REN_STAT_CD,
                    processTag: b.PROCESS_TAG,
                    balanceTag: b.BALANCE_TAG,
                    totalLossTag: b.TOTAL_LOSS_TAG,
                    blockedTag: b.BLOCKED_TAG,
                    purgeTag: b.PURGE_TAG,
                    balanceAmt: b.BALANCE_TAG,
                    blockedReason: b.BLOCKED_REASON,
                    remarks: b.REMARKS,
                    mobileNo: b.MOBILE_NO,
                    telNo: b.TEL_NO,
                    maritalStatus: b.MARITAL_STATUS,
                    address01: b.ADDRESS_01,
                    address02: b.ADDRESS_02,
                    address03: b.ADDRESS_03,
                    address04: b.ADDRESS_04,
                    // provCity: b.PROV_CITY,
                    birthCountry: b.COUNTRY_CD,
                    postalCd: b.POSTAL_CD,
                    lastUser: this.retrievalParams.userId,
                    policyNo: b.POLICY_NO,
                    oldExpiryDate: b.OLD_EXPIRY_DATE,
                    email: b.EMAIL,
                    assdFirstName: b.ASSD_FIRST_NAME,
                    insuredRisk: b.INSURED_RISK,
                    cityCd: provCity?.value,
                    countryCd: provCity?.countryCd,
                    provinceCd: provCity?.provCd
                  }
                });

                this.save();
              }
            }
          } else {
            this.jsonDataService.contorlLoading(false);
            this.tblConfig.loading = false;
            this.appMessageService.showAppMessage(data.message, "error");
          }
        });
    });
  }



  //FOR BATCH CANCELLATION OF RENEWAL RECORDS --- END
}
