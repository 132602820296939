import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class FormService {

    /************************** FIELD FORMATTERS **************************/

    setFieldToUpperCase(form: FormGroup, field: string, autocomplete: boolean, event: any): void {
        if (autocomplete) {
            if (event.target.value) {
                form.controls[field].setValue({
                    text: event.target.value.toUpperCase()
                });
            } else {
                form.controls[field].setValue("");
            }
        } else {
            form.controls[field].setValue(event.target.value.toUpperCase());
        }
    }

    setFieldToNumberWithDecimal(form: FormGroup, field: string, event: any): void {
        if (event.target.value) {
            const nm = (event.target.value + "").replace(/[^0-9\.]/g, "");
            form.controls[field].setValue(nm);
        }
    }

    setToNumberWithDecimal(amount: string): string {
        return (amount + "").replace(/[^0-9\.]/g, "");
    }

    setToNumber(form: FormGroup, field: string, event: any): void {
        if (event.target.value) {
            const nm = (event.target.value + "").replace(/[^0-9]/g, "");
            form.controls[field].setValue(nm);
        }
    }

    setFieldToAmountFormat(form: FormGroup, field: string, event: any): void {
        if (event.target.value && !isNaN(event.target.value)) {
            const nm = (event.target.value + "").split(".");
            const wn = nm[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            const dn = parseFloat("0." + (nm[1] || "00")).toFixed(2).substr(1);
            form.controls[field].setValue((wn || "0") + dn);
        }
        else {
            form.controls[field].setValue('');
        }
    }

    // Test Push
    setToAmountFormat(amount: string): string {
        const nm = (amount + "").split(".");
        const wn = nm[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        const dn = parseFloat("0." + (nm[1] || "00")).toFixed(2).substr(1);
        return (wn || "0") + dn;
    }

    /************************** CUSTOM VALIDATORS **************************/

    autocomplete: any = (): ValidatorFn => {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (control.value !== "" && !control.value.value) {
                return { "autocomplete": { value: control.value } }
            }

            return null;
        }
    };

    // email:any = Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    /************************** FIELD MESSAGE **************************/

    fieldMsg: any = {
        required: "This field is required.",
        email: "Not a valid email address.",
        autocomplete: "Value is not recognized. Please click one of the options."
    };

    isInvalid(form: any, field: string): boolean {
        return form.get(field).touched && form.get(field).invalid;
    }

    getFieldErrorMsg(form: any, field: string): string {
        let msg = "";
        if (form.get(field).hasError("required")) {
            msg = this.fieldMsg.required;
        } else if (form.get(field).hasError("email")) {
            msg = this.fieldMsg.email;
        } else if (form.get(field).hasError("autocomplete")) {
            msg = this.fieldMsg.autocomplete;
        }
        return msg;
    }

    /************************** FORM MESSAGE **************************/

    msgType: any = {
        S: "success",
        E: "error",
        I: "info"
    };

    showFormMsg(elementId: string, message: string, msgType: string): void {
        const element = document.getElementById(elementId);
        if (element !== null) {
            element.innerHTML = `
                <div class="form-msg ${this.msgType[msgType]}">
                    <p>${message}</p>
                </div>
            `;
        }
    }

    hideFormMsg(elementId: string): void {
        const element = document.getElementById(elementId);
        if (element !== null) {
            element.innerHTML = "";
        }
    }

    /************************** FORM LOADER **************************/

    showFormLoader(component: any, formId: string, message: string, func: any, funcParams: any, noSpinner?: boolean | undefined): void {
        const form = document.querySelector("#" + formId + " .form-loader");
        if (form !== null) {
            // ! Old Loading Symbol logo
            // form.innerHTML = `
            //     <div class="form-loader-container">
            //         <img src="./assets/images/common/bpims-logo.png">
            //         <div class="loader-logo">
            //             <div class="circle circle-1"></div>
            //             <div class="circle circle-2"></div>
            //             <div class="circle circle-3"></div>
            //         </div>
            //         <p class="loader-msg montserrat">${message}</p>
            //     </div>
            // `;
            form.innerHTML = `
                <div class="form-loader-container">
                    <div class="spinner">
                        <div class=""></div>
                        <div class=""></div>
                    </div>
                    <p class="loader-msg montserrat">${message}</p>
                </div>
            `;
            if (noSpinner) {
                form.innerHTML = `
                <div class="form-loader-container">
                    <p class="loader-msg montserrat">${message}</p>
                </div>
            `;
            }

            const stepperBtns = document.querySelector("#stepper-dtl .btn-wrap");
            if (stepperBtns !== null) stepperBtns.classList.add("hide");

            const formField = document.querySelector("#" + formId + " > .form-fields");
            if (formField !== null) formField.classList.add("hide");

            const formBtns = document.querySelector("#" + formId + " ~ .form-btns");
            if (formBtns !== null) formBtns.classList.add("hide");

            if (component != null)
                setTimeout(() => {
                    component[func](funcParams);
                }, 300);
        }
    }

    hideFormLoader(formId: string): void {
        const formLoader = document.querySelector("#" + formId + " > .form-loader");
        if (formLoader !== null) {
            formLoader.innerHTML = "";

            const stepperBtns = document.querySelector("#stepper-dtl .btn-wrap");
            if (stepperBtns !== null) stepperBtns.classList.remove("hide");

            const formField = document.querySelector("#" + formId + " > .form-fields");
            if (formField !== null) formField.classList.remove("hide");

            const formBtns = document.querySelector("#" + formId + " ~ .form-btns");
            if (formBtns !== null) formBtns.classList.remove("hide");
        }
    }

}