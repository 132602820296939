import { Component, Inject, OnInit } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContentObserver } from '@angular/cdk/observers';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';


@Component({
  selector: 'user-type-lov',
  templateUrl: './user-type-lov.component.html',
  styleUrls: ['./user-type-lov.component.css']
})
export class UserTypeLovDialogComponent {

  userTypeModuleId: string = 'BMM093';

  constructor(private apiCallService: APICallService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<UserTypeLovDialogComponent>) { 
      this.getUserType();
    }

  // Variable Initializer
  issueSourceData: any = [];

  // Issue Source Initializer
  userTypeTableConfig: any = {
    cols: [
      {
        key: "USER_TYPE",
        header: "Code",
        dataType: "string"
      },
      {
        key: "USER_TYPE_DESC",
        header: "Line",
        dataType: "string"
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  }

  // Gets data for Issue Source Table
  getUserType() {
    try {
      /*
      this.userTypeTableConfig.loading = true;
      this.issueSourceData = await this.apiCallService.getAllIssueSource().toPromise();
      this.issueSourceData = this.issueSourceData.content;
      this.userTypeTableConfig.loading = false;
      this.userTypeTableConfig.tblData = this.issueSourceData;
      */
      this.userTypeTableConfig.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.apiCallService.getAllAccType({
        moduleId: this.data.moduleId,
        userId: this.userDetailService.userId,
        type: "LOV"
      }).subscribe((data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
              this.securityService.hasValidCsrfToken(data, () => {
                if (data.status === "SUCCESS") {
                  data = JSON.parse(this.jsonDataService.decrypt(data.response));
                  if (data.moduleId === this.userTypeModuleId) {
                    this.jsonDataService.contorlLoading(false);
                    this.userTypeTableConfig.loading = false;
                    this.userTypeTableConfig.tblData = data.data.filter((e: any) => e.ACTIVE_TAG == 'A');
                  } else {
                      this.jsonDataService.contorlLoading(false);
                      this.userTypeTableConfig.loading = false;
                      this.appMessageService.showAppMessage("You do not have the correct privilege to access the data. Kindly contact your administrator for assistance.", "error");
                  }
                } else {
                    this.jsonDataService.contorlLoading(false);
                    this.userTypeTableConfig.loading = false;
                    this.appMessageService.showAppMessage(data.message, "error");
                }
              });
            });
      });
    } 
    catch(e) {
      
    }
  }

  onRowClick(ev: any) {
    try {
      this.data = ev;
    }
    catch(e) {
      
    }
  }

  onClose() {
    try {
      if(this.data=='' || this.data==null){
        this._dialogRef.close({content: ''}); 
      } else {
        this._dialogRef.close({content: this.data, moduleId: this.data.moduleId});
      }
    }
    catch(e) {
      
    }
  }

  onBack() {
    try {
      this._dialogRef.close({content: '', option: 'back'});
    }
    catch(e) {
      
    }
  }

}

